import { Alert, Col, Row } from 'antd';
import { BlockLoader } from 'components/common/BlockLoader';
import { Loader } from 'components/common/Loader';
import NoResults from 'components/common/noResults/noResults';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';
import { incrementOffset, resetParams } from 'redux/services/elmo/deploymentRequestsParams';
import { useGetDeploymentRequestsQuery } from 'redux/services/elmo/elmoApi';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { DeploymentCard } from './DeploymentCard/DeploymentCard';

const styles: InlineStylesModel = {
  container: {
    overflowY: 'auto',
    overflowX: 'hidden',
    flex: 1,
    alignContent: 'flex-start'
  }
};

export const DeploymentsGrid = (): JSX.Element => {
  /* ******************** Hooks ******************** */
  const nav = useNavigate();
  const { elmoDeploymentRequestsParams } = useAppSelector((state) => state);
  const { data, isError, isLoading, isFetching } = useGetDeploymentRequestsQuery(elmoDeploymentRequestsParams);

  const dispatch = useAppDispatch();

  /* ******************** Functions / Variables ******************** */
  const handleGetMore = (): void => {
    dispatch(incrementOffset());
  };

  const handleDetailClick = (detailId: string, requestStatus: string): void => {
    if (requestStatus === 'Draft') {
      nav(`/edit/${detailId}`);
    } else {
      nav(`/details/${detailId}`);
    }
  };

  console.log('check');

  /* ******************** Effects ******************** */
  useEffect(() => {
    return () => {
      dispatch(resetParams());
    };
  }, [dispatch]);

  const requestCardsJSX = data?.data.map((item) => (
    <Col key={item.id} onClick={() => handleDetailClick(item.id, item.status)} xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
      <DeploymentCard deploymentRequest={item} />
    </Col>
  ));

  /* ******************** Error Render ******************** */
  if (isError) {
    return <Alert showIcon message="Request Error" type="error" />;
  }

  /* ******************** No Data Render ******************** */
  if (data?.data.length === 0) {
    return <NoResults errorMessage="No Results Were Found..." />;
  }

  /* ******************** Loading Render ******************** */
  if (!data || (isFetching && elmoDeploymentRequestsParams.offset === 0)) {
    return <Loader />;
  }

  /* ******************** Data Render ******************** */
  const hasMore = (): boolean => {
    if (data.data.length < 25) return false;
    else {
      return data.data.length > 25 ? elmoDeploymentRequestsParams.offset < (data.totalCount - 25 ?? 0) : true;
    }
  };

  return (
    <InfiniteScroll
      style={{ overflowX: 'hidden', width: '100%' }}
      dataLength={data.data.length}
      next={handleGetMore}
      hasMore={hasMore()}
      loader={
        <div style={{ paddingTop: '30px' }}>
          <BlockLoader direction="loader loader--slideUp" />
        </div>
      }>
      <Row gutter={[16, 16]}>{requestCardsJSX}</Row>
    </InfiniteScroll>
  );
};
