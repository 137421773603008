import { List } from 'antd';
import { WaitToSearch } from 'components/common/waitToSearch/WaitToSearch';
import { Product } from 'models/Product';
import { ReduxState, useAppSelector } from 'redux/store';
import test from '../../../../assets/nothing-here.json';
import selectFavoritesLottie from '../../../../assets/star-hexagon-badge.json';
import { ProductGridItem } from '../ProductsGrid/components';

export const FavoritesGrid = (): JSX.Element => {
  /* ******************** Hooks ******************** */
  const { favoritesSearchResults } = useAppSelector((state: ReduxState) => state.app);

  /* ******************** Data Render ******************** */

  if (!favoritesSearchResults) return <WaitToSearch animationData={selectFavoritesLottie} message="Select favorites list" />;

  return (
    <List
      grid={{ gutter: 8, xs: 1, sm: 2, md: 4, lg: 4, xl: 5, xxl: 5 }}
      dataSource={favoritesSearchResults as any}
      locale={{ emptyText: <WaitToSearch animationData={test} message="No products in list" /> }}
      renderItem={(item: any): JSX.Element => {
        const productToPass: Partial<Product> = {
          id: item.productCatalogId,
          alternateItem: item.alternateItemId,
          category: item.categoryName,
          manufacturer: item.manufacturerName,
          productDescription: item.productDescription,
          productName: item.productName,
          productNumber: item.productNumber,
          customerXRef: {
            alternateItemId: item.alternateItemId,
            customerStandardCost: item.customerStandardCost
          }
        };

        return <ProductGridItem product={productToPass} />;
      }}
      rowKey={(item): string => item.productNumber}
      style={{ overflow: 'auto', width: '100%', overflowX: 'hidden' }}
    />
  );
};
