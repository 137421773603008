import { Checkbox, Col, List, Row, Space, Spin, message } from 'antd';
import { FavoriteListPayload } from 'models/Favorites';
import { Product } from 'models/Product';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useUpsertUserFavoritesMutation } from 'redux/services/fuzzyFace/fuzzyFaceApi';
import { setFavoritesSearchResults } from 'redux/slices/appSlice';
import { useAppSelector } from 'redux/store';

interface Props {
  lists: FavoriteListPayload[];
  product: Product | Partial<Product>;
  idx: number;
}

export const ListMenuItem: FC<Props> = ({ lists, product, idx }) => {
  const [upsertUserFavorites, { isLoading: isUpdating }] = useUpsertUserFavoritesMutation();
  const dispatch = useDispatch();
  const { productsView } = useAppSelector((state) => state.form);

  const mappedProductIds = lists[idx].products?.map((prod) => ({ productCatalogId: prod.productCatalogId }));

  const isChecked = lists[idx]?.products?.some((prod) => prod.productName === product.productName);

  const handleCheck = async (): Promise<void> => {
    const newArrAdd = [...lists.slice(0, idx), { ...lists[idx], products: [...(mappedProductIds ?? []), { productCatalogId: product.id }] }, ...lists.slice(idx + 1)];
    const newArrDelete = [...lists.slice(0, idx), { ...lists[idx], products: mappedProductIds?.filter((prod) => prod.productCatalogId !== product.id) }, ...lists.slice(idx + 1)];

    try {
      const updatedData = await upsertUserFavorites({ payload: { lists: isChecked ? newArrDelete : newArrAdd } }).unwrap();

      if (productsView === 'fav') {
        dispatch(setFavoritesSearchResults(updatedData.lists[idx].products));
      }

      message.success(isChecked ? `${product.productName} successfully removed from list ${lists[idx].name}` : `${product.productName} successfully added to list ${lists[idx].name}`);
    } catch (e) {
      console.error(e);
      message.error((e as { data: { errorMessage: string } }).data.errorMessage);
    }
  };
  // const isChecked = list.products.some((prod) => prod.productName === product.productName);

  return (
    <Spin spinning={isUpdating}>
      <List.Item>
        <Row>
          <Space>
            <Col>
              <Checkbox checked={isChecked} onChange={handleCheck} />
            </Col>
            <Col>{lists[idx].name}</Col>
          </Space>
        </Row>
      </List.Item>
    </Spin>
  );
};
