import { Select } from 'antd';
import { BlockLoader } from 'components/common/BlockLoader';
import { useFormikContext } from 'formik';
import { DeploymentRequestPayload } from 'models/DeploymentRequest';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useGetProjectsQuery } from 'redux/services/mrsGrouch/mrsGrouchApi';
import { setTaskCount } from 'redux/slices/appSlice';
import { FormLabel } from '../FormItems/FormLabel';

const { Option } = Select;

export const ProjectSelect: React.FC = (): JSX.Element => {
  const { values, setFieldValue } = useFormikContext<DeploymentRequestPayload>();
  const dispatch = useDispatch();

  const { data, isLoading, isFetching } = useGetProjectsQuery({ params: { overrideSkipTake: true } });

  const projectOptions = data?.data
    .filter((project) => project.status.toLowerCase() !== 'closed' && project.status.toLowerCase() !== 'cancelled')
    .map((project) => ({ label: `${project.customerProjectId} - ${project.description} - ${project.tasks.length} task(s)`, value: project.id }));

  const onChange = (value: string): void => {
    setFieldValue('taskId', '');
    setFieldValue('projectId', value);
    dispatch(setTaskCount(data?.data.find((project) => project.id === value)?.tasks.length));
  };

  const onSearch = (value: string): void => {
    console.log('search:', value);
  };

  if (isLoading || isFetching) return <BlockLoader direction="loader loader--slideUp" />;

  return (
    <>
      <FormLabel label={projectOptions?.length ? 'Project' : 'Project (None created or active for this division)'} />
      <Select
        showSearch
        value={values.projectId ?? ''}
        style={{ width: '100%', paddingTop: 10, borderRadius: 5 }}
        placeholder="Select a Project"
        optionFilterProp="children"
        onChange={onChange}
        loading={isLoading || isFetching}
        allowClear
        onSearch={onSearch}
        options={projectOptions}
        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
      />
    </>
  );
};
