import { RightCircleFilled } from '@ant-design/icons';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Button, Col, message, Row, Space, Tooltip, Typography } from 'antd';
import { AddDeliveryAddressModal } from 'components/createDeliveryAddress/AddDeliveryAddressModal';
import { useFormikContext } from 'formik';
import { DeploymentRequestLabels, DeploymentRequestPayload } from 'models/DeploymentRequest';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetAddressesQuery, useGetAddressQuery } from 'redux/services/chuckieSue/addressesApi';
import { LocationAddressTypes } from 'redux/services/chuckieSue/models/addresses';
import { useGetDeploymentRequestConfigQuery } from 'redux/services/drNobelPrice/drNobelPriceApi';
import { useAppSelector } from 'redux/store';
import { colors, toRgba } from 'styles/colors';
import { LocationsFilters } from '../common';
import { UseOutageLocationToggle } from './UseOutageLocationToggle';
const { Text, Title } = Typography;

const styles: InlineStylesModel = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: toRgba(colors.illuminatingEmerald, 0.4),
    justifyContent: 'space-between',
    width: '100%',
    padding: 24,
    borderBottom: '2px solid rgba(0,0,0,.1)',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10
  },
  title: {
    margin: 0,
    fontWeight: 'normal',
    borderBottom: 5
  },
  nextButton: {
    margin: 'auto',
    borderRadius: '5px'
  }
};

export const HeaderControls = (): JSX.Element => {
  const nav = useNavigate();
  const { id } = useParams();
  const { values, setFieldValue } = useFormikContext<DeploymentRequestPayload>();
  const { chuckieSueAddressesParams } = useAppSelector((state) => state);
  const [showAddAddressModal, _setShowAddAddressModal] = useState(false);

  const { data: installAddressData } = useGetAddressQuery(values.installAddressId ?? skipToken);
  const { data, isLoading: isConfigLoading } = useGetDeploymentRequestConfigQuery();

  const { isLoading } = useGetAddressesQuery({ ...chuckieSueAddressesParams, addressTypesInclude: [LocationAddressTypes.Delivery] });

  const selectedStatus = values.shippingAddressId !== null && values.shippingAddressId !== '';

  const handleShipToInstallButton = (): void => {
    if (values.installAddressId && installAddressData?.addressType.includes('Delivery')) {
      setFieldValue('shippingAddressId', values.installAddressId);
      setFieldValue('fullShippingAddress', values.fullInstallAddress);
    } else if (!values.installAddressId) {
      message.error('No Install Location Selected...');
    } else if (installAddressData?.addressType.includes('Delivery') === false) {
      message.error('Selected Install Address Is Not A Shipping Address...');
    } else {
      message.error('Could Not Use Install Address...');
    }
  };

  return (
    <Row align="middle" style={styles.container}>
      <Col span={6}>
        <Space style={{ flex: 1, justifyContent: 'center' }} size={0} direction="vertical">
          <Title level={4} style={styles.title}>
            {selectedStatus ? 'Selected ' : 'Search For '} {DeploymentRequestLabels.shippingAddress}
          </Title>
          {!selectedStatus && values.installAddressId && !isLoading ? <UseOutageLocationToggle installAddressData={installAddressData} /> : null}
        </Space>
      </Col>
      <Col span={18} style={{ textAlign: 'right' }}>
        {selectedStatus ? (
          <Button onClick={(): void => (id ? nav(`/edit/${id}/products`) : nav('/create/products'))} style={styles.nextButton}>
            Continue to Products <RightCircleFilled />
          </Button>
        ) : (
          <Space>
            <LocationsFilters locationPageType="install" />
            <Tooltip title={!data?.addressDetails.isManualAddressAllowedToBeAddedToPostOffice ? 'Account not configured to allow manual addresses' : undefined}>
              <Button disabled={!data?.addressDetails.isManualAddressAllowedToBeAddedToPostOffice} type="primary" style={styles.addAddressButton} onClick={(): void => _setShowAddAddressModal(true)}>
                Add Manual Address
              </Button>
            </Tooltip>

            <AddDeliveryAddressModal
              isShipping
              addressTypes={data?.addressDetails?.installAddressTypes ?? []}
              show={showAddAddressModal}
              key={1}
              onClose={(): void => {
                _setShowAddAddressModal(false);
              }}
            />
          </Space>
        )}
      </Col>
    </Row>
  );
};
