import { Col, Row, Typography } from 'antd';
import { DeploymentRequestPayload } from 'models/DeploymentRequest';
import '../styles/waveAnimation.css';

interface Props {
  confirmationInfo?: DeploymentRequestPayload;
}

export const DeploymentRequestConfirmation = ({ confirmationInfo }: Props): JSX.Element => {
  const hasSONumber = (confirmationInfo as unknown as { salesOrderNumber: string })?.salesOrderNumber !== null;

  return (
    <>
      <Row justify="center" style={{ borderRadius: '5px 5px 0 0' }}>
        <Row align="middle" justify="center" style={{ marginTop: 50 }}>
          <svg style={{ height: 100, width: 100 }} className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
            <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
            <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
          </svg>
        </Row>
        {hasSONumber && (
          <Col span={24} style={{ textAlign: 'center', margin: '30px 0' }}>
            <Typography.Text style={{ fontSize: 20, color: 'rgba(0,0,0,.8)', background: '#ffffffaf', padding: '8px 20px', borderRadius: 100 }}>
              Sales Order Number: {(confirmationInfo as unknown as { salesOrderNumber: string })?.salesOrderNumber}
            </Typography.Text>
          </Col>
        )}
        <Col span={24} style={{ textAlign: 'center', marginTop: !hasSONumber ? '30px' : undefined }}>
          <Typography.Text
            ellipsis={{ tooltip: `Request Name: ${confirmationInfo?.requestNumber}` }}
            style={{ fontSize: 20, color: 'rgba(0,0,0,.8)', background: '#ffffffaf', padding: '8px 20px', borderRadius: 100, maxWidth: 450 }}>
            Request Name: {confirmationInfo?.requestNumber}
          </Typography.Text>
        </Col>
      </Row>
      <Col style={{ position: 'absolute', bottom: 0 }}>
        <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
          <defs>
            <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
          </defs>
          <g className="parallax">
            <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
            <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
            <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
            <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
          </g>
        </svg>
      </Col>
    </>
  );
};
