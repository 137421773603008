import { Button, Col, Row, Space } from 'antd';
import { WaitToSearch } from 'components/common/waitToSearch/WaitToSearch';
import { useFormikContext } from 'formik';
import { DeploymentRequestPayload } from 'models/DeploymentRequest';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { setProductsView } from 'redux/slices/formSlice';
import { useAppDispatch } from 'redux/store';
import shoppingCartLottie from '../../../../assets/shopping-cart.json';
import { ProductCartItem } from './components';

const styles: InlineStylesModel = {
  container: {
    overflowY: 'scroll',
    overflowX: 'hidden',
    flex: 1,
    alignContent: 'flex-start'
  },
  emptyContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
};

export const ProductsCart = (): JSX.Element => {
  /* ******************** Hooks ******************** */
  const { values } = useFormikContext<DeploymentRequestPayload>();

  const dispatch = useAppDispatch();

  /* ******************** Functions ******************** */
  const handleShowSearch = (): void => {
    dispatch(setProductsView('grid'));
  };

  /* ******************** Render Empty ******************** */
  if (!values.lineItems?.length) {
    return (
      <Space size={'large'} direction="vertical" style={{ width: '100%' }}>
        <WaitToSearch animationData={shoppingCartLottie} message="Cart is empty" />
        <Row style={{ width: '100%' }} justify="center">
          <Button onClick={handleShowSearch}>Add products</Button>
        </Row>
      </Space>
    );
  }

  /* ******************** Render Data ******************** */
  return (
    <Row gutter={[16, 4]} style={styles.container}>
      {values.lineItems.map((product) => (
        <Col span={24} key={product.id}>
          <ProductCartItem product={product} />
        </Col>
      ))}
    </Row>
  );
};
