import { InstallLocationsGrid } from 'components/Create/common';
import { HeaderControls } from 'components/Create/InstallLocation';
import { useFormikContext } from 'formik';
import { DeploymentRequestPayload } from 'models/DeploymentRequest';
import { InlineStylesModel } from 'models/InlineStylesModel';

const styles: InlineStylesModel = {
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    // overflow: 'clip',
    height: '100%'
  }
};

export const InstallLocationPage = (): JSX.Element => {
  const { values } = useFormikContext<DeploymentRequestPayload>();

  return (
    <div style={styles.container}>
      <HeaderControls />
      {/* {isSelectedCard ? <SelectedLocationCard address={isSelectedCard} addressType={'install'} /> : <InstallLocationsGrid addressType="install" />} */}
      {<InstallLocationsGrid addressType="install" />}
    </div>
  );
};
