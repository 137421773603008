import { Button, Card, Col, Row, Switch, Typography } from 'antd';
import { useFormikContext } from 'formik';
import { DeploymentRequestPayload } from 'models/DeploymentRequest';
import { InlineStylesModel } from 'models/InlineStylesModel';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import { colors, toRgb, toRgba } from 'styles/colors';

const { Text, Title } = Typography;

const styles: InlineStylesModel = {
  header: {
    backgroundColor: toRgba(colors.illuminatingEmerald, 0.4),
    height: '18%'
  },
  selectAddressButton: {
    marginBottom: 10,
    width: '50%'
  },
  selectDeliverDateButton: {
    marginBottom: 10,
    width: '80%'
  }
};

export const ShippingInfoContent = (): JSX.Element => {
  const nav = useNavigate();
  const { id } = useParams();
  const { values } = useFormikContext<DeploymentRequestPayload>();

  return (
    <Card
      headStyle={styles.header}
      style={{ height: '100%' }}
      title={
        <Title level={4} style={{ fontWeight: 'normal' }}>
          Shipping Information
        </Title>
      }>
      <Row gutter={[32, 0]}>
        <Col span={14}>
          <Typography.Title level={5} style={{ color: values.fullInstallAddress ? 'black' : toRgb(colors.redSalsa) }}>
            Install Address:
          </Typography.Title>
          {values.fullInstallAddress ? (
            <Typography.Paragraph style={styles.infoText}>
              {values.fullInstallAddress.name}: {values.fullInstallAddress.code} <br /> {values.fullInstallAddress?.street1 + ' ' + values.fullInstallAddress?.street2} ,{' '}
              {values.fullInstallAddress?.city} {values.fullInstallAddress?.subdivision?.localCode} {values.fullInstallAddress.postalCode} {values.fullInstallAddress.country.alpha2Code}
            </Typography.Paragraph>
          ) : (
            <Button onClick={() => (id ? nav(`/edit/${id}/install-location`) : nav('/create/install-location'))} type="ghost" style={styles.selectAddressButton}>
              Select an Install Address
            </Button>
          )}
          <Typography.Title level={5} style={{ color: values.fullShippingAddress ? 'black' : toRgb(colors.redSalsa) }}>
            Shipping Address:
          </Typography.Title>
          {values.fullShippingAddress ? (
            <Typography.Paragraph style={styles.infoText}>
              {values.fullShippingAddress.name}: {values.fullShippingAddress.code} <br /> {values.fullShippingAddress?.street1 + ' ' + values.fullShippingAddress?.street2} ,{' '}
              {values.fullShippingAddress?.city} {values.fullShippingAddress?.subdivision?.localCode} {values.fullShippingAddress.postalCode} {values.fullShippingAddress.country.alpha2Code}
            </Typography.Paragraph>
          ) : (
            <Button onClick={() => (id ? nav(`/edit/${id}/shipping-location`) : nav('/create/shipping-location'))} type="ghost" style={styles.selectAddressButton}>
              Select a Shipping Address
            </Button>
          )}
        </Col>
        <Col span={10}>
          <Typography.Title level={5} style={{ color: values.requestedDeliveryDate ? 'black' : toRgb(colors.redSalsa) }}>
            Requested Delivery Date:
          </Typography.Title>
          {values.requestedDeliveryDate ? (
            <Typography.Paragraph style={styles.infoText}>{values.requestedDeliveryDate && moment(values.requestedDeliveryDate).format('MM-DD-YYYY')}</Typography.Paragraph>
          ) : (
            <Button onClick={() => (id ? nav(`/edit/${id}/request-info`) : nav('/create/request-info'))} type="ghost" style={styles.selectDeliverDateButton}>
              Select Delivery Date
            </Button>
          )}
          <Typography.Title level={5} style={styles.infoTitle}>
            Ship Complete Required:
          </Typography.Title>
          <Typography.Paragraph style={styles.infoText}>
            <Switch disabled={true} checked={values.isShipCompleteRequired} />
          </Typography.Paragraph>
        </Col>
      </Row>
    </Card>
  );
};
