import { Checkbox, Space, message } from 'antd';
import { FormLabel } from 'components/Create/FormItems/FormLabel';
import { useFormikContext } from 'formik';
import { DeploymentRequestPayload } from 'models/DeploymentRequest';
import { FC } from 'react';
import { Address } from 'redux/services/chuckieSue/models/addresses';

type Props = {
  installAddressData?: Address;
};

export const UseOutageLocationToggle: FC<Props> = ({ installAddressData }) => {
  const { values, setFieldValue } = useFormikContext<DeploymentRequestPayload>();

  const handleShipToInstallButton = (): void => {
    if (values.installAddressId && installAddressData?.addressType.includes('Delivery')) {
      setFieldValue('shippingAddressId', values.installAddressId);
      setFieldValue('fullShippingAddress', values.fullInstallAddress);
    } else if (!values.installAddressId) {
      message.error('No Install Location Selected...');
    } else if (installAddressData?.addressType.includes('Delivery') === false) {
      message.error('Selected Install Address Is Not A Shipping Address...');
    } else {
      message.error('Could Not Use Install Address...');
    }
  };

  return (
    <Space>
      <Checkbox checked={values.installAddressId === values.shippingAddressId} onChange={handleShipToInstallButton} />
      <FormLabel label="Use Install Location" />
    </Space>
  );
};
