import { Col, Row, Space, Switch, Typography } from 'antd';
import { KitSearch } from 'components/common/KitSearch';
import { HeaderFilters, ProductsCart, ProductsGrid } from 'components/Create/Products';
import { FavoritesGrid } from 'components/Create/Products/FavoritesGrid/FavoritesGrid';
import CartMenu from 'components/Create/Products/ProductsCart/components/CartMenu';
import { useFormikContext } from 'formik';
import { DeploymentRequestPayload } from 'models/DeploymentRequest';
import { useGetDeploymentRequestTypesQuery } from 'redux/services/drNobelPrice/drNobelPriceApi';
import { setIsCommonStock } from 'redux/services/julia/productsParams';
import { useAppDispatch, useAppSelector } from 'redux/store';

export const ProductsPage = (): JSX.Element => {
  /* ******************** Hooks ******************** */
  const dispatch = useAppDispatch();
  const { productsView } = useAppSelector((state) => state.form);
  const { isCommonStock } = useAppSelector((state) => state.juliaProductsParams);
  const { data, isError, isLoading } = useGetDeploymentRequestTypesQuery();
  const { values, setFieldValue } = useFormikContext<DeploymentRequestPayload>();

  const currentRuleType = data?.data.find((value) => value.id === values.deploymentRequestTypeId)?.dispositionRuleType || null;

  const handleChange = (): void => {
    dispatch(setIsCommonStock(!isCommonStock));
  };

  return (
    <>
      <HeaderFilters />
      <Row justify="space-between">
        <Col>
          <CartMenu />
        </Col>
        <Col>
          {(currentRuleType === 'SpecificProjects' || currentRuleType === 'AllProjects') && productsView !== 'fav' && productsView !== 'kit' ? (
            <Space size={8} style={{ paddingTop: 5 }}>
              <Switch onChange={handleChange} checked={isCommonStock !== undefined ? (isCommonStock ? true : false) : false} />
              <Typography.Title style={{ margin: 'auto' }} level={5}>
                View Common Stock
              </Typography.Title>
            </Space>
          ) : null}
        </Col>
      </Row>

      {productsView === 'grid' ? <ProductsGrid /> : productsView === 'fav' ? <FavoritesGrid /> : productsView === 'kit' ? <KitSearch /> : <ProductsCart />}
    </>
  );
};
