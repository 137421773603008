import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Card, Col, Empty, Popover, Row, Space, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useFormikContext } from 'formik';
import { DeploymentRequest, DeploymentRequestPayload } from 'models/DeploymentRequest';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { useNavigate, useParams } from 'react-router-dom';
import { colors, toRgba } from 'styles/colors';
const { Title } = Typography;

const styles: InlineStylesModel = {
  header: {
    backgroundColor: toRgba(colors.royalBlueLight, 0.4)
  },
  table: {
    width: '100%'
  }
};

export const ProductsContent = (): JSX.Element => {
  const { values } = useFormikContext<DeploymentRequestPayload>();
  const { id } = useParams();
  const nav = useNavigate();

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  });

  const numberFormatter = new Intl.NumberFormat('en-US', {
    style: 'decimal'
  });

  const columns: ColumnsType<DeploymentRequest['lineItems'][number]> = [
    {
      title: 'Product Number',
      dataIndex: 'productNumber',
      width: '20%',
      fixed: 'left',
      render: (text: string, record): JSX.Element => {
        return (
          <Space>
            {record.productDescription === '' ? (
              <Popover content={'No description found'}>
                <div>
                  <InfoCircleOutlined style={{ color: '#2786fa' }} />
                  &nbsp;{text}
                </div>
              </Popover>
            ) : (
              <Popover content={record.productDescription}>
                <div>
                  <InfoCircleOutlined style={{ color: '#2786fa' }} />
                  &nbsp;{text}
                </div>
              </Popover>
            )}
          </Space>
        );
      }
    },
    {
      title: 'Manufacturer',
      dataIndex: 'manufacturer',
      width: '10%',
      render: (text: string): JSX.Element => {
        return <div>{text}</div>;
      }
    },
    {
      title: 'Category',
      dataIndex: 'category',
      width: '10%',
      render: (text: string): JSX.Element => {
        return <div>{text}</div>;
      }
    },
    {
      title: 'Quantity',
      dataIndex: 'qty',
      width: '5%',
      align: 'center',
      render: (text: string, record): JSX.Element => {
        return <div style={styles.number}>{numberFormatter.format(Number(record.quantity))}</div>;
      }
    }
  ];

  const getTotalCost = (): number => {
    let totalCost = 0;

    for (const product of values.lineItems) {
      totalCost += product.quantity * (product.customerStandardCost || 0);
    }

    return totalCost;
  };

  const getTotalProducts = (): number => {
    let totalProducts = 0;

    for (const product of values.lineItems) {
      totalProducts += product.quantity;
    }

    return totalProducts;
  };

  return (
    <Card
      headStyle={styles.header}
      style={{ height: '100%' }}
      title={
        <Title level={4} style={{ fontWeight: 'normal' }}>
          Products
        </Title>
      }
      extra={
        <Title level={4} style={{ margin: 0, fontWeight: 'normal' }}>
          Total Products: {getTotalProducts()}
        </Title>
      }>
      <Row gutter={[8, 8]}>
        {!values.lineItems || !values.lineItems.length ? (
          <Col span={24} style={{ alignItems: 'center', textAlign: 'center', height: '100%' }}>
            <Empty description="No products selected" />
            <Button onClick={() => (id ? nav(`/edit/${id}/products`) : nav('/create/products'))}>Select A Product</Button>
          </Col>
        ) : (
          <Table columns={columns} dataSource={values.lineItems?.map((lineItem, index) => ({ key: index.toString(), ...lineItem }))} style={styles.table} pagination={false} />
        )}
      </Row>
    </Card>
  );
};
