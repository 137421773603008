import * as yup from 'yup';

export interface Address {
  city?: string;
  code?: string;
  country?: string;
  countryAlpha2Code?: string;
  id?: string;
  latitude?: number;
  longitude?: number;
  name?: string;
  postalCode?: string;
  street1?: string;
  street2?: string | null;
  subdivision?: {
    categoryName?: string;
    code?: string;
    localCode?: string;
    name?: string;
  };
  subdivisionLocalCode?: string;
}

export enum AddressLabels {
  city = 'City',
  code = 'Location Code',
  country = 'Country',
  countryAlpha2Code = 'Country',
  id = '',
  latitude = '',
  longitude = '',
  name = 'Location Name',
  postalCode = 'Zip Code',
  street1 = 'Street',
  street2 = 'Street1',
  subdivision = 'State | Providence',
  subdivisionLocalCode = 'State | Providence'
}

export const addressSchema: yup.SchemaOf<Address> = yup.object({
  city: yup.string(),
  code: yup.string(),
  country: yup.string(),
  countryAlpha2Code: yup.string(),
  id: yup.string(),
  latitude: yup.number(),
  longitude: yup.number(),
  name: yup.string(),
  postalCode: yup.string(),
  street1: yup.string(),
  street2: yup.string().nullable(),
  subdivision: yup.object().shape({ categoryName: yup.string(), code: yup.string(), localCode: yup.string(), name: yup.string() }),
  subdivisionLocalCode: yup.string()
});
