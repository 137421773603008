import { Col, Row, Typography } from 'antd';
import { useAppSelector } from 'redux/store';
import './NoConfigTypeStyle.css';

interface Props {
  messageContent: string;
}

const NoConfigType = ({ messageContent }: Props): JSX.Element => {
  const companyName = useAppSelector((state) => state.app.acuityContext?.selectedCustomer.name);

  return (
    <Row style={{ width: '100%' }} justify="center" align="top">
      <Col span={24}>
        <div className="banner-text">
          <Typography.Title level={1}>Missing Deployement Request Configfuration</Typography.Title>
          <Typography.Title level={5}>
            <b>{companyName}</b> {messageContent}
          </Typography.Title>
        </div>
        <div className="animation-area">
          <ul className="box-area">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </div>
      </Col>
    </Row>
  );
};

export default NoConfigType;
