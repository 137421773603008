/* eslint-disable jsx-a11y/label-has-associated-control */
import { Space } from 'antd';
import Select from 'antd/lib/select';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGetDeploymentRequestTypesQuery } from 'redux/services/drNobelPrice/drNobelPriceApi';
import { elmoApi } from 'redux/services/elmo';
import { setType } from 'redux/services/elmo/deploymentRequestsParams';
import { ReduxState } from 'redux/store';

const styles: InlineStylesModel = {
  titleColor: {
    // color: '#2F5596'
  },
  selectContainer: {
    minWidth: 200,
    borderRadius: 0
  },
  labelUnfocused: {
    position: 'absolute',
    color: 'rgba(0,0,0,0.50)',
    pointerEvents: 'none',
    top: 5,
    left: 10,
    paddingLeft: 8,
    zIndex: 2,
    transition: 'all 0.3s ease'
  },
  labelFocused: {
    backgroundColor: 'white',
    // backgroundImage: 'linear-gradient(bottom white 50%, lightblue 50%)',
    position: 'absolute',
    paddingLeft: 5,
    marginLeft: 8,
    paddingRight: 5,
    zIndex: 2,
    top: -15,
    left: 8,
    transition: 'all 0.3s ease'
  }
};

export const TypeFilter = (): JSX.Element => {
  const { deploymentRequestType } = useSelector((state: ReduxState) => state.elmoDeploymentRequestsParams);

  const [inputValue, _setInputValue] = useState<string>(deploymentRequestType || '');
  const [isLabelTop, _setIsLabelTop] = useState(inputValue !== '');

  const dispatch = useDispatch();

  const { data, isLoading } = useGetDeploymentRequestTypesQuery();

  /* ****************** Functions ****************** */
  const handleStatusChange = (value: string): void => {
    _setInputValue(value);
    if (value !== 'Both' && value) {
      dispatch(setType(value));
    }
    if (value === 'Both' || !value) {
      dispatch(setType(undefined));
    }

    dispatch(elmoApi.util.invalidateTags(['DeploymentRequests']));
  };

  const handleBlur = (): void => {
    if (inputValue.length === 0) {
      _setIsLabelTop(false);
    }
  };

  const options = useMemo(() => data?.data.map((type) => ({ value: type.name, label: type.name } ?? [])), [data]);

  useEffect(() => {
    if (inputValue.length === 0) {
      _setIsLabelTop(false);
    } else {
      _setIsLabelTop(true);
    }
    if (inputValue.length === 2 || inputValue.length === 0) dispatch(setType(undefined));
  }, [inputValue, dispatch]);

  return (
    <Space direction="vertical" size="small">
      <Space direction="vertical" size="small">
        <Select
          mode={undefined}
          loading={isLoading}
          // filterOption={(input, option): boolean => (option?.children as unknown as string).toLowerCase().includes(input.toLowerCase())}
          style={styles.selectContainer}
          onChange={handleStatusChange}
          onBlur={handleBlur}
          defaultValue={deploymentRequestType}
          onFocus={(): void => _setIsLabelTop(true)}
          options={options}
        />

        <label style={isLabelTop ? styles.labelFocused : styles.labelUnfocused}>
          <span>Type</span>
        </label>
      </Space>
    </Space>
  );
};
