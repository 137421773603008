import { useDispatch } from 'react-redux';
import { setOffset } from 'redux/services/chuckieSue/addressesParams';
import { juliaApi } from 'redux/services/julia';
import { setBulkSearchResults } from 'redux/slices/appSlice';

export const useBulkSearch = (): { clearValues: () => void } => {
  const dispatch = useDispatch();

  const clearValues = (): void => {
    dispatch(setBulkSearchResults([]));
    dispatch(setOffset(0));
    dispatch(juliaApi.util.resetApiState());
    dispatch(juliaApi.util.invalidateTags(['Products']));
  };

  return { clearValues };
};
