import * as yup from 'yup';

export interface Project {
  id: string | null;
  customerProjectId?: string;
  task: Task | null;
  description?: string;
}

export interface Task {
  name: string;
  description: string;
  customerTaskId: string;
  id: string;
}

export const projectSchema: yup.SchemaOf<Project> = yup.object({
  id: yup.string().required(),
  customerProjectId: yup.string().notRequired(),
  description: yup.string().notRequired(),
  task: yup.mixed().oneOf([
    yup.object({
      customerTaskId: yup.string().required(),
      description: yup.string().required()
    }),
    null
  ])
});

export interface ProjectType {
  business: {
    dataAreaId: string;
    erpId: string;
    id: string;
  };
  createdByFullName: string;
  createdByUserId: string;
  createdDateTime: string;
  customerProjectId?: string;
  description: string;
  division: {
    erpId: string;
    id: string;
    name: string;
  };
  documentType: string;
  id: string;
  isActive: boolean;
  isDeleted: boolean;
  modifiedByFullName: string;
  modifiedByUserId: string;
  modifiedDateTime: string;
  owner: {
    fullName: string;
    userId: string;
  };
  partitionKey: string;
  partitionKeyDescription: string;
  status: string;
  tasks: Task[];
  userDefinedFields: [];
  users: [
    {
      fullName: string;
      userId: string;
    }
  ];
  _rid: string;
  _self: string;
  _etag: string;
}

export interface Task {
  createdByFullName?: string;
  createdByUserId?: string;
  createdDateTime?: string;
  description: string;
  customerTaskId: string;
}

export interface ProjectTypePayload {
  isActive?: boolean;
  customerProjectId: string;
  owner?: {
    fullName: string;
    userId: string;
  };
  projectType: string;
  users?: {
    fullName: string;
    userId: string;
  }[];
  status: string;
  tasks: Task[];
  userDefinedFields?: [];
}

export interface ProjectParams {
  projectName?: string;
}
