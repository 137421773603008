import { Button } from 'antd';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { useGetDeploymentRequestConfigQuery } from 'redux/services/drNobelPrice/drNobelPriceApi';
import { useAppSelector } from 'redux/store';

export const ContactInfoSwitch = (): JSX.Element => {
  const { user } = useAppSelector((state) => state.app);
  const { handleChange, values, errors, touched, setFieldValue, getFieldHelpers } = useFormikContext<any>();

  const { data } = useGetDeploymentRequestConfigQuery();

  const handleSetMyContactInfo = (): void => {
    handleChange('contactName')(user?.name || '');
    handleChange('contactPhone')(user?.['https://acuity.mdsiinc.com/user/user_metadata'].phone_number);
    handleChange('contactEmail')(user?.email || '');
  };

  useEffect(() => {
    if (data) {
      if (data.shipmentDetails.automaticallySetShippingContactInfoToUsersContactInfo) handleSetMyContactInfo();
    }
  }, [data]);

  return (
    <Button style={{ border: '0px', textAlign: 'left', paddingLeft: 2, paddingRight: 2 }} ghost onClick={() => handleSetMyContactInfo()}>
      Use My Contact Info
    </Button>
  );
};
