import { Select } from 'antd';
import { BlockLoader } from 'components/common/BlockLoader';
import { useFormikContext } from 'formik';
import { DeploymentRequestPayload } from 'models/DeploymentRequest';
import React from 'react';
import { useGetProjectsQuery } from 'redux/services/mrsGrouch/mrsGrouchApi';
import { FormLabel } from '../FormItems/FormLabel';

const TaskSelect: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<DeploymentRequestPayload>();

  const { data, isLoading, isFetching } = useGetProjectsQuery({ params: { overrideSkipTake: true } });

  const taskOptions = data?.data.find((project) => project.id === values.projectId)?.tasks.map((task) => ({ label: task.customerTaskId, value: task.id }));

  const onChange = (value: string): void => {
    setFieldValue('taskId', value);
  };

  const onSearch = (value: string): void => {
    console.log('search:', value);
  };

  if (isLoading || isFetching) return <BlockLoader direction="loader loader--slideUp" />;

  return (
    <>
      <FormLabel label={taskOptions?.length ? 'Task Type' : 'Task Type (None created for this project)'} />
      <Select
        showSearch
        value={values.taskId || ''}
        style={{ width: '100%', paddingTop: 10 }}
        placeholder="Select a Task"
        optionFilterProp="children"
        onChange={onChange}
        onSearch={onSearch}
        allowClear
        options={taskOptions}
        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
      />
    </>
  );
};

export default TaskSelect;
