import { List } from 'antd';
import { SubstituteProduct } from 'models/Substitutions';
import { FC } from 'react';
import { ProductSubstitutionCard } from './components/ProductSubstitutionCard';

type Props = {
  substituteProducts: SubstituteProduct[];
};

export const ProductSubGrid: FC<Props> = ({ substituteProducts }) => {
  const grid = substituteProducts.length > 1 ? { gutter: 8, xs: 1, sm: 2, md: 2, lg: 2, xl: 2, xxl: 2 } : { gutter: 8, xs: 1, sm: 1, md: 1, lg: 1, xl: 1, xxl: 1 };

  return (
    <List
      grid={grid}
      dataSource={substituteProducts}
      renderItem={(item): JSX.Element => <ProductSubstitutionCard product={item} />}
      rowKey={(item): string => item.productNumber}
      style={{ overflow: 'auto', width: '100%', overflowX: 'hidden' }}
    />
  );
};
