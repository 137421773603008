import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface KitParams {
  kitIdContains?: string;
  descriptionContains?: string;
  overrideSkipTake?: boolean;
}

const initialState: KitParams = {
  kitIdContains: undefined,
  descriptionContains: undefined,
  overrideSkipTake: true
};

export const gonzoParams = createSlice({
  name: 'gonzoParams',
  initialState,
  reducers: {
    setKitIdContains: (state, { payload }: PayloadAction<KitParams['kitIdContains']>) => {
      state.kitIdContains = payload;
    },
    setDescriptionContains: (state, { payload }: PayloadAction<KitParams['descriptionContains']>) => {
      state.descriptionContains = payload;
    }
  }
});

export const { setDescriptionContains, setKitIdContains } = gonzoParams.actions;
