import { Col, Spin } from 'antd';
import { BlockLoader } from 'components/common/BlockLoader';
import { useFormikContext } from 'formik';
import { AddressLabels } from 'models/Address';
import { AcuityContext } from 'models/Application';
import { DeploymentRequestPayload } from 'models/DeploymentRequest';
import { DefaultOptionType } from 'rc-cascader';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setCountryAlpha2CodeEquals, setSubdivisionLocalCodeEquals } from 'redux/services/chuckieSue/addressesParams';
import { useGetWorldsQuery } from 'redux/services/chuckieSue/worldsApi';
import { useAppSelector } from 'redux/store';
import { FancySelect } from '../Inputs';

export const CountryStateInputs: React.FC = () => {
  const { subdivisionLocalCodeEquals, countryAlpha2CodeEquals } = useAppSelector((state) => state.chuckieSueAddressesParams);
  const { acuityContext } = useAppSelector((state) => state.app);
  const { configuration } = acuityContext as AcuityContext;
  const { values } = useFormikContext<DeploymentRequestPayload>();

  const dispatch = useDispatch();

  const { data: countries, isLoading, isFetching } = useGetWorldsQuery();

  const countryNames = countries?.data[0].countries.map((country) => ({ label: country.shortName, value: country.shortName }));

  const findInstallCountry = values.fullInstallAddress ? countries?.data[0].countries.find((country) => country.alpha2Code === values.fullInstallAddress?.country.alpha2Code) : undefined;

  const testStateOptions = countries?.data[0].countries.find((country) => country.alpha2Code === countryAlpha2CodeEquals);

  const stateOptions: DefaultOptionType[] | undefined = testStateOptions?.subdivisions.map((state) => ({ label: state.name, value: state.localCode }));

  useEffect(() => {
    if (findInstallCountry && configuration?.addressDetails?.displayAddressesByDefault) {
      dispatch(setCountryAlpha2CodeEquals(findInstallCountry.alpha2Code));

      return;
    }
    if (countries && configuration?.addressDetails?.displayAddressesByDefault) {
      dispatch(setCountryAlpha2CodeEquals('US'));
      dispatch(setSubdivisionLocalCodeEquals(undefined));
    }
  }, []);

  const handleStateChange = (value: string): void => {
    dispatch(setSubdivisionLocalCodeEquals(value));
  };

  const handleCountryChange = (value: string): void => {
    const countryAlpha2Code = () => {
      const country = countries?.data[0].countries.find((country) => country.shortName === value);

      return country?.alpha2Code;
    };

    dispatch(setCountryAlpha2CodeEquals(countryAlpha2Code()));
    dispatch(setSubdivisionLocalCodeEquals(undefined));
  };

  return (
    // <Row gutter={[8, 8]}>
    <>
      <Col span={6}>
        <Spin spinning={isLoading} indicator={<BlockLoader direction="loader loader--slideUp" small />}>
          <FancySelect
            placeholder="Country"
            style={{ margin: 0 }}
            defaultActiveFirstOption={!!countryAlpha2CodeEquals}
            defaultOpen={false}
            value={countryAlpha2CodeEquals}
            onChange={handleCountryChange}
            showSearch
            options={countryNames ? countryNames : undefined}
          />
        </Spin>
      </Col>
      <Col span={6}>
        <Spin spinning={isLoading || isFetching} indicator={<BlockLoader direction="loader loader--slideUp" small />}>
          <FancySelect
            showSearch
            placeholder={AddressLabels.subdivisionLocalCode}
            onChange={handleStateChange}
            onDeselect={(): any => dispatch(setSubdivisionLocalCodeEquals(undefined))}
            options={stateOptions}
            value={subdivisionLocalCodeEquals}
          />
        </Spin>
      </Col>
    </>
  );
};
