import { FancySelect } from 'components/ui/Inputs';
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useGetUserFavoritesQuery } from 'redux/services/fuzzyFace/fuzzyFaceApi';
import { setBulkSearchResults, setFavoritesSearchResults } from 'redux/slices/appSlice';
import { ReduxState, useAppSelector } from 'redux/store';

export const FavoriteListFilter: FC = () => {
  const { data, isLoading, isFetching } = useGetUserFavoritesQuery();
  const dispatch = useDispatch();
  const [val, setVal] = useState<string | undefined>(undefined);

  const { alternateItemIdContains, categoryNameContains, manufacturerNameContains, productDescriptionContains, productNumberContains } = useAppSelector(
    (state: ReduxState) => state.juliaProductsParams
  );

  const hasFilterValue = Boolean(alternateItemIdContains || categoryNameContains || productDescriptionContains || productNumberContains || manufacturerNameContains);

  useEffect(() => {
    if (hasFilterValue) {
      dispatch(setFavoritesSearchResults(undefined));
      dispatch(setBulkSearchResults(undefined));
      setVal(undefined);
    }
  }, [hasFilterValue]);

  const options = data?.lists.map((list) => ({ label: list.name, value: list.name }));

  const handleSelect = (val: string): void => {
    setVal(val);
    const selectedList = data?.lists.find((list) => list.name === val);

    dispatch(setFavoritesSearchResults(selectedList?.products));
  };

  useEffect(() => {
    return (): void => {
      dispatch(setFavoritesSearchResults(undefined));
    };
  }, []);

  return <FancySelect width={300} value={val} allowClear onChange={handleSelect} placeholder="Favorites Lists" loading={isLoading || isFetching} options={options} style={{ width: '100%' }} />;
};
