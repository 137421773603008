import { green, red } from '@ant-design/colors';
import { DeleteOutlined } from '@ant-design/icons';
import { Button, InputNumber, Space } from 'antd';
import { useFormikContext } from 'formik';
import { DeploymentRequestPayload } from 'models/DeploymentRequest';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { ProductPayload } from 'models/Product';
import { ProductCartCard } from '../../common/ProductCartCard';

interface Props {
  product: ProductPayload;
}

const styles: InlineStylesModel = {
  plusButton: {
    backgroundColor: green.primary,
    color: 'white'
  },
  minusButton: {
    backgroundColor: red.primary,
    color: 'white'
  },
  bottomControls: {
    justifyContent: 'flex-end'
  },
  quantityInput: {
    width: '70px',
    textAlign: 'center'
  }
};

export const ProductCartItem = ({ product }: Props): JSX.Element => {
  /* ******************** Hooks ******************** */
  const { setFieldValue, values } = useFormikContext<DeploymentRequestPayload>();

  /* ******************** Functions ******************** */

  const handleRemoveProduct = (): void => {
    setFieldValue(
      'lineItems' as keyof DeploymentRequestPayload,
      values.lineItems?.filter((item) => item.id !== product.id)
    );
  };

  const handleChangeQuantity = (quantity: number | null): void => {
    if (quantity) {
      const updatedLineItems = values.lineItems.map((item) => (item.id === product.id ? { ...item, quantity: quantity } : item));

      setFieldValue('lineItems' as keyof DeploymentRequestPayload, updatedLineItems);
    }
  };

  return (
    <ProductCartCard product={product}>
      <Space style={styles.bottomControls}>
        <InputNumber style={styles.quantityInput} defaultValue={product.quantity} type="int" min={1} precision={0} onChange={handleChangeQuantity} />
        <Button onClick={handleRemoveProduct} icon={<DeleteOutlined style={{ color: red.primary }} />} />
      </Space>
    </ProductCartCard>
  );
};
