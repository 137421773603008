import { Typography } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { FancySelect } from 'components/ui/Inputs';
import { Stack } from 'components/ui/Stack';
import { InlineStylesModel } from 'models/InlineStylesModel';
import React, { useEffect } from 'react';
import { setAddressTypesInclude } from 'redux/services/chuckieSue/addressesParams';
import { LocationAddressTypes } from 'redux/services/chuckieSue/models/addresses';
import { useGetDeploymentRequestConfigQuery } from 'redux/services/drNobelPrice/drNobelPriceApi';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { colors, toRgb } from 'styles/colors';
import { ReactComponent as DeliveryIcon } from '../../../../../assets/delivery.svg';
import { ReactComponent as InstallIcon } from '../../../../../assets/install.svg';
import { ReactComponent as WarehouseIcon } from '../../../../../assets/warehouse.svg';

const styles: InlineStylesModel = {
  deliveryIconStyle: {
    fontSize: '20px',
    color: toRgb(colors.royalBlueLight)
  },
  installIconStyle: {
    fontSize: '20px',
    color: toRgb(colors.orangeWeb)
  },
  warehouseIconStyle: {
    fontSize: '20px',
    color: toRgb(colors.illuminatingEmerald)
  }
};

interface Props {
  addressPageType: string;
}

export const TypeFilter: React.FC<Props> = ({ addressPageType }) => {
  /* ******************** Hooks ******************** */
  const { addressTypesInclude } = useAppSelector((state) => state.chuckieSueAddressesParams);
  const dispatch = useAppDispatch();

  const { data } = useGetDeploymentRequestConfigQuery();

  /* ******************** Functions / Variables ******************** */
  const handleTypeFilterChange = (type: LocationAddressTypes[]): void => {
    dispatch(setAddressTypesInclude(type));
  };

  useEffect(() => {
    if (data?.addressDetails.installAddressTypes.length) {
      dispatch(setAddressTypesInclude(data.addressDetails.installAddressTypes));
    }
  }, [data?.addressDetails.installAddressTypes]);

  const filterOptions: DefaultOptionType[] = [
    {
      label: (
        <Stack alignItems="center" height={20}>
          <DeliveryIcon width={20} />
          <Typography>Delivery</Typography>
        </Stack>
      ),
      value: 'Delivery',
      disabled: addressPageType === 'shipping' ? true : true
    },
    {
      label: (
        <Stack alignItems="center" height={20}>
          <InstallIcon width={15} />
          <Typography>Install</Typography>
        </Stack>
      ),
      value: 'Install',
      disabled: addressPageType === 'install' ? true : true
    },
    {
      label: (
        <Stack alignItems="center" height={20}>
          <WarehouseIcon width={15} />
          <Typography>Warehouse</Typography>
        </Stack>
      ),
      value: 'Warehouse',
      disabled: true
    }
  ];

  // Clears state on unmount
  useEffect(() => {
    return (): void => {
      if (addressPageType === 'install') dispatch(setAddressTypesInclude([LocationAddressTypes.Install]));
      else if (addressPageType === 'shipping') dispatch(setAddressTypesInclude([LocationAddressTypes.Delivery]));
    };
  }, [dispatch]);

  /* ******************** Renderer ******************** */
  return (
    <FancySelect
      options={data?.addressDetails.installAddressTypes.map((type) => ({ label: type, value: type }))}
      value={addressTypesInclude}
      onChange={handleTypeFilterChange}
      dropdownMatchSelectWidth={false}
      mode="multiple"
      // defaultValue={data?.addressDetails.installAddressTypes}
    />
  );
};
