// import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Form, Spin } from 'antd';
import { Loader } from 'components/common/Loader';
import NoConfigType from 'components/common/noConfigType/NoConfigType';
import { ActionControls, StepControls } from 'components/Create/common';
import { DeploymentRequestInfo } from 'components/Create/common/DeploymentRequestInfo';
import { FormikProvider, useFormik } from 'formik';
import { DeploymentRequestPayload, deploymentRequestPayloadSchema, DeploymentStatuses } from 'models/DeploymentRequest';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useGetDeploymentRequestConfigQuery, useGetDeploymentRequestTypesQuery } from 'redux/services/drNobelPrice/drNobelPriceApi';
import { useCreateDeploymentRequestMutation, useSubmitDeploymentRequestMutation } from 'redux/services/elmo/elmoApi';
// import { useGetDivisionQuery } from 'redux/services/abbyCadabby/abbyCadabbyApi';
import { useAppSelector } from 'redux/store';

const styles: InlineStylesModel = {
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    // overflow: 'clip',
    height: '100%'
  }
};

export const CreateDeploymentRequestLayout = (): JSX.Element => {
  /* ******************** Hooks ******************** */
  // const divisionId = useAppSelector((state) => state.app.acuityContext?.selectedCustomer.id);
  const { user } = useAppSelector((state) => state.app);

  const { data: requestTypesData, isError: requestTypeError } = useGetDeploymentRequestTypesQuery();
  const { data: requestConfigData, isError: requestConfigError } = useGetDeploymentRequestConfigQuery();
  const [, { isLoading }] = useCreateDeploymentRequestMutation({ fixedCacheKey: 'create' });
  const [, { isLoading: postingRequest }] = useSubmitDeploymentRequestMutation({ fixedCacheKey: 'post' });

  const formik = useFormik<DeploymentRequestPayload>({
    validationSchema: deploymentRequestPayloadSchema,
    initialValues: {
      requestNumber: '',
      customerReferenceNumber: '',
      customerPurchaseOrderNumber: '',
      deploymentRequestTypeId: '',
      projectId: '',
      taskId: '',
      installAddressId: null,
      lineItems: [],
      shippingAddressId: null,
      contactEmail: requestConfigData && requestConfigData.shipmentDetails.automaticallySetShippingContactInfoToUsersContactInfo ? user?.email : '',
      contactName: requestConfigData && requestConfigData.shipmentDetails.automaticallySetShippingContactInfoToUsersContactInfo ? user?.name : '',
      contactPhone:
        requestConfigData && requestConfigData.shipmentDetails.automaticallySetShippingContactInfoToUsersContactInfo ? user?.['https://acuity.mdsiinc.com/user/user_metadata'].phone_number : '',
      requestedDeliveryDate: '',
      notificationEmails: user && user.email ? [user.email] : [],
      note: undefined,
      status: DeploymentStatuses.draft,
      isOrderReleased: requestConfigData ? requestConfigData.isReleaseOrderRequired : false,
      isShipCompleteRequired: requestConfigData ? requestConfigData.shipmentDetails.isShipCompleteRequired : false,
      isLabConfigurationRequired: false,
      attachments: [],
      fullInstallAddress: null,
      fullShippingAddress: null,
      isExpediteRequested: false
    },
    onSubmit: async (values) => {
      // console.log('')
    }
  });

  /* ******************** Effects ******************** */
  useEffect(() => {
    if (user && requestConfigData && requestConfigData.shipmentDetails.automaticallySetShippingContactInfoToUsersContactInfo) {
      formik.setFieldValue('contactEmail', user.email);
      formik.setFieldValue('contactName', user.name);
      formik.setFieldValue('contactPhone', user['https://acuity.mdsiinc.com/user/user_metadata'].phone_number);
      //console.log(user['https://acuity.mdsiinc.com/user/user_metadata'].phone_number, 'LOOK');
    }
  }, [requestConfigData]);

  if (requestConfigError) {
    <NoConfigType messageContent="is not configured for deployment requests. Please contact MDSi support." />;
  }

  if (requestTypeError || (requestTypesData && requestTypesData.totalCount === 0)) {
    return <NoConfigType messageContent="has no deployment request types created. Please contact MDSi support." />;
  }

  return (
    <FormikProvider value={formik}>
      <Form style={styles.container} layout="vertical">
        <Spin spinning={isLoading || postingRequest} indicator={<Loader loadingMessage={isLoading ? 'Creating deployment request' : 'Posting deployment request'} />}>
          <ActionControls />
          <DeploymentRequestInfo />
          <StepControls />
          <Outlet />
        </Spin>
      </Form>
    </FormikProvider>
  );
};
