import { PlusOutlined, SettingOutlined } from '@ant-design/icons';
import { Button, Col, Collapse, Input, List, Modal, Row, Space, Spin, Tooltip, Typography } from 'antd';
import message, { MessageType } from 'antd/lib/message';
import { useState } from 'react';
import { useGetUserFavoritesQuery, useUpsertUserFavoritesMutation } from 'redux/services/fuzzyFace/fuzzyFaceApi';
import { DeleteListItem } from './DeleteListItem';
import EditListItem from './EditListItem';

const { Panel } = Collapse;

export const FavoriteListSettings = (): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);

  const { data, isLoading, isFetching } = useGetUserFavoritesQuery();
  const [value, setValue] = useState('');

  const [upsertUserFavorites, { isLoading: isUpdating }] = useUpsertUserFavoritesMutation();

  const handleAddListItem = async (): Promise<void | MessageType> => {
    if (data?.lists.some((list) => list.name === value)) return message.error('List name already in use');
    // if (list.some((listNode) => listNode.name === value) || !value) return message.error('List already exists');
    // setList((prev) => [...prev, { name: value, description: value, products: [] }]);

    try {
      await upsertUserFavorites({ payload: { lists: [...(data?.lists ?? []), { name: value, products: [] }] } });

      message.success(`${value} added to user favorites lists`, 5);
    } catch (e) {
      console.error(e);
      message.error((e as { data: { errorMessage: string } }).data.errorMessage);
    }

    setValue('');
  };

  const handleModal = (): void => {
    setIsOpen(true);
  };

  return (
    <>
      <Tooltip title="Favorites Configuration">
        <Button loading={isLoading || isFetching} onClick={handleModal} icon={<SettingOutlined />} />
      </Tooltip>
      <Modal closable={false} title="Edit Favorite Lists" okText="Exit" cancelButtonProps={{ style: { visibility: 'hidden' } }} visible={isOpen} onOk={(): void => setIsOpen(false)}>
        <Spin spinning={isFetching}>
          <List
            footer={
              <Spin spinning={isUpdating}>
                <Input.Group style={{ display: 'flex' }} compact>
                  <Input onPressEnter={handleAddListItem} value={value} onChange={(e): void => setValue(e.target.value)} placeholder="Add New List" style={{ flex: 1 }} />
                  <Button icon={<PlusOutlined />} type="primary" onClick={handleAddListItem} />
                </Input.Group>
              </Spin>
            }
            dataSource={data?.lists}
            renderItem={(item, index): JSX.Element => (
              <List.Item key={item.name} style={{ width: '100%' }}>
                <Row align="middle" justify="space-between" style={{ width: '100%' }}>
                  <Col>
                    <Typography.Text>{item.name}</Typography.Text>
                  </Col>
                  <Col>
                    <Space>
                      <EditListItem index={index} lists={data?.lists ?? []} />
                      <DeleteListItem index={index} lists={data?.lists ?? []} />
                    </Space>
                  </Col>
                </Row>
              </List.Item>
            )}
          />
        </Spin>
      </Modal>
    </>
  );
};
