import { ArrowLeftOutlined } from '@ant-design/icons';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Button, Col, Divider, Dropdown, Menu, message, Modal, Row, Space, Typography } from 'antd';
import { Loader } from 'components/common/Loader';
import { DeploymentRequestConfirmation } from 'components/DeploymentRequestConfirmation.tsx';
import { useFormikContext } from 'formik';
import { DeploymentRequest, DeploymentRequestPayload, DeploymentStatuses } from 'models/DeploymentRequest';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useGetDeploymentRequestConfigQuery, useGetDeploymentRequestTypesQuery } from 'redux/services/drNobelPrice/drNobelPriceApi';
import {
  useCreateAttachmentMutation,
  useCreateDeploymentRequestMutation,
  useDeleteDeploymentRequestMutation,
  useGetDeploymentRequestQuery,
  useLazyGetDeploymentRequestQuery,
  usePostAttachmentMutation,
  useSubmitDeploymentRequestMutation,
  useSubmitLineDeploymentRequestMutation,
  useUpdateDeploymentRequestMutation
} from 'redux/services/elmo/elmoApi';
import { setIsCreating } from 'redux/slices/formSlice';
import { colors, toRgb } from 'styles/colors';
import '../../../styles/checkmarkStyle.less';
import '../../../styles/waveAnimation.css';

const styles: InlineStylesModel = {
  container: {
    width: '100%',
    justifyContent: 'space-between',
    marginBottom: 8
  },
  draftButton: {
    color: toRgb(colors.raisinBlackLight),
    backgroundColor: '#EED202',
    border: 'none'
  },
  requestButton: {
    color: toRgb(colors.culturedWhite),
    backgroundColor: toRgb(colors.illuminatingEmerald),
    border: 'none'
  }
};

export enum CreateRequestMenu {
  RequestDetails = 'request-details',
  InstallLocation = 'install-location',
  Products = 'products',
  ShippingLocation = 'shipping-location',
  ShippingDetails = 'shipping-details',
  NotesSection = 'more-details',
  ReviewOrder = 'review-order'
}

interface LineItemErrorObject {
  key: string;
  lineProductName: string;
  lineStatus: boolean;
  lineMessage: string;
}

export const ActionControls = (): JSX.Element => {
  /* ******************** Hooks ******************** */
  const nav = useNavigate();
  const { id } = useParams();
  const { pathname } = useLocation();
  const [apiCallFlag, setApiCallFlag] = useState(false);
  const [isSave, setIsSave] = useState(false);
  const [lineErrorMessages, setLineErrorMessages] = useState<LineItemErrorObject[] | undefined>(undefined);
  const [loadingMessage, setLoadingMessage] = useState<string | undefined>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [confirmationInfo, setConfirmationInfo] = useState<DeploymentRequestPayload | undefined>();
  const [modalRequestID, setModalRequestID] = useState<string | null>(null);
  const [submittedDeploymentRequest, setSubmittedDeploymentRequest] = useState<DeploymentRequest>();
  const { data, error: reqError } = useGetDeploymentRequestQuery(id || skipToken);
  const { values, setSubmitting, isSubmitting } = useFormikContext<DeploymentRequestPayload>();
  const [deleteDeploymentRequest] = useDeleteDeploymentRequestMutation();
  const [requestDeployment] = useCreateDeploymentRequestMutation({ fixedCacheKey: 'create' });
  const [submitLineItem, { isLoading: lineSubmitingLoading }] = useSubmitLineDeploymentRequestMutation();
  const [updateDeployment] = useUpdateDeploymentRequestMutation({ fixedCacheKey: 'update' });
  const [submitDeployment] = useSubmitDeploymentRequestMutation({ fixedCacheKey: 'post' });
  const [uploadAttachment, { error, isError: isAttachmentError }] = useCreateAttachmentMutation();
  const [postAttachment] = usePostAttachmentMutation();
  const [trigger] = useLazyGetDeploymentRequestQuery();
  const { data: typeData } = useGetDeploymentRequestTypesQuery();
  const { data: configData, isLoading: isConfigLoading } = useGetDeploymentRequestConfigQuery();

  const dispatch = useDispatch();

  const validLineItemPopup = !!lineErrorMessages?.length && !lineSubmitingLoading && lineErrorMessages.length === values.lineItems.length;

  const foundType = typeData?.data?.find((type) => type.id === values.deploymentRequestTypeId);

  // const isValidToPostSync = deploymentRequestPayloadSchema.isValidSync(values);

  const validateProjects = (): boolean => {
    if (configData?.areProjectsEnabled && values.projectId) return true;
    if (!configData?.areProjectsEnabled) return true;

    return false;
  };

  const isValidToPost = Boolean(values.installAddressId && values.shippingAddressId && values.requestedDeliveryDate && values.status && values.lineItems.length && validateProjects());

  /* ******************** Functions ******************** */
  const showModal = (): void => {
    setIsModalOpen(true);
  };
  const handleGoBack = (): void => {
    nav('/search');
  };

  const currentLocation = useMemo(() => {
    if (pathname.includes(CreateRequestMenu.InstallLocation)) {
      return CreateRequestMenu.InstallLocation;
    } else if (pathname.includes(CreateRequestMenu.Products)) {
      return CreateRequestMenu.Products;
    } else if (pathname.includes(CreateRequestMenu.ShippingLocation)) {
      return CreateRequestMenu.ShippingLocation;
    } else if (pathname.includes(CreateRequestMenu.ShippingDetails)) {
      return CreateRequestMenu.ShippingDetails;
    } else if (pathname.includes(CreateRequestMenu.NotesSection)) {
      return CreateRequestMenu.NotesSection;
    } else if (pathname.includes(CreateRequestMenu.ReviewOrder)) {
      return CreateRequestMenu.ReviewOrder;
    } else if (pathname.includes(CreateRequestMenu.RequestDetails)) {
      return CreateRequestMenu.RequestDetails;
    } else if (pathname.includes('request-info')) {
      return 'request-info';
    } else {
      return '';
    }
  }, [pathname]);

  const handleDraftSubmit = async (closeFlag: boolean): Promise<void> => {
    // setSubmitting(true);
    dispatch(setIsCreating(true));

    try {
      const data = await requestDeployment({
        ...values,
        status: DeploymentStatuses.posted,
        requestNumber: values.requestNumberSuffix ? `${values.requestNumber}-${values.requestNumberSuffix}` : values.requestNumber,
        projectId: !values.projectId ? null : values.projectId,
        isOrderReleased: !foundType?.isReleaseOrderRequired ? true : values.isOrderReleased,
        isLabConfigurationRequired: !foundType?.isLabConfigurationRequired ? false : values.isLabConfigurationRequired
      }).unwrap();

      for (const attachment of values.attachments) {
        const formData = new FormData();

        formData.append(`${attachment.name}`, attachment);

        await uploadAttachment({ deploymentRequestId: data.id, file: formData }).unwrap();
        if (isAttachmentError) {
          if ((error as { status: number }).status === 409 || (error as { originalStatus: number }).originalStatus === 409) message.error('This file has already been attached.');
        }
      }
      dispatch(setIsCreating(false));

      setConfirmationInfo(data as unknown as DeploymentRequestPayload);
    } catch (error: any) {
      dispatch(setIsCreating(false));

      console.log(error);
      const todayDate = new Date();
      const requestDate = new Date(values.requestedDeliveryDate);

      if (requestDate < todayDate) {
        message.error(`Request Delivery Date: ${values.requestedDeliveryDate.slice(0, 10)} is NOT valid...`);
      } else {
        message.error(error.data);
      }
    }
  };

  const handleDraftUpdate = async (closeFlag: boolean): Promise<void> => {
    if (data && id) {
      setSubmitting(true);

      try {
        values.requestNumber = `${values.requestNumber}--${values.requestNumberSuffix}`;
        const responseData = await updateDeployment({
          ...values,
          status: DeploymentStatuses.draft,
          projectId: values.projectId ?? null,
          isOrderReleased: !foundType?.isReleaseOrderRequired ? true : values.isOrderReleased,
          isLabConfigurationRequired: !foundType?.isLabConfigurationRequired ? false : values.isLabConfigurationRequired
        }).unwrap();

        const fileNames = responseData.attachments.map((each) => {
          return each.name;
        });

        for (const attachment of values.attachments) {
          const formData = new FormData();

          if (fileNames.includes(attachment.name) === false) {
            formData.append(attachment.name, attachment);
            await uploadAttachment({ deploymentRequestId: id, file: formData }).unwrap();
            if (isAttachmentError) {
              if ((error as { status: number }).status === 409 || (error as { originalStatus: number }).originalStatus === 409) message.error('This file has already been attached.');
            }
          }
        }

        setConfirmationInfo(responseData);
      } catch (error: any) {
        console.log(error);
        const todayDate = new Date();
        const requestDate = new Date(values.requestedDeliveryDate);

        if (requestDate < todayDate) {
          message.error(`Request Delivery Date: ${values.requestedDeliveryDate.slice(0, 10)} is NOT valid...`);
        } else {
          message.error(error.data);
        }
      }
      setSubmitting(false);
    }
  };

  const handleSubmittedSubmit = async (): Promise<void> => {
    Modal.destroyAll();
    if (!values.contactEmail || !values.contactName || !values.contactPhone) {
      message.error('All contact fields must be filled out');

      return;
    }
    if (id) {
      //FROM EDITING PAGES =====================================
      setApiCallFlag(true);
      try {
        values.requestNumber = `${values.requestNumber}--${values.requestNumberSuffix}`;
        setLoadingMessage('Validating sales order header...');
        const responseData = await updateDeployment({
          ...values,
          status: DeploymentStatuses.draft,
          projectId: values.projectId ?? undefined,
          isOrderReleased: !foundType?.isReleaseOrderRequired ? true : values.isOrderReleased,
          isLabConfigurationRequired: !foundType?.isLabConfigurationRequired ? false : values.isLabConfigurationRequired
        }).unwrap();

        const fileNames = responseData.attachments.map((each) => {
          return each.name;
        });

        setLoadingMessage('Uploading attachments...');
        for (const attachment of values.attachments) {
          const formData = new FormData();

          // Check to see if attachment is already existing first, then uploads if not
          if (fileNames.includes(attachment.name) === false) {
            formData.append(attachment.name, attachment);
            await uploadAttachment({ deploymentRequestId: id, file: formData }).unwrap();
            if (isAttachmentError) {
              if ((error as { status: number }).status === 409 || (error as { originalStatus: number }).originalStatus === 409) message.error('This file has already been attached.');
            }
          }
        }
        // Posts Request
        setLoadingMessage('Posting sales order header...');

        const response = await submitDeployment(id).unwrap();

        setSubmittedDeploymentRequest(response);

        // submit attachments if there are any
        if (responseData.attachments.length) {
          responseData.attachments.forEach(async (attachment, i) => {
            setLoadingMessage(`Posting attachment ${i} of ${responseData.attachments.length}`);
            await postAttachment({ deploymentRequestId: id, fileName: attachment.name });
          });
        }
      } catch (error: any) {
        setApiCallFlag(false);
        message.error(`Error Status ${error.status} : ${error.data.errorMessage}`, 5);
      }
    } else {
      //FROM NEW CREATION PAGES ==================================
      setApiCallFlag(true);
      try {
        showModal();
        // SAVE AS DRAFT
        setLoadingMessage('Validating sales order header...');
        const data = await requestDeployment({
          ...values,
          requestNumber: values.requestNumberSuffix ? `${values.requestNumber}-${values.requestNumberSuffix}` : values.requestNumber,
          status: values.attachments.length > 0 ? DeploymentStatuses.draft : DeploymentStatuses.submitted,
          isOrderReleased: !foundType?.isReleaseOrderRequired ? true : values.isOrderReleased,
          isLabConfigurationRequired: !foundType?.isLabConfigurationRequired ? false : values.isLabConfigurationRequired
          // projectId: values.configType === 'Disposition' ? null : values.projectId
        }).unwrap();

        // SAVE ATTACHMENTS
        for (const attachment of values.attachments) {
          let i = 1;

          setLoadingMessage(`Uploading attachment ${i} of ${values.attachments.length}...`);
          const formData = new FormData();

          if (data.attachments.includes(attachment) === false) {
            formData.append(attachment.name, attachment);
            await uploadAttachment({ deploymentRequestId: data.id, file: formData }).unwrap();
            if (isAttachmentError) {
              if ((error as { status: number }).status === 409 || (error as { originalStatus: number }).originalStatus === 409) message.error('This file has already been attached.');
            }
          }
          i++;
        }

        // POST REQUEST
        setLoadingMessage('Posting sales order header...');
        const reps = await submitDeployment(data.id).unwrap();

        setSubmittedDeploymentRequest(reps);

        // Line Items

        if (data?.attachments.length) {
          data?.attachments.forEach(async (attachment, i) => {
            setLoadingMessage(`Posting attachment ${i} of ${data?.attachments.length}`);
            await postAttachment({ deploymentRequestId: data.id, fileName: attachment.name }).unwrap();
          });
        }
      } catch (error: any) {
        Modal.error({
          afterClose: () => nav(`/search`),
          title: `Error Status ${error.status}`,
          content: <Typography.Text>{error.data.errorMessage}...</Typography.Text>
        });
        console.log(error);
      }
    }
  };

  // Only submit line items after we are sure the deployment request has been posted (having issues with a race condition where the line item was submitting before the deployment req was posted)
  useEffect(() => {
    const submitLineItems = async (submittedDeploymentRequest: DeploymentRequest): Promise<void> => {
      const lineItemSubmitModal = Modal.info({
        title: 'Deployment Request Line Items',
        bodyStyle: { maxHeight: 800 },
        width: '60vw',
        okText: 'Cancel',
        icon: <></>,
        content: (
          <>
            <Loader loadingMessage="Validating Line Items" />
          </>
        )
      });
      const stager: LineItemErrorObject[] = [];

      for (const [idx, lineItem] of submittedDeploymentRequest.lineItems.entries()) {
        try {
          await submitLineItem({ deploymentRequestId: id ?? submittedDeploymentRequest.id, lineItemId: lineItem.id }).unwrap();

          if (lineItem.productName) stager.push({ key: `${idx}`, lineProductName: lineItem.productName, lineStatus: true, lineMessage: 'SUCCESS' });
        } catch (lineError: any) {
          // message.error(`Error Status ${lineError.status} : ${lineError.data.errorMessage}`);
          if (lineItem.productName) stager.push({ key: `${idx}`, lineProductName: lineItem.productName, lineStatus: false, lineMessage: lineError.data.errorMessage });
        }

        lineItemSubmitModal.update({
          content: (
            <>
              <Loader loadingMessage="Submitting Line Items" />
              <Row justify="center">
                <Typography.Title level={4}>
                  {idx + 1} of {submittedDeploymentRequest.lineItems.length}
                </Typography.Title>
              </Row>
            </>
          )
        });
      }

      setLineErrorMessages(stager);
      setModalRequestID(data?.id || null);
      setApiCallFlag(false);
      if (stager.length === submittedDeploymentRequest.lineItems.length) lineItemSubmitModal.destroy();
    };

    if (submittedDeploymentRequest) submitLineItems(submittedDeploymentRequest);
  }, [data?.id, id, submittedDeploymentRequest]);

  const handleDelete: React.MouseEventHandler<HTMLElement> = async (event): Promise<void> => {
    if (id) {
      Modal.error({
        width: 600,
        okText: 'Delete',
        okType: 'danger',
        onOk: async () => {
          try {
            event.stopPropagation();
            await deleteDeploymentRequest(id);
            nav('/search');
            message.success('Successfully deleted deployment request');
          } catch (error) {
            message.success('Deployment request deletion failed!');
          }
        },
        cancelText: 'Cancel',
        onCancel: () => {
          console.log('canceled popup');
        },
        closable: true,
        title: <Typography.Title level={5}>Delete Request {data?.requestNumber} ?</Typography.Title>,
        content: <Typography.Text>Are you sure you want to delete this Deployment Request? You can still recover it in the Recycle Bin...</Typography.Text>
      });
    }
  };

  const handleSave = (): void => {
    setIsSave(true);
    id ? handleDraftUpdate(false) : handleDraftSubmit(false);
  };

  const menuJSX = (
    <Menu disabledOverflow={true}>
      <Menu.Item key="1" onClick={handleSave}>
        Save
      </Menu.Item>
    </Menu>
  );

  const handleOk = (): void => {
    setLineErrorMessages([]);
    setModalRequestID(null);
    setIsModalOpen(false);
  };

  const handleClose = (): void => {
    if (isSave) {
      setConfirmationInfo(undefined);
      nav(`/edit/${id ?? confirmationInfo?.id}/${currentLocation}`);
      setLineErrorMessages([]);
      setModalRequestID(null);
      setIsModalOpen(false);

      return;
    }
    nav('/');
    setLineErrorMessages([]);
    setModalRequestID(null);
    setIsModalOpen(false);
  };

  //console.log(postAttachmentError, 'attach error');

  /* ******************** Render ******************** */
  return (
    <Space size="large" style={styles.container}>
      <Typography.Title style={{ marginBottom: 0 }} level={4}>
        {id ? 'Edit Deployment Request' : 'Create Deployment Request'}
      </Typography.Title>
      {/* <DeploymentRequestInfo /> */}
      <Space size="small">
        <Button onClick={handleGoBack} icon={<ArrowLeftOutlined />}>
          Cancel
        </Button>
        {id ? (
          <Button onClick={handleDelete} type="ghost" danger>
            Delete
          </Button>
        ) : null}
        <Dropdown.Button
          type="primary"
          // disabled={!validProjectStep || !validDate}
          onClick={(): void => {
            setIsSave(false);
            if (id) handleDraftUpdate(true);
            else handleDraftSubmit(true);
          }}
          loading={isSubmitting || apiCallFlag}
          overlay={menuJSX}>
          Save &amp; Close
        </Dropdown.Button>
        <Button
          type="default"
          disabled={!isValidToPost}
          onClick={() => Modal.confirm({ onOk: handleSubmittedSubmit, title: <Typography.Title level={5}>Are you sure you want to post this deployment request?</Typography.Title> })}
          loading={isSubmitting || apiCallFlag || isConfigLoading}>
          Post Request
        </Button>
      </Space>
      <Modal
        // afterClose={handleCancel}
        onOk={handleClose}
        visible={confirmationInfo !== undefined}
        cancelButtonProps={{ style: { display: 'none' } }}
        onCancel={handleClose}
        closeIcon={<></>}
        bodyStyle={{ background: 'linear-gradient(to right bottom, #979797, #acacac, #c1c1c1, #d7d7d7, #ededed)', padding: 0, height: 400, position: 'relative', borderRadius: 5 }}>
        {<DeploymentRequestConfirmation confirmationInfo={confirmationInfo} />}
      </Modal>
      <Modal
        width={1000}
        bodyStyle={{
          background: 'linear-gradient(to right bottom, #979797, #acacac, #c1c1c1, #d7d7d7, #ededed)',
          padding: 0,
          position: 'relative',
          borderRadius: 5
        }}
        // title={<Typography.Title level={3}>{validLineItemPopup ? 'Request Submitted Successfully' : 'Request Submitting...'}</Typography.Title>}
        afterClose={() => {
          modalRequestID ? nav(`/details/${modalRequestID}`) : nav(`/search`);
        }}
        visible={validLineItemPopup}
        onOk={handleOk}
        //onCancel={handleCancel}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ disabled: validLineItemPopup ? false : true }}
        okText="Continue">
        <Row>
          <Typography.Title level={3} style={{ margin: 20 }}>
            {validLineItemPopup ? `Request Successfully Posted ${submittedDeploymentRequest?.salesOrderNumber}` : ''}
          </Typography.Title>
        </Row>
        {validLineItemPopup && <Divider style={{ marginTop: 0, marginBottom: 18 }} />}
        {validLineItemPopup && (
          <Row style={{ marginLeft: 20 }}>
            <Col span={6}>
              <Typography.Text>Product Name</Typography.Text>
            </Col>
            <Col span={2}>
              <Typography.Text>Status</Typography.Text>
            </Col>
            <Col span={16}>
              <Typography.Text>Message</Typography.Text>
            </Col>
          </Row>
        )}
        <Row style={{ maxHeight: 400, overflowY: validLineItemPopup ? 'auto' : undefined, overflowX: 'hidden' }}>
          {validLineItemPopup &&
            lineErrorMessages.map((each, index) => {
              return (
                // <Row key={index}>
                <Row
                  key={index}
                  justify="space-between"
                  align="middle"
                  style={{
                    borderTop: index > 0 ? '1px solid rgba(0,0,0,.1)' : 'none',
                    background: '#ffffff70',
                    padding: '5px 25px',
                    borderRadius: 10,
                    margin: '10px 10px 0px 10px',
                    width: '100%',
                    minHeight: 55
                  }}>
                  <Col span={6}>{each.lineProductName}</Col>
                  <Col span={2} style={{ justifyContent: 'center' }}>
                    {each.lineStatus ? (
                      <div style={{ width: 20, marginLeft: 10, marginTop: 5 }}>
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                          <circle className="path circle" fill="none" stroke="#73AF55" strokeWidth="10" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                          <polyline className="path check" fill="none" stroke="#73AF55" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
                        </svg>
                      </div>
                    ) : (
                      <div style={{ width: 20, marginLeft: 10, marginTop: 5 }}>
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                          <circle className="path circle" fill="none" stroke="#D06079" strokeWidth="7" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                          <line className="path line" fill="none" stroke="#D06079" strokeWidth="7" strokeLinecap="round" strokeMiterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3" />
                          <line className="path line" fill="none" stroke="#D06079" strokeWidth="7" strokeLinecap="round" strokeMiterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2" />
                        </svg>
                      </div>
                    )}
                  </Col>
                  <Col span={16}>{each.lineMessage}</Col>
                  {/* </Row> */}
                </Row>
              );
            })}
        </Row>
        {validLineItemPopup && <Divider style={{ margin: '18px 0' }} />}
        <Col style={{ bottom: 0, width: '100%', marginTop: 50 }}>
          <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
            <defs>
              <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
            </defs>
            <g className="parallax">
              <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
              <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
              <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
              <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
            </g>
          </svg>
        </Col>
      </Modal>
    </Space>
  );
};
