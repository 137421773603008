import { Select } from 'antd';
import { NamePath } from 'antd/lib/form/interface';
import { FC, useState } from 'react';
import usePlacesAutocomplete, { getDetails } from 'use-places-autocomplete';

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

type GoogleAutoCompleteProps = {
  setAddressValues: (name: NamePath, value: any) => void;
};

export const GoogleAutoComplete: FC<GoogleAutoCompleteProps> = ({ setAddressValues }) => {
  const [searchValue, setSearchValue] = useState('');

  const {
    ready,
    suggestions: { status, data },
    setValue,
    clearSuggestions
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300
  });

  const handleInput = (value: string): void => {
    // Update the keyword of the input element
    setValue(value);
    setSearchValue(value);
  };

  const handleSelect = async (value: string, option: { label: string; value: string; placeId: string }): Promise<void> => {
    const { placeId } = option;

    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter to "false"
    setValue('', false);
    clearSuggestions();
    setSearchValue('');

    // Get latitude and longitude via utility functions
    const { address_components }: { address_components: { long_name: string; short_name: string; types: string[] }[] } = await getDetails({ placeId, fields: ['address_components'] });

    const streetNumber = address_components.find((comp) => comp.types.includes('street_number'))?.long_name ?? undefined;
    const route = address_components.find((comp) => comp.types.includes('route'))?.long_name ?? undefined;
    const city = address_components.find((comp) => comp.types.includes('locality'))?.long_name ?? undefined;
    const state = address_components.find((comp) => comp.types.includes('administrative_area_level_1'))?.long_name ?? undefined;
    const zipCode = address_components.find((comp) => comp.types.includes('postal_code'))?.long_name ?? undefined;
    const country = address_components.find((comp) => comp.types.includes('country'))?.short_name ?? undefined;
    const subpremise = address_components.find((comp) => comp.types.includes('subpremise'))?.long_name ?? undefined;

    setAddressValues('street', streetNumber && route ? `${streetNumber} ${route}` : '');
    setAddressValues('street2', `${subpremise ?? ''}`);
    setAddressValues('city', city);
    setAddressValues('country', country);
    setAddressValues('state', state);
    setAddressValues('zipCode', zipCode);
  };

  const suggestions = data.map((suggestion) => {
    const {
      place_id,
      structured_formatting: { main_text, secondary_text }
    } = suggestion;

    return { value: `${main_text} ${secondary_text}`, label: `${main_text} ${secondary_text}`, placeId: place_id };
  });

  return (
    <Select
      onSelect={handleSelect}
      options={suggestions}
      onSearch={handleInput}
      disabled={!ready}
      placeholder="Address Lookup"
      showSearch
      searchValue={searchValue}
      value={searchValue}
      notFoundContent={null}
    />
  );
};
