import { FancyInput } from 'components/ui/Inputs';
import { useBulkSearch } from 'hooks/useBulkSearch';
import React, { useEffect } from 'react';
import { setAlternateItemIdContains } from 'redux/services/julia/productsParams';
import { useGetXrefConfigQuery } from 'redux/services/rudyCadabby/rudyCadabbyApi';
import { useAppDispatch } from 'redux/store';
import { useDebouncedCallback } from 'use-debounce';

export const AlternateIdFilter = (): JSX.Element => {
  const { data, isLoading, isFetching } = useGetXrefConfigQuery();
  const alternateIdName = data?.alternateItemIdLabel;
  /* ******************** Hooks ******************** */
  const dispatch = useAppDispatch();
  const { clearValues } = useBulkSearch();

  /* ******************** Functions ******************** */
  const handleChange = useDebouncedCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    clearValues();
    dispatch(setAlternateItemIdContains(value || undefined));
  }, 500);

  // Clears state on unmount
  useEffect(() => {
    return (): void => {
      dispatch(setAlternateItemIdContains(undefined));
    };
  }, [dispatch]);

  return <FancyInput isSpinning={isLoading || isFetching} placeholder={alternateIdName} onChange={handleChange} />;
};
