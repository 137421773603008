import { DeleteOutlined } from '@ant-design/icons';
import { Button, Modal, message } from 'antd';
import { FavoriteListPayload } from 'models/Favorites';
import { FC } from 'react';
import { useUpsertUserFavoritesMutation } from 'redux/services/fuzzyFace/fuzzyFaceApi';

interface Props {
  lists: FavoriteListPayload[];
  index: number;
}

export const DeleteListItem: FC<Props> = ({ lists, index }) => {
  const [upsertUserFavorites, { isLoading: isUpdating }] = useUpsertUserFavoritesMutation();

  const handleDelete = async (): Promise<void> => {
    const newArr = lists.filter((list) => list.name !== lists[index].name);

    try {
      await upsertUserFavorites({ payload: { lists: newArr } });
      message.success(`List has been succussfully deleted`);
    } catch (e) {
      console.error(e);
      message.error((e as { data: { errorMessage: string } }).data.errorMessage);
    }
  };
  const handleButtonClick = (ev: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    ev.stopPropagation();
    Modal.confirm({
      title: 'Are you sure you want to delete this list? All products contained within the list will be lost.',
      onOk: handleDelete
    });
  };

  return <Button icon={<DeleteOutlined />} onClick={handleButtonClick} />;
};
