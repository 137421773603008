import { message, Modal, Select } from 'antd';
import { useFormikContext } from 'formik';
import { DeploymentRequestPayload } from 'models/DeploymentRequest';
import { DeploymentRequestType } from 'models/DeploymentRequestType';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useGetDeploymentRequestTypesQuery } from 'redux/services/drNobelPrice/drNobelPriceApi';
import { setSelectedDeploymentRequestType } from 'redux/slices/appSlice';
import { FormLabel } from '../FormItems/FormLabel';

const { Option } = Select;

export const RequestTypeSelect: React.FC = (): JSX.Element => {
  const { data, isError, isLoading } = useGetDeploymentRequestTypesQuery();
  const { values, setFieldValue } = useFormikContext<DeploymentRequestPayload>();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { pathname } = useLocation();

  const isCreate = pathname.includes('create');

  useEffect(() => {
    if (isCreate && data?.data.length === 1) setFieldValue('deploymentRequestTypeId', data?.data[0].id);
  }, [data?.data, isCreate]);

  const dispatch = useDispatch();

  const [selectedRequestTypeName, setSelectedRequestTypeName] = useState(data?.data.find((type) => values.deploymentRequestTypeId === type.id)?.name ?? '');
  const [selectedRequestTypeId, setSelectedRequestTypeId] = useState(data?.data.find((type) => values.deploymentRequestTypeId === type.id)?.id ?? '');

  useEffect(() => {
    const requestTypeName = data?.data.find((type) => values.deploymentRequestTypeId === type.id)?.name || null;

    if (requestTypeName) setSelectedRequestTypeName(requestTypeName);
  }, [values]);

  const handleOk = (): void => {
    setFieldValue('deploymentRequestTypeId', selectedRequestTypeId);
    setFieldValue('lineItems', []);
    setFieldValue('projectId', '');
    setFieldValue('taskId', '');
    setIsModalVisible(false);
  };

  const handleCancel = (): void => {
    setSelectedRequestTypeName('');
    setIsModalVisible(false);
  };

  const onChange = (value: string): void => {
    const selectedConfig = data?.data.find((type) => type.name === value);

    dispatch(setSelectedDeploymentRequestType(selectedConfig));

    setSelectedRequestTypeName(selectedConfig?.name || '');
    setSelectedRequestTypeId(selectedConfig?.id || '');

    if (selectedConfig && values.lineItems.length === 0) {
      setFieldValue('deploymentRequestTypeId', selectedConfig.id);
      setFieldValue('projectId', '');
      setFieldValue('taskId', '');
    } else {
      setIsModalVisible(true);
    }
  };

  if (isError) {
    message.error('Failed to fetch deployment request types. Please try again later');
  }

  return (
    <>
      <FormLabel label={'Request Type'} />
      <Select
        loading={isLoading}
        disabled={isLoading}
        showSearch
        value={selectedRequestTypeName ? selectedRequestTypeName : data?.data.length === 1 ? data?.data[0].name : undefined}
        style={{ width: '100%', paddingTop: 10 }}
        placeholder="Select a Type"
        optionFilterProp="children"
        onChange={onChange}
        filterOption={(input, option) => (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())}>
        {!isLoading &&
          data?.data.map((type: DeploymentRequestType, index: number) => {
            return (
              <Option key={`${index}`} value={type.name}>
                {type.name}
              </Option>
            );
          })}
      </Select>
      <Modal title="Deployment Request Type Change Warning" visible={isModalVisible} onOk={handleOk} okText={'Confirm'} onCancel={handleCancel}>
        <p>Changing the Type of a Deployment Request will remove all Products from the Product Cart and The Assigned Project Type...</p>
      </Modal>
    </>
  );
};
