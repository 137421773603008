import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Row, Space, Typography } from 'antd';
import { useFormikContext } from 'formik';
import { DeploymentRequestPayload } from 'models/DeploymentRequest';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { useGetAddressQuery } from 'redux/services/chuckieSue/addressesApi';
import { useGetDeploymentRequestTypeQuery } from 'redux/services/drNobelPrice/drNobelPriceApi';

const styles: InlineStylesModel = {
  headerTitle: {
    fontWeight: 'bold',
    color: '#2F5596'
  }
};

export const DeploymentRequestInfo = (): JSX.Element => {
  const { values } = useFormikContext<DeploymentRequestPayload>();
  const { data } = useGetDeploymentRequestTypeQuery(values.deploymentRequestTypeId ?? skipToken);
  const { data: installAddress } = useGetAddressQuery(values.installAddressId ?? skipToken);
  const { data: shippingAddress } = useGetAddressQuery(values.shippingAddressId ?? skipToken);

  const cartTotal = values.lineItems.reduce((acc, item) => item.quantity + acc, 0);

  return (
    <>
      <Row align="middle">
        <Space size="large" align="start" style={{ marginBottom: 8 }}>
          {/* <Typography.Text style={{ marginBottom: 0, fontSize: 14 }}>Request Info: </Typography.Text> */}
          <Space>
            <Typography.Text style={styles.headerTitle}>Request Name: </Typography.Text>
            <Typography.Text>
              {values.requestNumber
                ? `${values.requestNumber}${values.requestNumberSuffix ? '--' + values.requestNumberSuffix : ''}`
                : `AUTOGENERATED${values.requestNumberSuffix ? '--' + values.requestNumberSuffix : ''}`}
            </Typography.Text>
          </Space>
          {values.customerReferenceNumber && (
            <Space>
              <Typography.Text style={styles.headerTitle}>Customer Reference Number: </Typography.Text>
              <Typography.Text>{values.customerReferenceNumber}</Typography.Text>
            </Space>
          )}
          {values.customerPurchaseOrderNumber && (
            <Space>
              <Typography.Text style={styles.headerTitle}>Customer PO Number: </Typography.Text>
              <Typography.Text>{values.customerPurchaseOrderNumber}</Typography.Text>
            </Space>
          )}
          {values.deploymentRequestTypeId && data?.name && (
            <Space>
              <Typography.Text style={styles.headerTitle}>Request Type: </Typography.Text>
              <Typography.Text>{data.name}</Typography.Text>
            </Space>
          )}
          {values.status && (
            <Space>
              <Typography.Text style={styles.headerTitle}>Status: </Typography.Text>
              <Typography.Text style={{ textTransform: 'capitalize' }}>{values.status}</Typography.Text>
            </Space>
          )}
          {!!values.lineItems.length && (
            <Space>
              <Typography.Text style={styles.headerTitle}>Items in Cart: </Typography.Text>
              <Typography.Text>{cartTotal}</Typography.Text>
            </Space>
          )}
        </Space>
      </Row>
      <Row align="middle">
        <Space size="large" align="center" style={{ marginBottom: 8 }}>
          {values.installAddressId && installAddress && (
            <Space>
              <Typography.Text style={styles.headerTitle}>Install Address: </Typography.Text>
              <Typography.Text ellipsis={{ tooltip: '' }} style={{ textTransform: 'capitalize' }}>
                {installAddress.name
                  ? `${installAddress.name}: ${installAddress.code}: ${installAddress.street1}, ${installAddress.city}, ${installAddress?.subdivision?.localCode || ''}, ${
                      installAddress.country?.alpha2Code || ''
                    } ${installAddress.postalCode}`
                  : ''}
              </Typography.Text>
            </Space>
          )}
          {values.shippingAddressId && shippingAddress && (
            <Space>
              <Typography.Text style={styles.headerTitle}>Shipping Address: </Typography.Text>
              <Typography.Text ellipsis={{ tooltip: '' }} style={{ textTransform: 'capitalize' }}>
                {shippingAddress.name
                  ? `${shippingAddress.name}: ${shippingAddress.code}: ${shippingAddress.street1}, ${shippingAddress.city}, ${shippingAddress?.subdivision?.localCode || ''}, ${
                      shippingAddress.country?.alpha2Code || ''
                    } ${shippingAddress.postalCode}`
                  : ''}
              </Typography.Text>
            </Space>
          )}
          {values.requestedDeliveryDate && (
            <Space>
              <Typography.Text style={styles.headerTitle}>Requested Delivery Date: </Typography.Text>
              <Typography.Text>{values.requestedDeliveryDate.slice(0, 10)}</Typography.Text>
            </Space>
          )}
        </Space>
      </Row>
    </>
  );
};
