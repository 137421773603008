import { FancyDateRangePicker } from 'components/ui/Inputs';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { setEndDate, setStartDate } from 'redux/services/elmo/deploymentRequestsParams';
import { ReduxState, useAppDispatch } from 'redux/store';

interface Props {
  labelTitle: string;
}

export const DateFilter = ({ labelTitle }: Props): JSX.Element => {
  const { startDate, endDate } = useSelector((app: ReduxState) => app.elmoDeploymentRequestsParams);
  /* ******************** Hooks ******************** */
  const dispatch = useAppDispatch();

  /* ******************** Functions ******************** */
  const handleChange = (value: unknown, formatString: [string, string]): void => {
    dispatch(setStartDate(formatString[0] || undefined));
    dispatch(setEndDate(formatString[1] || undefined));
  };

  return <FancyDateRangePicker onChange={handleChange} label={labelTitle} rangeValues={startDate || endDate ? [moment(startDate), moment(endDate)] : []} />;
};
