import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';
import lodash from 'lodash';
import { GetProductsParams, GetProductsResponse } from 'models/Product';
import { ReduxState } from 'redux/store';

/* ******************** Base Query ******************** */
const baseUrl = process.env.REACT_APP_JULIA_BASE_URL || '';
const functionsKey = process.env.REACT_APP_API_HOST_KEY_JULIA || '';

export const juliaBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
  const divisionId = (api.getState() as ReduxState).app.acuityContext?.selectedCustomer.id;
  const isDiagnostic = typeof args !== 'string' && (args.url === '/diagnostics/version' || args.url === '/diagnostics/apiName');

  if (!divisionId && !isDiagnostic) {
    return {
      error: {
        status: 400,
        statusText: 'Bad Request',
        data: 'No division ID received'
      }
    };
  }
  const urlEnd = typeof args === 'string' ? args : args.url;
  const adjustedUrl = isDiagnostic ? args.url : `customers/${divisionId}/${urlEnd}`;
  const adjustedArgs = typeof args === 'string' ? adjustedUrl : { ...args, url: adjustedUrl };

  return fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as ReduxState).app.accessToken;

      if (token) {
        headers.set('authorization', `Bearer ${token}`);
        headers.set('x-functions-key', functionsKey);
        headers.set('Content-Type', 'application/json');
      }

      return headers;
    }
  })(adjustedArgs, api, extraOptions);
};

export const juliaApi = createApi({
  reducerPath: 'juliaApi',
  baseQuery: juliaBaseQuery,
  tagTypes: ['Products'],
  endpoints: (builder) => ({
    getProducts: builder.query<GetProductsResponse, GetProductsParams>({
      query: (params) => ({
        url: 'productCatalogItems',
        params: lodash.pickBy(params, (v) => v !== undefined)
      }),
      onCacheEntryAdded: async (_arg, _api) => {
        try {
          await _api.cacheDataLoaded;
          const { originalArgs } = _api.getCacheEntry();
          const { queries } = _api.getState().juliaApi;
          const lastQueryArgs = { ...originalArgs, offset: _arg.offset - 25 };
          const lastQueryData = Object.values(queries).find((value) => lodash.isEqual(value?.originalArgs, lastQueryArgs));

          if (lastQueryData) {
            _api.updateCachedData((draft) => {
              draft.data.unshift(...(lastQueryData.data as any).data);
            });
          }
        } catch (error) {
          console.log(error);
        }
      },
      providesTags: ['Products']
    }),
    getProductsForBulkSearch: builder.query<GetProductsResponse, { multiPartProductId: string; multiPartAltId: string }>({
      query: ({ multiPartProductId, multiPartAltId }) => ({
        url: `productCatalogItems?${multiPartProductId}&${multiPartAltId}`
      })
    }),
    getProductQuantity: builder.query<number, string>({
      query: (productId) => ({
        url: `products/${productId}/quantityAvailable`
      })
    }),
    getVersion: builder.query<string, void>({
      query: () => ({
        url: '/diagnostics/version',
        responseHandler: (response): Promise<string> => response.text()
      })
    }),
    getApiName: builder.query<string, void>({
      query: () => ({
        url: '/diagnostics/apiName',
        responseHandler: (response): Promise<string> => response.text()
      })
    })
  })
});

export const { useGetProductsQuery, useGetProductQuantityQuery, useGetVersionQuery, useGetApiNameQuery, useLazyGetProductsQuery, useLazyGetProductsForBulkSearchQuery } = juliaApi;
