import { FancyInput } from 'components/ui/Inputs';
import { useBulkSearch } from 'hooks/useBulkSearch';
import React, { useEffect } from 'react';
import { setKitIdContains } from 'redux/services/gonzo/gonzoParams';
import { useAppDispatch } from 'redux/store';
import { useDebouncedCallback } from 'use-debounce';

export const KitIdFilter = (): JSX.Element => {
  /* ******************** Hooks ******************** */
  const dispatch = useAppDispatch();
  const { clearValues } = useBulkSearch();

  /* ******************** Functions ******************** */
  const handleChange = useDebouncedCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    clearValues();
    dispatch(setKitIdContains(value || undefined));
  }, 500);

  // Clears state on unmount
  useEffect(() => {
    return (): void => {
      dispatch(setKitIdContains(undefined));
    };
  }, [dispatch]);

  return <FancyInput placeholder={'Kit Id'} onChange={handleChange} />;
};
