import { PlusOutlined } from '@ant-design/icons';
import { Button, Input, List, Popover, Spin, message } from 'antd';
import { MessageType } from 'antd/lib/message';
import { FavoriteProduct } from 'models/Favorites';
import { Product } from 'models/Product';
import { FC, useState } from 'react';
import { useGetUserFavoritesQuery, useUpsertUserFavoritesMutation } from 'redux/services/fuzzyFace/fuzzyFaceApi';
import { ListMenuItem } from './ListMenuItem';

interface Props {
  children: React.ReactNode;
  product: Product | Partial<Product>;
}

export interface ListType {
  name: string;
  description: string;
  products: FavoriteProduct[];
}

export const ListMenu: FC<Props> = ({ children, product }) => {
  const [open, setOpen] = useState(false);

  const { data, isLoading, isFetching } = useGetUserFavoritesQuery();

  const [value, setValue] = useState('');

  const [upsertUserFavorites, { isLoading: isUpdating }] = useUpsertUserFavoritesMutation();

  const handleAddListItem = async (): Promise<void | MessageType> => {
    if (data?.lists.some((list) => list.name === value)) return message.error('List name already in use');
    // if (list.some((listNode) => listNode.name === value) || !value) return message.error('List already exists');
    // setList((prev) => [...prev, { name: value, description: value, products: [] }]);

    try {
      await upsertUserFavorites({ payload: { lists: [...(data?.lists ?? []), { name: value, products: [] }] } });

      message.success(`${value} added to user favorites lists`, 5);
    } catch (e) {
      console.error(e);
      message.error((e as { data: { errorMessage: string } }).data.errorMessage);
    }

    setValue('');
  };

  return (
    <Popover
      onVisibleChange={(vis): void => {
        setOpen(vis);
        setValue('');
      }}
      content={
        <List
          loading={isLoading || isFetching}
          locale={{ emptyText: 'No favorite lists created yet' }}
          footer={
            <Spin spinning={isUpdating}>
              <Input.Group style={{ display: 'flex' }} compact>
                <Input onPressEnter={handleAddListItem} value={value} onChange={(e): void => setValue(e.target.value)} placeholder="Add New List" style={{ flex: 1 }} />
                <Button icon={<PlusOutlined />} type="primary" onClick={handleAddListItem} />
              </Input.Group>
            </Spin>
          }
          dataSource={data?.lists}
          renderItem={(list, index): JSX.Element => <ListMenuItem idx={index} product={product} lists={data?.lists ?? []} />}
        />
      }
      trigger="click"
      placement="rightBottom"
      visible={open}
      overlayInnerStyle={{ margin: 0, padding: 0, borderRadius: 10, width: 200 }}>
      {children}
    </Popover>
  );
};
