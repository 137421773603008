import { SearchOutlined } from '@ant-design/icons';
import { Button, Col, Modal, Row, Spin, Typography, message } from 'antd';
import { Loader } from 'components/common/Loader';
import { FC, Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLazyGetProductsForBulkSearchQuery } from 'redux/services/julia/juliaApi';
import { useGetXrefConfigQuery } from 'redux/services/rudyCadabby/rudyCadabbyApi';
import { setBulkSearchResults } from 'redux/slices/appSlice';

export const ProductNumberBulkSearch: FC = () => {
  const { data, isLoading: isXrefLoading, isFetching: isXrefFetching } = useGetXrefConfigQuery();

  const [productNumbers, setProductNumbers] = useState<string[]>([]);
  const [altIDs, setAltIDs] = useState<string[]>([]);
  const [isOpen, setIsOpen] = useState(false);

  const dispatch = useDispatch();

  const [trigger, { isLoading, isFetching }] = useLazyGetProductsForBulkSearchQuery();

  const prodInputRef = useRef<HTMLTextAreaElement | null>(null);
  const altIdInputRef = useRef<HTMLTextAreaElement | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setProductNumbers(e.target.value.split(/\r?\n/));
  };

  const handleAltIdChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    setAltIDs(e.target.value.split(/\r?\n/));
  };

  const handleConfirm = async (): Promise<void> => {
    const mappedProds = productNumbers.map((prod, idx) => (idx === productNumbers.length - 1 ? `productNumbers=${prod}` : `productNumbers=${prod}&`)).join('');
    const mappedAltIds = altIDs.map((altId, idx) => (idx === altIDs.length - 1 ? `alternateItemIds=${altId}` : `alternateItemIds=${altId}&`)).join('');

    const resp = await trigger({ multiPartProductId: mappedProds, multiPartAltId: mappedAltIds }).unwrap();

    if (resp.totalCount) {
      dispatch(setBulkSearchResults(resp.data));
      setIsOpen(false);
      setProductNumbers([]);
      setAltIDs([]);
      if (prodInputRef?.current?.value) prodInputRef.current.value = '';
      if (altIdInputRef?.current?.value) altIdInputRef.current.value = '';

      return;
    }

    message.error('No results found');
  };

  const handleCancel = (): void => {
    setIsOpen(false);
    setProductNumbers([]);
    setAltIDs([]);
    if (prodInputRef?.current?.value) prodInputRef.current.value = '';
    if (altIdInputRef?.current?.value) altIdInputRef.current.value = '';
  };

  useEffect(() => {
    return (): void => {
      dispatch(setBulkSearchResults(undefined));
    };
  }, []);

  return (
    <Fragment>
      <Button loading={isXrefFetching || isXrefLoading} type="primary" icon={<SearchOutlined />} onClick={(): void => setIsOpen(true)}>
        Bulk Search
      </Button>

      <Modal
        closable={false}
        onCancel={handleCancel}
        okText="Search"
        onOk={handleConfirm}
        title={
          <Row style={{ width: '100%', backgroundColor: 'rgba(255, 165, 0, 0.4)', padding: 16, borderRadius: 5 }}>
            <Typography.Title style={{ margin: 0, padding: 0 }} level={4}>
              Bulk Search
            </Typography.Title>
          </Row>
        }
        width={700}
        visible={isOpen}>
        <Spin spinning={isLoading || isFetching} indicator={<Loader loadingMessage="Searching" />}>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Typography.Title level={5}>Product Number</Typography.Title>
              <textarea className="custom-text-area" ref={prodInputRef} onChange={handleChange} style={{ height: 400, width: '100%' }} />
            </Col>
            <Col span={12}>
              <Typography.Title level={5}>{data?.alternateItemIdLabel ?? 'Alternate Item Id'}</Typography.Title>
              <textarea className="custom-text-area" ref={altIdInputRef} onChange={handleAltIdChange} style={{ height: 400, width: '100%' }} />
            </Col>
          </Row>
        </Spin>
      </Modal>
    </Fragment>
  );
};
