import { green } from '@ant-design/colors';
import { LoadingOutlined } from '@ant-design/icons';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Card, Col, Row, Space, Tooltip, Typography } from 'antd';
import { useFormikContext } from 'formik';
import { DeploymentRequestPayload } from 'models/DeploymentRequest';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { InvOnHandResp } from 'models/InventoryOnHand';
import { ProductPayload } from 'models/Product';
import React from 'react';
import { useGetDeploymentRequestTypeQuery } from 'redux/services/drNobelPrice/drNobelPriceApi';
import { useGetInventoryQuery } from 'redux/services/mrJohnson/mrJohnsonApi';
import { formatCurrency } from 'utils/formatters';
const { Text, Title } = Typography;

interface Props {
  product: ProductPayload;
}

const styles: InlineStylesModel = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    lineHeight: 1
  },
  cardBody: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },

  cardTitle: {
    width: '250px'
  },
  productInfo: { display: 'flex', flexDirection: 'column', textAlign: 'end' },
  productDescription: {
    flex: 1,
    marginBottom: 24
  }
};

export const ProductCartCard: React.FC<Props> = ({ product, children }) => {
  /* ******************** Hooks ******************** */
  const { values } = useFormikContext<DeploymentRequestPayload>();

  const { data: typeData } = useGetDeploymentRequestTypeQuery(values.deploymentRequestTypeId ?? skipToken);

  const isOverageAllowed = typeData?.isOverageAllowed;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  const { data, isLoading, isError, error } = useGetInventoryQuery(
    {
      typeId: values.deploymentRequestTypeId as string,
      product: product.productNumber?.toUpperCase() as string,
      includeCommonStock: false,
      payload: values.fullShippingAddress as any
    },
    { skip: !values.deploymentRequestTypeId || !product.productNumber || !values.fullShippingAddress }
  );

  const handleGetTotalQuantity = (allInventoryTotals: InvOnHandResp[]): number => {
    if (allInventoryTotals.length === 0) return 0;
    else {
      const sumTotal = (arr: any[]) => arr.reduce((sum, { quantityAvailable }) => sum + quantityAvailable, 0);
      const totalInventory = sumTotal(allInventoryTotals);

      return totalInventory;
    }
  };
  const isQtyError = handleGetTotalQuantity(data || []) < product.quantity && !isOverageAllowed;

  const cardTitleJSX = (
    <div style={styles.cardTitle}>
      <Space direction="vertical">
        <Title style={{ margin: 0 }} level={4}>
          {product.productName}
        </Title>
        <Text style={{ fontWeight: 'normal', fontSize: '12px' }} type="secondary">
          {product.manufacturer} | {product.category}
        </Text>
        <Row>
          <Tooltip title={'Alternate Item Id'}>
            <Text style={{ fontWeight: 'normal', fontSize: '12px' }} type="secondary">
              {product.alternateItemId}
            </Text>
          </Tooltip>
        </Row>
      </Space>
    </div>
  );

  /* ******************** Render ******************** */
  return (
    <Card style={styles.container} title={cardTitleJSX} bodyStyle={styles.cardBody}>
      <Row>
        <Col span={14}>
          <Text style={styles.productDescription}>{product.productDescription}</Text>
        </Col>
        <Col span={6}>
          <div style={styles.productInfo}>
            <Text type="success">Available: {isLoading ? <LoadingOutlined style={{ color: green.primary }} /> : handleGetTotalQuantity(data || [])}</Text>
            <Text type="secondary">
              Unit Cost: <Text>{formatCurrency(product.customerStandardCost)}</Text>
            </Text>
            <Text type="danger">{isQtyError && <Text type="danger">You have ordered more than the available stock.</Text>}</Text>
          </div>
        </Col>
        <Col span={4} style={{ textAlign: 'right' }}>
          {children}
        </Col>
      </Row>
    </Card>
  );
};
