import { Form, InputProps } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useFormikContext } from 'formik';
import { FormLabel } from './FormLabel';

interface Props extends InputProps {
  fieldName: string;
  label: string;
}

export const NoteInput = ({ fieldName, label, ...rest }: Props): JSX.Element => {
  const { getFieldMeta, handleChange, getFieldHelpers } = useFormikContext<any>();
  const { value, touched, error } = getFieldMeta<string>(fieldName);
  const { setTouched } = getFieldHelpers(fieldName);

  return (
    <Form.Item
      hasFeedback={touched && !error}
      labelAlign="left"
      validateStatus={touched && error ? 'error' : 'success'}
      label={<FormLabel label={label} />}
      help={touched && error ? error : undefined}
      style={{ marginBottom: 0, color: 'rgba(0,0,0,0.85)' }}>
      {/* <Input multiple {...rest} value={value || undefined} onChange={handleChange(fieldName)} onFocus={() => setTouched(false)} onBlur={() => setTouched(true)} /> */}
      <TextArea value={value || undefined} onChange={handleChange(fieldName)} rows={4} maxLength={400} onFocus={() => setTouched(false)} onBlur={() => setTouched(true)} showCount />
    </Form.Item>
  );
};
