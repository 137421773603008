import { Col, Row } from 'antd';
import { DateFilter, OrderNameFilter, OrderNumberFilter, ReferenceFilter, RequesterFilter, StatusFilter } from './components';
import { CustomerOrderNumberFilter } from './components/CustomerOrderNumberFilter';
import { TypeFilter } from './components/TypeFilter';

export const ControlFilters = (): JSX.Element => {
  return (
    <Row gutter={[16, 16]}>
      <Col xs={24} md={5}>
        <OrderNameFilter />
      </Col>
      <Col xs={24} md={5}>
        <ReferenceFilter />
      </Col>
      <Col xs={24} md={5}>
        <CustomerOrderNumberFilter />
      </Col>
      <Col xs={24} md={5}>
        <OrderNumberFilter />
      </Col>
      <Col xs={24} md={4}>
        <RequesterFilter />
      </Col>
      <Col>
        <StatusFilter />
      </Col>
      <Col>
        <TypeFilter />
      </Col>
      <Col xs={24} md={6}>
        <DateFilter labelTitle="Created Date Range" />
      </Col>
      {/* <Col xs={24} md={6}>
        <DateFilter labelTitle="Shipping Date Range" />
      </Col> */}
      <Col span={11}></Col>
    </Row>
  );
};
