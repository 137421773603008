import { AimOutlined, BarcodeOutlined, CheckOutlined, EnvironmentOutlined, ExclamationOutlined, EyeOutlined, FormOutlined, RightOutlined, SolutionOutlined, UserAddOutlined } from '@ant-design/icons';
import { Col, Menu, Space, Tooltip } from 'antd';
import { useFormikContext } from 'formik';
import { DeploymentRequestPayload } from 'models/DeploymentRequest';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useGetDeploymentRequestConfigQuery, useGetDeploymentRequestTypesQuery } from 'redux/services/drNobelPrice/drNobelPriceApi';
import { setProductsView } from 'redux/slices/formSlice';
import { colors, toRgb } from 'styles/colors';

export enum CreateRequestMenu {
  RequestDetails = 'request-details',
  InstallLocation = 'install-location',
  Products = 'products',
  ShippingLocation = 'shipping-location',
  RequestInfo = 'request-info',
  Contact = 'contact-info',
  ReviewOrder = 'review-order'
}

const styles: InlineStylesModel = {
  wrapper: {
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  container: {
    display: 'flex',
    overflow: 'hidden'
  },
  navContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 40
  },
  navIcon: {
    paddingRight: 20,
    paddingLeft: 20,
    cursor: 'pointer',
    border: 0,
    backgroundColor: 'transparent'
  }
};

export const StepControls = (): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { id: deploymentRequestId } = useParams();

  const { data, isError, isLoading } = useGetDeploymentRequestTypesQuery();
  const { data: configData } = useGetDeploymentRequestConfigQuery();
  const { values, errors, touched } = useFormikContext<DeploymentRequestPayload>();

  const [translateX, _setTranslateX] = useState(0);
  const [selectedRequestRuleTypeName, setSelectedRequestRuleTypeName] = useState(data?.data.find((type) => values.deploymentRequestTypeId === type.id)?.dispositionRuleType ?? '');

  const areProjectsEnabled =
    (!!configData?.areProjectsEnabled && selectedRequestRuleTypeName === 'Disposition') || selectedRequestRuleTypeName === 'SpecificProjects' || selectedRequestRuleTypeName === 'AllProjects';
  const validDetailsStep = true;
  const validInstallLocationStep = values.installAddressId !== null && values.fullInstallAddress !== null;
  const validProductsStep = values.lineItems?.length > 0;
  const validShippingLocationStep = values.shippingAddressId !== null && values.fullShippingAddress !== null;
  const validShippingDetailsStep = values.requestedDeliveryDate !== '';
  const validTypeStep = values.deploymentRequestTypeId !== '';
  const validContactInfoStep = values.contactEmail !== '' && values.contactName !== '' && values.contactPhone !== '';

  const requestShippingDateHasErrors = Boolean(errors.requestedDeliveryDate && touched.requestedDeliveryDate);

  //TO DO PROJECT EVALIDATION
  const invalidProjectStep = validTypeStep && areProjectsEnabled ? (values.projectId === '' ? true : false) : selectedRequestRuleTypeName === 'Disposition' ? false : true;

  const validateRequestIdentificationStep = (): boolean => {
    if (areProjectsEnabled && values.projectId && values.deploymentRequestTypeId) return true;
    if (!areProjectsEnabled && values.deploymentRequestTypeId) return true;

    return false;
  };

  useEffect(() => {
    const requestTypeName = data?.data.find((type) => values.deploymentRequestTypeId === type.id)?.dispositionRuleType || null;

    if (requestTypeName) setSelectedRequestRuleTypeName(requestTypeName);
  }, [values.deploymentRequestTypeId]);

  const currentLocation = useMemo(() => {
    if (pathname.includes(CreateRequestMenu.InstallLocation)) {
      return CreateRequestMenu.InstallLocation;
    } else if (pathname.includes(CreateRequestMenu.Products)) {
      return CreateRequestMenu.Products;
    } else if (pathname.includes(CreateRequestMenu.ShippingLocation)) {
      return CreateRequestMenu.ShippingLocation;
    } else if (pathname.includes(CreateRequestMenu.RequestInfo)) {
      return CreateRequestMenu.RequestInfo;
    } else if (pathname.includes(CreateRequestMenu.Contact)) {
      return CreateRequestMenu.Contact;
    } else if (pathname.includes(CreateRequestMenu.ReviewOrder)) {
      return CreateRequestMenu.ReviewOrder;
    } else {
      return CreateRequestMenu.RequestDetails;
    }
  }, [pathname]);

  const handleNavigationCreate = (info: { key: string }): void => {
    if (info.key === CreateRequestMenu.RequestDetails) {
      navigate('/create');
    } else if (info.key === CreateRequestMenu.InstallLocation) {
      navigate('/create/install-location');
    } else if (info.key === CreateRequestMenu.Products) {
      dispatch(setProductsView('grid'));
      navigate('/create/products');
    } else if (info.key === CreateRequestMenu.ShippingLocation) {
      navigate('/create/shipping-location');
    } else if (info.key === CreateRequestMenu.RequestInfo) {
      navigate('/create/request-info');
    } else if (info.key === CreateRequestMenu.Contact) {
      navigate('/create/contact-info');
    } else if (info.key === CreateRequestMenu.ReviewOrder) {
      navigate('/create/review-order');
    }
  };
  const handleNavigationEdit = (info: { key: string }): void => {
    if (info.key === CreateRequestMenu.RequestDetails) {
      navigate(`/edit/${deploymentRequestId}`);
    } else if (info.key === CreateRequestMenu.InstallLocation) {
      navigate(`/edit/${deploymentRequestId}/install-location`);
    } else if (info.key === CreateRequestMenu.Products) {
      dispatch(setProductsView('grid'));
      navigate(`/edit/${deploymentRequestId}/products`);
    } else if (info.key === CreateRequestMenu.ShippingLocation) {
      navigate(`/edit/${deploymentRequestId}/shipping-location`);
    } else if (info.key === CreateRequestMenu.RequestInfo) {
      navigate(`/edit/${deploymentRequestId}/request-info`);
    } else if (info.key === CreateRequestMenu.Contact) {
      navigate(`/edit/${deploymentRequestId}/contact-info`);
    } else if (info.key === CreateRequestMenu.ReviewOrder) {
      navigate(`/edit/${deploymentRequestId}/review-order`);
    }
  };

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      {/* <SalesOrderFormInstallLocation isFormValid={true} /> */}
      <Menu onClick={deploymentRequestId ? handleNavigationEdit : handleNavigationCreate} mode="horizontal" style={{ justifyContent: 'center' }} selectedKeys={[currentLocation]}>
        <Menu.Item key={CreateRequestMenu.RequestDetails}>
          <Space style={validDetailsStep ? styles.valid : undefined}>
            <FormOutlined />
            <div>Request Identification</div>
            {validateRequestIdentificationStep() && <CheckOutlined style={{ color: toRgb(colors.illuminatingEmerald) }} />}
            <RightOutlined />
          </Space>
        </Menu.Item>
        <Menu.Item disabled={!validateRequestIdentificationStep()} key={CreateRequestMenu.InstallLocation}>
          <Space style={validInstallLocationStep ? styles.valid : undefined}>
            <EnvironmentOutlined />
            <div>Install Location</div>
            {/* {installAddressHasErrors && <ExclamationOutlined style={{ color: toRgb(colors.redSalsa) }} />} */}
            {validInstallLocationStep && <CheckOutlined style={{ color: toRgb(colors.illuminatingEmerald) }} />}
            <RightOutlined />
          </Space>
        </Menu.Item>
        <Menu.Item disabled={!validateRequestIdentificationStep()} key={CreateRequestMenu.ShippingLocation}>
          <Space style={validShippingLocationStep ? styles.valid : undefined}>
            <AimOutlined />
            <div>Shipping Location</div>
            {/* {shippingAddressHasErrors && <ExclamationOutlined style={{ color: toRgb(colors.redSalsa) }} />} */}
            {validShippingLocationStep && <CheckOutlined style={{ color: toRgb(colors.illuminatingEmerald) }} />}
            <RightOutlined />
          </Space>
        </Menu.Item>
        <Menu.Item disabled={!validateRequestIdentificationStep() || !validShippingLocationStep} key={CreateRequestMenu.Products}>
          <Tooltip title={!validShippingLocationStep && 'A shipping location is required to add products'}>
            <Col>
              <Space style={validProductsStep ? styles.valid : undefined}>
                <BarcodeOutlined />
                <div>Products</div>
                {validProductsStep && <CheckOutlined style={{ color: toRgb(colors.illuminatingEmerald) }} />}
                <RightOutlined />
              </Space>
            </Col>
          </Tooltip>
        </Menu.Item>
        <Menu.Item disabled={!validateRequestIdentificationStep()} key={CreateRequestMenu.RequestInfo}>
          <Space style={validShippingDetailsStep ? styles.valid : undefined}>
            <SolutionOutlined />
            <div>Other</div>
            {requestShippingDateHasErrors && <ExclamationOutlined style={{ color: toRgb(colors.redSalsa) }} />}
            {validShippingDetailsStep && <CheckOutlined style={{ color: toRgb(colors.illuminatingEmerald) }} />}
            <RightOutlined />
          </Space>
        </Menu.Item>
        <Menu.Item disabled={!validateRequestIdentificationStep()} key={CreateRequestMenu.Contact}>
          <Space>
            <UserAddOutlined />
            <div>Contact</div>
            {validContactInfoStep && <CheckOutlined style={{ color: toRgb(colors.illuminatingEmerald) }} />}
            <RightOutlined />
          </Space>
        </Menu.Item>
        <Menu.Item disabled={!validateRequestIdentificationStep()} key={CreateRequestMenu.ReviewOrder}>
          <Space>
            <EyeOutlined />
            <div>Review Order</div>
          </Space>
        </Menu.Item>
      </Menu>
    </Space>
  );
};
