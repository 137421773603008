import { GroupOutlined, SearchOutlined, ShoppingCartOutlined, StarOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { useFormikContext } from 'formik';
import { DeploymentRequestPayload } from 'models/DeploymentRequest';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setProductsView } from 'redux/slices/formSlice';
import '../../HeaderFilters/CountStyle.css';

const CartMenu = (): JSX.Element => {
  const dispatch = useDispatch();
  const { values } = useFormikContext<DeploymentRequestPayload>();

  const itemCount = values.lineItems?.map((product) => product.quantity).reduce((previousQuantity, currentQuantity) => previousQuantity + currentQuantity, 0);

  // Clears state on unmount
  useEffect(() => {
    return (): void => {
      dispatch(setProductsView('grid'));
    };
  }, [dispatch]);

  return (
    <>
      <Menu disabledOverflow={true} mode="horizontal" defaultSelectedKeys={['grid']}>
        <Menu.Item key="grid" icon={<SearchOutlined />} onClick={(): any => dispatch(setProductsView('grid'))}>
          Search
        </Menu.Item>
        <Menu.Item key="kit" icon={<GroupOutlined />} onClick={(): any => dispatch(setProductsView('kit'))}>
          Search Kits
        </Menu.Item>
        <Menu.Item
          key="fav"
          icon={<StarOutlined />}
          onClick={(): void => {
            dispatch(setProductsView('fav'));
          }}>
          Favorites
        </Menu.Item>
        <Menu.Item key="cart" icon={<ShoppingCartOutlined />} onClick={(): any => dispatch(setProductsView('cart'))}>
          View Cart
          {itemCount <= 0 || !itemCount ? null : <span className="count">{itemCount}</span>}
        </Menu.Item>
      </Menu>
    </>
  );
};

export default CartMenu;
