import * as yup from 'yup';
import { Business } from './Application';
import { DivisionReference } from './DeploymentRequest';

export interface Product {
  alternateItem: null | { id: string };
  business: Business;
  category: string;
  createdByFullName: string;
  createdByUserId: string;
  createdDateTime: string;
  division: DivisionReference;
  documentType: string;
  hasSubstitutions: boolean;
  id: string;
  isActive: boolean;
  isDeleted: boolean;
  isItemConfigurable: boolean;
  isKit: boolean;
  manufacturer: string;
  modifiedByFullName: string;
  modifiedByUserId: string;
  modifiedDateTime: string;
  partitionKey: string;
  partitionKeyDescription: string;
  productDescription: string;
  productName: string;
  productNumber: string;
  searchName: string;
  salesNewPrice: number;
  customerXRef: {
    alternateItemId: null | string;
    customerStandardCost: null | number;
  };
}
export interface ProductPayload {
  alternateItemId?: string | null;
  id?: string;
  category?: string;
  manufacturer?: string;
  productDescription?: string;
  productName?: string;
  productNumber: string;
  quantity: number;
  customerStandardCost?: number;
  isPosted?: boolean;
  customerXRef?: {
    alternateItemId: null | string;
    customerStandardCost: null | number;
  };
}
export interface XrefPayload {
  alternateItemIdLabel: string;
  business: Business;
  createdByFullName: string;
  createdByUserId: string;
  createdDateTime: string;
  dataInheritanceLevel: string;
  division: DivisionReference;
  documentType: string;
  id: string;
  isActive: boolean;
  modifiedByFullName: string;
  modifiedByUserId: string;
  modifiedDateTime: string;
  partitionKey: string;
  partitionKeyDescription: string;
  isAlternateItemIdInUse: boolean;
}

export interface LineItemPayload {
  deploymentRequestId?: string;
  lineItemId?: string;
}

export enum ProductLabels {
  categoryName = 'Category',
  manufacturer = 'Manufacturer',
  productDescription = 'Product Description',
  productName = 'Product Name',
  productNumber = 'Product Number',
  quantity = 'Quantity',
  salesNewPrice = 'Standard Cost',
  alternateId = 'Alternate ID'
}

export const productSchema: yup.SchemaOf<ProductPayload> = yup.object({
  alternateItemId: yup.string().nullable(),
  id: yup.string().required(),
  category: yup.string().required(),
  manufacturer: yup.string().required(),
  productDescription: yup.string().required(),
  productName: yup.string().required(),
  productNumber: yup.string().required(),
  quantity: yup.number().required(),
  customerStandardCost: yup.number().required(),
  isPosted: yup.boolean(),
  customerXRef: new yup.ObjectSchema().nullable()
});

export interface GetProductsResponse {
  totalCount: number;
  data: Product[];
}

export interface GetInventoryOnHandResponse {
  conditionId: string;
  dataAreaId: string;
  dispositionId: string;
  itemId: string;
  ownerId: string;
  quantityAvailable: number;
  siteId: string;
  warehouseId: string;
}

export interface GetProductsParams {
  // TODO: Add rest
  offset: number;
  manufacturerNameContains?: string;
  categoryNameContains?: string;
  productNameContains?: string;
  productDescriptionContains?: string;
  productNumberContains?: string;
  alternateItemIdContains?: string;
  isKit?: string;
  isItemConfigurable?: string;
  hasSubstitutions?: string;
  isCommonStock?: boolean;
  deploymentTypeEquals?: string;
}
