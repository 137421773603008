import { DatePicker, Form, message, Spin, Tooltip } from 'antd';
import { useFormikContext } from 'formik';
import { DeploymentRequestPayload } from 'models/DeploymentRequest';
import moment from 'moment';
import { useGetEarliestRequestDeliveryDateQuery } from 'redux/services/elmo/elmoApi';
import { FormLabel } from './FormLabel';

interface Props {
  fieldName: string;
  label: string;
}

export const DateInput = ({ fieldName, label, ...rest }: Props): JSX.Element => {
  const { getFieldMeta, handleBlur, handleChange, values } = useFormikContext<DeploymentRequestPayload>();

  const { value, touched, error } = getFieldMeta<string>(fieldName);
  const { data, isLoading } = useGetEarliestRequestDeliveryDateQuery({
    deploymentRequestTypeId: values.deploymentRequestTypeId || '',
    destinationCountryCode: values.fullShippingAddress ? values.fullShippingAddress.country?.alpha2Code : 'US',
    destinationSubdivisionCode: values.fullShippingAddress ? values?.fullShippingAddress?.subdivision?.localCode : '',
    destinationPostalCode: values.fullShippingAddress ? values.fullShippingAddress.postalCode : ''
  });

  const validShippingSet = values.shippingAddressId && values.shippingAddressId !== '';

  const validateDate = (selectedDate: string): void => {
    if (!selectedDate) handleChange(fieldName)('');
    else {
      const today = new Date();

      today.setUTCHours(0, 0, 0, 0);
      const toggledDate = new Date(selectedDate);

      if (toggledDate >= today) {
        handleChange(fieldName)(selectedDate);
      } else {
        message.error('please select a valid delivery date...');
      }
    }
  };
  const disabledDates = (current: any): boolean => {
    const weekendIndexs = [0, 6];
    const [earliestStandardMonth, earliestStandardDay, earliestStandardYear] = data?.earliestAvailableStandardDeliveryDate.split('/') || [];
    const earliestStandardDate = new Date(`${earliestStandardMonth}-${earliestStandardDay}-${earliestStandardYear}`);

    const [earliestMonth, earliestDay, earliestYear] = data?.earliestAvailableExpeditedDeliveryDate.split('/') || [];
    const earliestDate = new Date(`${earliestMonth}-${earliestDay}-${earliestYear}`);

    if (values.isExpediteRequested) {
      return (current && current < moment().endOf('day')) || weekendIndexs.includes(new Date(current._d).getDay()) || earliestDate > new Date(current._d);
    } else {
      return (current && current < moment().endOf('day')) || weekendIndexs.includes(new Date(current._d).getDay()) || earliestStandardDate > new Date(current._d);
    }
  };

  return (
    <Form.Item
      // hasFeedback={touched && !error}
      labelAlign="left"
      validateStatus={touched && error ? 'error' : 'success'}
      label={<FormLabel label={label} />}
      help={touched ? error : undefined}
      style={{ marginBottom: 0 }}>
      <Tooltip title={!validShippingSet ? 'You must first select a valid shipping address' : undefined}>
        <DatePicker
          disabled={isLoading || !validShippingSet}
          suffixIcon={isLoading ? <Spin /> : null}
          value={value ? moment(value) : undefined}
          disabledDate={disabledDates}
          onChange={(_, dateString): void => {
            validateDate(dateString);
          }}
          onBlur={handleBlur(fieldName)}
          style={{ width: '100%' }}
          {...rest}
        />
      </Tooltip>
    </Form.Item>
  );
};
