import { DeploymentRequest } from 'models/DeploymentRequest';
import { BarChartModel } from 'models/Reports';
import { colors, toRgb, toRgba } from 'styles/colors';

interface ChartDataStager {
  name: string;
  totalCount: number;
}

const padToTwoDigits = (num: number): string => {
  return num.toString().padStart(2, '0');
};

const formatDate = (dateValue: Date): string => {
  return [padToTwoDigits(dateValue.getMonth() + 1), padToTwoDigits(dateValue.getDate()), dateValue.getFullYear()].join('/');
};

const random_RGBA = (): string => {
  const o = Math.round,
    r = Math.random,
    s = 255;

  return 'rgba(' + o(r() * s) + ',' + o(r() * s) + ',' + o(r() * s) + ',' + '.6)';
};

export const monthConverter = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

export const monthIndexConverter = (monthName: string): number => {
  switch (monthName) {
    case 'January':
      return 0;

    case 'February':
      return 1;

    case 'March':
      return 2;

    case 'April':
      return 3;

    case 'May':
      return 4;

    case 'June':
      return 5;

    case 'July':
      return 6;

    case 'August':
      return 7;

    case 'September':
      return 8;

    case 'October':
      return 9;

    case 'November':
      return 10;

    case 'December':
      return 11;

    default:
      return -1;
  }
};

export const statusChartGenerator = (allAssets: DeploymentRequest[]): BarChartModel => {
  const requestStatusChartData = {
    labels: ['Drafts', 'Posted'],
    // datasets is an array of objects where each object represents a set of data to display corresponding to the labels above. for brevity, we'll keep it at one object
    datasets: [
      {
        label: 'Total Count',
        data: [0, 0, 0], // Active, Cancelled, Expired
        // you can set indiviual colors for each bar
        backgroundColor: [toRgba(colors.orangeWeb, 0.7), toRgba(colors.illuminatingEmerald, 0.7)],
        borderWidth: 3,
        borderColor: [toRgb(colors.orangeWeb), toRgb(colors.illuminatingEmerald)]
      }
    ]
  };

  allAssets?.map((eachRequest) => {
    // By Asset Status -----------------------------------------
    if (eachRequest.status.toUpperCase() === 'DRAFT') {
      requestStatusChartData.datasets[0].data[0] += 1;
    } else if (eachRequest.status.toUpperCase() === 'POSTED') {
      requestStatusChartData.datasets[0].data[1] += 1;
    }
  });

  return requestStatusChartData;
};

export const deliveryDatesChartGenerator = (allAssets: DeploymentRequest[]): BarChartModel => {
  const deliveryDatesChartData = {
    labels: [...Array(31)].map((each, index) => {
      const todayDate = new Date();

      todayDate.setDate(todayDate.getDate() + index);

      return formatDate(todayDate);
    }),
    // datasets is an array of objects where each object represents a set of data to display corresponding to the labels above. for brevity, we'll keep it at one object
    datasets: [
      {
        label: 'Upcoming Shipping Count',
        data: [...Array(31)].map(() => 0),
        // you can set indiviual colors for each bar
        backgroundColor: [...Array(31)].map((each, index) => {
          if (index === 0) return toRgba(colors.redSalsa, 0.5);
          else if (index === 1 || index === 2) return toRgba(colors.orangeWeb, 0.5);
          else return toRgba(colors.romanSilver, 0.5);
        }),
        borderWidth: 3
      }
    ]
  };

  allAssets?.map((eachRequest) => {
    if (eachRequest.requestedDeliveryDate) {
      const requestedDate = new Date(eachRequest.requestedDeliveryDate);
      const formattedRequestDate = formatDate(requestedDate);
      const labelMatchIndex = deliveryDatesChartData.labels.indexOf(formattedRequestDate);

      if (labelMatchIndex > -1) deliveryDatesChartData.datasets[0].data[labelMatchIndex] += 1;
    }
  });

  return deliveryDatesChartData;
};

export const createdDatesChartGenerator = (allAssets: DeploymentRequest[]): BarChartModel => {
  const createdDatesChartData = {
    labels: [...Array(31)].map((each, index) => {
      const todayDate = new Date();

      todayDate.setDate(todayDate.getDate() - (30 - index));

      return formatDate(todayDate);
    }),
    // datasets is an array of objects where each object represents a set of data to display corresponding to the labels above. for brevity, we'll keep it at one object
    datasets: [
      {
        label: 'Recently Created Count',
        data: [...Array(31)].map(() => 0),
        // you can set indiviual colors for each bar
        backgroundColor: [...Array(31)].map((each, index) => {
          if (index === 0) return toRgba(colors.redSalsa, 0.5);
          else if (index === 1 || index === 2) return toRgba(colors.orangeWeb, 0.5);
          else return toRgba(colors.romanSilver, 0.5);
        }),
        borderWidth: 3
      }
    ]
  };

  allAssets?.map((eachRequest) => {
    if (eachRequest.createdDateTime) {
      const requestedDate = new Date(eachRequest.createdDateTime);
      const formattedRequestDate = formatDate(requestedDate);
      const labelMatchIndex = createdDatesChartData.labels.indexOf(formattedRequestDate);

      if (labelMatchIndex > -1) createdDatesChartData.datasets[0].data[labelMatchIndex] += 1;
    }
  });

  return createdDatesChartData;
};
