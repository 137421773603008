import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Card, Col, Row, Switch, Typography } from 'antd';
import { useFormikContext } from 'formik';
import { DeploymentRequestPayload } from 'models/DeploymentRequest';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { useParams } from 'react-router-dom';
import { useGetDeploymentRequestQuery } from 'redux/services/elmo/elmoApi';
import { useAppSelector } from 'redux/store';
import { colors, toRgba } from 'styles/colors';
const { Text, Title } = Typography;

const styles: InlineStylesModel = {
  header: {
    backgroundColor: toRgba(colors.orangeWeb, 0.4)
  }
};

export const OrderInfoContent = (): JSX.Element => {
  const { values } = useFormikContext<DeploymentRequestPayload>();
  const { id } = useParams();
  const { user } = useAppSelector((state) => state.app);
  const { data } = useGetDeploymentRequestQuery(id || skipToken);

  return (
    <Card
      headStyle={styles.header}
      style={{ height: '100%' }}
      title={
        <Title level={4} style={{ fontWeight: 'normal' }}>
          Order Details
        </Title>
      }>
      <Row gutter={16}>
        <Col span={8}>
          <Typography.Title level={5} style={styles.infoTitle}>
            Created By:
          </Typography.Title>
          <Typography.Paragraph style={styles.infoText}>{id ? data?.createdByFullName : user?.name}</Typography.Paragraph>
          <Typography.Title level={5} style={styles.infoTitle}>
            Created On:
          </Typography.Title>
          <Typography.Paragraph style={styles.infoText}>{id ? new Date(data?.createdDateTime || '').toLocaleDateString() : new Date().toLocaleDateString()}</Typography.Paragraph>
          <Typography.Title level={5} style={styles.infoTitle}>
            Is Order Released:
          </Typography.Title>
          <Typography.Paragraph style={styles.infoText}>
            <Switch disabled={true} checked={values.isOrderReleased} />
          </Typography.Paragraph>
        </Col>
        <Col span={8}>
          <Typography.Title level={5} style={styles.infoTitle}>
            Modified By:
          </Typography.Title>
          <Typography.Paragraph style={styles.infoText}>{id ? data?.modifiedByFullName : user?.name}</Typography.Paragraph>
          <Typography.Title level={5} style={styles.infoTitle}>
            Modified On:
          </Typography.Title>
          <Typography.Paragraph style={styles.infoText}>{id ? new Date(data?.modifiedDateTime || '').toLocaleDateString() : new Date().toLocaleDateString()}</Typography.Paragraph>
          <Typography.Title level={5} style={styles.infoTitle}>
            Lab Config Required:
          </Typography.Title>
          <Typography.Paragraph style={styles.infoText}>
            <Switch disabled={true} checked={values.isLabConfigurationRequired} />
          </Typography.Paragraph>
        </Col>
        <Col span={8}>
          <Typography.Title level={5} style={styles.infoTitle}>
            Contact Name:
          </Typography.Title>
          <Typography.Paragraph style={styles.infoText}>{values.contactName || 'n/a'}</Typography.Paragraph>
          <Typography.Title level={5} style={styles.infoTitle}>
            Contact Email:
          </Typography.Title>
          <Typography.Paragraph style={styles.infoText}>{values.contactEmail || 'n/a'}</Typography.Paragraph>
          <Typography.Title level={5} style={styles.infoTitle}>
            Contact Number:
          </Typography.Title>
          <Typography.Paragraph style={styles.infoText}>{values.contactPhone || 'n/a'}</Typography.Paragraph>
        </Col>
        {/* <Col span={24}>
          <Typography.Title level={5} style={styles.infoTitle}>
            Attatchments:
          </Typography.Title>
          {values.attachments.length > 0 || data?.attachments ? (
            <div>
              {values.attachments.map((eachAttachment: any, index) => {
                return (
                  <Typography.Paragraph key={index} style={styles.infoText}>
                    <a href={eachAttachment.url || '/'} target="_blank" key={index} rel="noreferrer">
                      {eachAttachment.name}
                    </a>
                  </Typography.Paragraph>
                );
              })}
              {data?.attachments.map((eachAttachment: any, index) => {
                return (
                  <Typography.Paragraph key={index} style={styles.infoText}>
                    <a href={eachAttachment.url || '/'} target="_blank" key={index} rel="noreferrer">
                      {eachAttachment.name}
                    </a>
                  </Typography.Paragraph>
                );
              })}
            </div>
          ) : (
            <Typography.Paragraph style={styles.infoText}>{'None'}</Typography.Paragraph>
          )}
        </Col> */}
        <Col span={24}>
          <Typography.Title level={5} style={styles.infoTitle}>
            Notes:
          </Typography.Title>
          <Typography.Paragraph style={styles.infoText}>{values.note || 'None'}</Typography.Paragraph>
        </Col>
      </Row>
    </Card>
  );
};
