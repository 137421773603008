import { Space, Switch, SwitchProps, Typography } from 'antd';
import { useFormikContext } from 'formik';
interface Props extends SwitchProps {
  fieldName: string;
  label: string;
  color?: string;
}

export const SwitchInput = ({ fieldName, label, color, ...rest }: Props): JSX.Element => {
  const { getFieldMeta, getFieldHelpers } = useFormikContext<any>();
  const { value } = getFieldMeta<boolean>(fieldName);
  const { setValue } = getFieldHelpers(fieldName);

  return (
    <Space size={8}>
      <Switch {...rest} onChange={(checked): void => setValue(checked)} />
      <Typography.Text style={{ color }}>{label}</Typography.Text>
    </Space>
  );
};
