/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, InputNumber, notification, Row, Table, TableColumnsType, TableProps, Typography } from 'antd';
import { ColumnType } from 'antd/es/table';
import { useFormikContext } from 'formik';
import { uniqueId } from 'lodash';
import { DeploymentRequestPayload } from 'models/DeploymentRequest';

import { Component, KitResponse } from 'models/Kit';
import { Product } from 'models/Product';
import { FC, useState } from 'react';

type Props = TableProps<KitResponse> & {
  isCreate: boolean;
  subComponents?: Component[];
};

export type TableProduct = Partial<Product> & {
  lineType: string;
  componentQuantity: number;
};

export const LineItemTable: FC<Props> = ({ isCreate, ...rest }) => {
  const [inputValues, setInputValues] = useState({});
  const { setFieldValue, values } = useFormikContext<DeploymentRequestPayload>();

  const handleInputChange = (kitId: string, value: number): void => {
    setInputValues((prev) => ({ ...prev, [kitId]: value }));
  };

  const columns: ColumnType<KitResponse>[] = [
    {
      title: 'Kit ID',
      dataIndex: 'kitId',
      key: 'kitID'
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: '# of Components',
      dataIndex: 'componentQuantity',
      key: 'componentQuantity',
      align: 'right',
      render: (text, record) => {
        const qty = record.components.flatMap((comp) => comp.subComponents).length + record.components.length;

        return <Typography.Text>{qty}</Typography.Text>;
      }
    },
    {
      title: 'Kit Price',
      dataIndex: 'kitPrice',
      key: 'kitPrice',
      align: 'right',
      render: (text, record) => <Typography.Text>{'N/A'}</Typography.Text>
    },
    {
      title: 'Qty To Order',
      dataIndex: 'qtyToOrder',
      key: 'qtyToOrder',
      align: 'center',
      render: (text, record) => {
        return (
          <InputNumber
            defaultValue={1}
            min={1}
            controls={false}
            value={(inputValues as any)[record.kitId as any] || ''}
            onChange={(e): void => handleInputChange(record.kitId, e)}
            style={{ width: 75 }}
            onBlur={(e): void => {
              if (e.target.value === '') {
                handleInputChange(record.kitId, 1);
              }
            }}
          />
        );
      }
    },
    {
      title: 'Action',
      key: 'action',
      align: 'center',
      render: (text, record) => (
        <Button
          onClick={(): void => {
            const inputValue = (inputValues as any)[record.kitId];

            const createProductObject = (product: any) => ({
              alternateItemId: product?.alternateItemId,
              id: uniqueId(),
              category: product.category,
              manufacturer: product.manufacturer || 'n/a',
              productDescription: product.description,
              productName: product.itemId,
              productNumber: product.itemId ?? '',
              quantity: inputValue ? product.componentQuantity * inputValue : product.componentQuantity,
              customerStandardCost: 0
            });

            const parentProducts = record.components.map(createProductObject);
            const childProducts = record.components.flatMap((comp) => comp.subComponents).map(createProductObject);
            const newProducts = [...parentProducts, ...childProducts];

            const updatedLineItems = values.lineItems.reduce((acc: DeploymentRequestPayload['lineItems'], currentItem) => {
              const existingIndex = newProducts.findIndex((newItem) => newItem.productNumber === currentItem.productNumber);

              if (existingIndex > -1) {
                // If the product exists, update the quantity
                const existingProduct = newProducts[existingIndex];

                acc.push({
                  ...currentItem,
                  quantity: currentItem.quantity + existingProduct.quantity
                });
                // Remove the item from newProducts as it's already processed
                newProducts.splice(existingIndex, 1);
              } else {
                // If the product does not exist in newProducts, keep it as is
                acc.push(currentItem);
              }

              return acc;
            }, []);

            // Add any remaining new products that weren't in the cart already
            const finalLineItems = [...updatedLineItems, ...newProducts];

            setFieldValue('lineItems' as keyof DeploymentRequestPayload, finalLineItems);

            notification.success({ message: 'Success', description: 'Item added to cart.', placement: 'topRight' });
          }}
          type="primary"
          icon={<PlusCircleOutlined />}>
          Add
        </Button>
      )
    }
  ];

  const expandedRowRenderSub = (record: Component) => {
    const columns: TableColumnsType<any> = [
      {
        title: 'Product Number (SubComponent)',
        dataIndex: 'itemId',
        key: 'itemId',
        width: '30ch'
      },
      {
        title: 'Alt Item Id',
        dataIndex: 'alternateItemId',
        key: 'alternateItemId',
        width: '20ch'
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        width: '30ch',
        ellipsis: true
      },
      {
        title: 'Manufacturer',
        dataIndex: 'manufacturer',
        key: 'manufacturer',
        width: '15ch'
      },
      {
        title: 'Category',
        dataIndex: 'category',
        key: 'category',
        width: '15ch'
      },
      {
        title: 'Qty',
        dataIndex: 'componentQuantity',
        align: 'right',
        width: '5ch',
        key: 'componentQuantity'
      },
      {
        title: 'List Price',
        dataIndex: 'listPrice',
        key: 'listPrice',
        render: (text, record) => <Typography.Text>{'N/A'}</Typography.Text>
      },
      {
        title: 'Discount %',
        dataIndex: 'discountPercent',
        key: 'discountPercent',
        render: (text, record) => <Typography.Text>{'N/A'}</Typography.Text>
      },
      {
        title: 'Unit Price',
        dataIndex: 'unitPrice',
        key: 'unitPrice',
        render: (text, record) => <Typography.Text>{'N/A'}</Typography.Text>
      },
      {
        title: 'Extended Price',
        dataIndex: 'extendedPrice',
        key: 'extendedPrice',
        render: (text, record) => <Typography.Text>{'N/A'}</Typography.Text>
      }
    ];

    return (
      <Row style={{ paddingTop: 25, paddingBottom: 25 }}>
        <Table style={{ width: '100%' }} columns={columns} dataSource={record.subComponents} pagination={false} />
      </Row>
    );
  };

  const expandedRowRender = (record: KitResponse) => {
    const columns: TableColumnsType<any> = [
      {
        title: 'Product Number (Main)',
        dataIndex: 'itemId',
        key: 'itemId',
        width: '30ch'
      },
      {
        title: 'Alt Item Id',
        dataIndex: 'alternateItemId',
        key: 'alternateItemId',
        width: '20ch'
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        width: '30ch',
        ellipsis: true
      },
      {
        title: 'Manufacturer',
        dataIndex: 'manufacturer',
        key: 'manufacturer',
        width: '15ch'
      },
      {
        title: 'Category',
        dataIndex: 'category',
        key: 'category',
        width: '15ch'
      },
      {
        title: 'Qty',
        dataIndex: 'componentQuantity',
        align: 'right',
        width: '5ch',
        key: 'componentQuantity'
      },
      {
        title: 'List Price',
        dataIndex: 'listPrice',
        key: 'listPrice',
        render: (text, record) => <Typography.Text>{'N/A'}</Typography.Text>
      },
      {
        title: 'Discount %',
        dataIndex: 'discountPercent',
        key: 'discountPercent',
        render: (text, record) => <Typography.Text>{'N/A'}</Typography.Text>
      },
      {
        title: 'Unit Price',
        dataIndex: 'unitPrice',
        key: 'unitPrice',
        render: (text, record) => <Typography.Text>{'N/A'}</Typography.Text>
      },
      {
        title: 'Extended Price',
        dataIndex: 'extendedPrice',
        key: 'extendedPrice',
        render: (text, record) => <Typography.Text>{'N/A'}</Typography.Text>
      }
    ];

    return (
      <Table
        style={{ width: '100%' }}
        columns={columns}
        dataSource={record.components}
        rowKey={(rec) => rec.itemId ?? ''}
        pagination={false}
        expandable={{ expandedRowRender: expandedRowRenderSub, rowExpandable: (rec) => rec?.subComponents?.length > 0 }}
      />
    );
  };

  return (
    <Table
      expandable={{
        expandedRowRender: isCreate ? undefined : expandedRowRender,
        rowExpandable: (record) => record?.components?.length > 0
      }}
      rowKey={(record) => record.kitId}
      bordered
      columns={columns}
      {...rest}
      size="small"
    />
  );
};
