import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DeploymentRequestParams } from 'models/DeploymentRequest';

const initialState: DeploymentRequestParams = {
  offset: 0,
  requester: undefined,
  referenceNumberContains: undefined,
  orderNumberContains: undefined,
  customerPurchaseOrderNumberContains: undefined,
  requestNumberContains: undefined,
  startDate: undefined,
  endDate: undefined,
  statuses: undefined,
  deploymentRequestType: undefined
};

export const elmoDeploymentRequestsParams = createSlice({
  name: 'elmoDeploymentRequestsParams',
  initialState,
  reducers: {
    setOffset: (state, { payload }: PayloadAction<DeploymentRequestParams['offset']>) => {
      state.offset = payload;
    },
    incrementOffset: (state) => {
      state.offset += 25;
    },
    setRequester: (state, { payload }: PayloadAction<DeploymentRequestParams['requester']>) => {
      state.requester = payload;
      state.offset = 0;
    },
    setReferenceNumber: (state, { payload }: PayloadAction<DeploymentRequestParams['referenceNumberContains']>) => {
      state.referenceNumberContains = payload;
      state.offset = 0;
    },
    setCustomerPurchaseOrderNumberContains: (state, { payload }: PayloadAction<DeploymentRequestParams['customerPurchaseOrderNumberContains']>) => {
      state.customerPurchaseOrderNumberContains = payload;
      state.offset = 0;
    },
    setOrderNumber: (state, { payload }: PayloadAction<DeploymentRequestParams['orderNumberContains']>) => {
      state.orderNumberContains = payload;
      state.offset = 0;
    },
    setRequestNumber: (state, { payload }: PayloadAction<DeploymentRequestParams['requestNumberContains']>) => {
      state.requestNumberContains = payload;
      state.offset = 0;
    },
    setStartDate: (state, { payload }: PayloadAction<DeploymentRequestParams['startDate']>) => {
      state.startDate = payload;
      state.offset = 0;
    },
    setEndDate: (state, { payload }: PayloadAction<DeploymentRequestParams['endDate']>) => {
      state.endDate = payload;
      state.offset = 0;
    },
    setStatuses: (state, { payload }: PayloadAction<DeploymentRequestParams['statuses']>) => {
      state.statuses = payload;
      state.offset = 0;
    },
    setType: (state, { payload }: PayloadAction<DeploymentRequestParams['deploymentRequestType']>) => {
      state.deploymentRequestType = payload;
      state.offset = 0;
    },
    resetParams: (state) => {
      Object.assign(state, initialState);
    }
  }
});

export const {
  setEndDate,
  incrementOffset,
  setCustomerPurchaseOrderNumberContains,
  setOrderNumber,
  setReferenceNumber,
  setRequestNumber,
  setRequester,
  setStartDate,
  setStatuses,
  setOffset,
  resetParams,
  setType
} = elmoDeploymentRequestsParams.actions;
