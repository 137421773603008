import { Alert, Col, Empty, Row } from 'antd';
import { Loader } from 'components/common/Loader';
import { LoadingMore } from 'components/common/LoadMore';
import InfiniteScroll from 'react-infinite-scroll-component';
import { incrementOffset } from 'redux/services/elmo/deploymentRequestsParams';
import { useGetDeploymentRequestsQuery } from 'redux/services/elmo/elmoApi';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { RecoverDeploymentCard } from './RecoverDeploymentCard';

export const DeploymentsGrid = (): JSX.Element => {
  /* ******************** Hooks ******************** */
  const { elmoDeploymentRequestsParams } = useAppSelector((state) => state);
  const { data, isError, isLoading } = useGetDeploymentRequestsQuery({ ...elmoDeploymentRequestsParams, returnDeletedDataOnly: true });

  const dispatch = useAppDispatch();

  /* ******************** Functions / Variables ******************** */
  const handleGetMore = (): void => {
    dispatch(incrementOffset());
  };

  /* ******************** Error Render ******************** */
  if (isError) {
    return <Alert showIcon message="Request Error" type="error" />;
  }

  /* ******************** Loading Render ******************** */
  if (isLoading || !data) {
    return (
      <Row style={{ marginTop: 8 }} gutter={[16, 16]}>
        <Loader />
      </Row>
    );
  }

  /* ******************** Data Render ******************** */
  const hasMore = data.totalCount > elmoDeploymentRequestsParams.offset;

  return (
    <InfiniteScroll style={{ overflowX: 'clip' }} dataLength={data.data.length} next={handleGetMore} hasMore={hasMore} loader={<LoadingMore />} scrollableTarget="scrollableDiv">
      <Row style={{ marginTop: 8 }} gutter={[16, 16]}>
        {data.data.map((deploymentRequest) => (
          <Col xs={24} md={12} lg={6} key={deploymentRequest.id}>
            <RecoverDeploymentCard deploymentRequest={deploymentRequest} />
          </Col>
        ))}
      </Row>
      {!data.totalCount && <Empty />}
    </InfiniteScroll>
  );
};
