import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Alert, Col, Row, Typography } from 'antd';
import { Loader } from 'components/common/Loader';
import NoResults from 'components/common/noResults/noResults';
import { useFormikContext } from 'formik';

import { AcuityContext } from 'models/Application';
import { DeploymentRequestPayload } from 'models/DeploymentRequest';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useLocation, useParams } from 'react-router-dom';
import { useGetAddressQuery, useGetAddressesQuery } from 'redux/services/chuckieSue/addressesApi';
import { incrementOffset, setAddressTypesInclude } from 'redux/services/chuckieSue/addressesParams';
import { Address, GetAddressesParams, LocationAddressTypes } from 'redux/services/chuckieSue/models/addresses';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { LocationCard, SelectedLocationCard } from './components';

const styles: InlineStylesModel = {
  container: {
    flexGrow: 1,
    alignContent: 'flex-start'
  },
  selectedContainer: {
    flex: 1,
    alignItems: 'flex-start'
  }
};

interface Props {
  addressType: 'shipping' | 'install';
}

export const InstallLocationsGrid = ({ addressType }: Props): JSX.Element => {
  /* ******************** Hooks ******************** */
  const { id } = useParams();
  const { pathname } = useLocation();
  const { values } = useFormikContext<DeploymentRequestPayload>();
  const { chuckieSueAddressesParams } = useAppSelector((state) => state);
  const { data: toggleAddressData, isLoading: toggleAddressIsLoading, isError: toggleAddressIsError } = useGetAddressQuery(values.installAddressId ?? skipToken);
  const { acuityContext } = useAppSelector((state) => state.app);

  const { configuration } = acuityContext as AcuityContext;
  const areAllValuesUndefined = (obj: GetAddressesParams): boolean => {
    const filterOutParams = (obj: GetAddressesParams, paramsToExclude: string[]): Partial<GetAddressesParams> => {
      const newObj = { ...obj };

      paramsToExclude.forEach((param) => {
        const val = param as keyof GetAddressesParams;

        delete newObj[val];
      });

      return newObj;
    };

    return Object.values(filterOutParams(obj, ['offset', 'addressTypesInclude'])).every((val) => val === undefined);
  };

  const areAddressesNotDisplayed = Boolean(areAllValuesUndefined(chuckieSueAddressesParams) && !configuration?.addressDetails.displayAddressesByDefault);

  const { data, isLoading, isError, isFetching, error } = useGetAddressesQuery({ ...chuckieSueAddressesParams }, { skip: areAddressesNotDisplayed });

  const [selectAddressData, setSelectedAddressData] = useState<Address>();
  const dispatch = useAppDispatch();

  /* ******************** Functions / Variables ******************** */
  const getMore = (): void => {
    dispatch(incrementOffset());
  };
  const handleSelectedAddressChange = (selectedAddress: Address): void => {
    setSelectedAddressData(selectedAddress);
  };

  /* ******************** Effects ******************** */
  useEffect(() => {
    if (toggleAddressData && !toggleAddressIsError) {
      setSelectedAddressData(toggleAddressData);
    }
  }, [toggleAddressData, pathname]);

  useEffect(() => {
    dispatch(setAddressTypesInclude([LocationAddressTypes.Install]));
  }, []);

  if (areAllValuesUndefined(chuckieSueAddressesParams) && !configuration?.addressDetails.displayAddressesByDefault && !values.installAddressId)
    return (
      <Row justify="center" style={{ marginTop: 25 }}>
        <Typography.Text style={{ fontSize: 20 }}>Please use filters above to begin search..</Typography.Text>
      </Row>
    );

  /* ******************** Loading Render ******************** */
  if (isError) {
    return <Alert type="error" message="Server error" showIcon />;
  }

  /* ******************** Error Render ******************** */
  if (isLoading || (chuckieSueAddressesParams.offset === 0 && isFetching)) {
    return (
      <Row style={styles.container} gutter={[16, 16]}>
        <Loader />
      </Row>
    );
  }
  /* ******************** No Results Render ******************** */
  if (data?.totalCount === 0) {
    return <NoResults errorMessage={'no locations were found...'} />;
  }

  const selectedCardJSX = (
    <Col key={values.installAddressId} span={12}>
      {selectAddressData ? <SelectedLocationCard handleRemoveInstallAddress={handleSelectedAddressChange} address={selectAddressData} addressType="install" /> : null}
    </Col>
  );

  const locationCardsJSX = data?.data
    .filter((address) => address.id !== values?.installAddressId)
    .map((address) => {
      return (
        <Col xs={24} sm={12} md={12} key={address.id}>
          <LocationCard handleAddressSelected={handleSelectedAddressChange} addressType={addressType} address={address} />
        </Col>
      );
    });

  const hasMore = (data?.totalCount ?? 0) > chuckieSueAddressesParams.offset && (data?.totalCount ?? 0) > 25 && !values.installAddressId;

  return (
    <InfiniteScroll style={{ width: '100%', marginTop: '8px', overflow: 'visible' }} next={getMore} hasMore={hasMore} loader={<Loader />} dataLength={data?.data.length ?? 0}>
      <Row gutter={[16, 16]}>{values.installAddressId && values.installAddressId !== '' ? [...[selectedCardJSX], ...[locationCardsJSX ?? []]] : locationCardsJSX}</Row>
    </InfiniteScroll>
  );
};
