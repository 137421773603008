import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Form, message, Modal, Spin } from 'antd';
import { Loader } from 'components/common/Loader';
import NoConfigType from 'components/common/noConfigType/NoConfigType';
import { ActionControls, StepControls } from 'components/Create/common';
import { DeploymentRequestInfo } from 'components/Create/common/DeploymentRequestInfo';
import { FormikProvider, useFormik } from 'formik';
import { DeploymentRequestPayload, deploymentRequestPayloadSchema, DeploymentStatuses } from 'models/DeploymentRequest';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { useGetAddressQuery } from 'redux/services/chuckieSue/addressesApi';
import { useGetDeploymentRequestConfigQuery, useGetDeploymentRequestTypesQuery } from 'redux/services/drNobelPrice/drNobelPriceApi';
import {
  useCreateAttachmentMutation,
  useCreateDeploymentRequestMutation,
  useGetDeploymentRequestQuery,
  useGetDeploymentRequestsQuery,
  useSubmitDeploymentRequestMutation,
  useUpdateDeploymentRequestMutation
} from 'redux/services/elmo/elmoApi';
import { useAppSelector } from 'redux/store';

const styles: InlineStylesModel = {
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    height: '100%'
  }
};

export const EditDeploymentRequestLayout = (): JSX.Element => {
  /* ******************** Hooks ******************** */
  const { id } = useParams();
  const nav = useNavigate();
  const { data, isLoading } = useGetDeploymentRequestQuery(id || skipToken);
  const { user } = useAppSelector((state) => state.app);
  const { elmoDeploymentRequestsParams } = useAppSelector((state) => state);
  const { isCreating } = useAppSelector((state) => state.form);

  const { data: requestTypesData, isError: requestTypeError } = useGetDeploymentRequestTypesQuery();
  const { data: requestConfigData, isError: requestConfigError } = useGetDeploymentRequestConfigQuery();
  const { data: installAddressData, isLoading: installAddressIsLoading, isError: installAddressIsError } = useGetAddressQuery(data?.installAddress?.id ?? skipToken);
  const { data: shippingAddressData, isLoading: shippingAddressIsLoading, isError: shippingAddressIsError } = useGetAddressQuery(data?.shippingAddress?.id ?? skipToken);

  const { refetch, isFetching } = useGetDeploymentRequestsQuery(elmoDeploymentRequestsParams);
  const [requestDeployment, { isLoading: savingRequest }] = useCreateDeploymentRequestMutation({ fixedCacheKey: 'create' });
  const [submitDeployment, { isLoading: postingRequest }] = useSubmitDeploymentRequestMutation({ fixedCacheKey: 'post' });
  const [updateDeployment, { isLoading: updatingRequest }] = useUpdateDeploymentRequestMutation({ fixedCacheKey: 'update' });

  const [uploadAttachment] = useCreateAttachmentMutation({ fixedCacheKey: 'test' });

  const handleRemoveSuffix = (fullRequestNumber: string): string => {
    if (fullRequestNumber.includes('--')) {
      const suffix = fullRequestNumber.split('--');

      return suffix[0];
    } else {
      return fullRequestNumber;
    }
  };

  const handleGetSuffix = (fullRequestNumber: string): string => {
    if (fullRequestNumber.includes('--')) {
      const suffix = fullRequestNumber.split('--');

      return suffix[1];
    } else {
      return '';
    }
  };

  const handleDateValidation = (requestDate: string): boolean => {
    const today = new Date();

    today.setUTCHours(23, 59, 59, 999);
    const toggledDate = new Date(requestDate);

    return toggledDate < today;
  };

  const formik = useFormik<DeploymentRequestPayload>({
    validationSchema: deploymentRequestPayloadSchema,
    initialValues: {
      id: id,
      requestNumber: data ? handleRemoveSuffix(data.requestNumber) : '',
      requestNumberSuffix: data ? handleGetSuffix(data.requestNumber) : '',
      customerReferenceNumber: data ? data.customerReferenceNumber || '' : '',
      customerPurchaseOrderNumber: data ? data.customerPurchaseOrderNumber || '' : '',
      deploymentRequestTypeId: data ? data.deploymentRequestTypeId : '',
      projectId: data ? data.project?.id : '',
      taskId: data ? data.project?.task?.id : '',
      installAddressId: data ? data.installAddress?.id || '' : '',
      lineItems: data?.lineItems
        ? data.lineItems.map((each) => {
            return {
              category: each.category,
              manufacturer: each.manufacturer,
              productDescription: each.productDescription,
              productNumber: each.productNumber,
              quantity: each.quantity,
              customerStandardCost: each.customerXRef?.customerStandardCost || undefined,
              productName: each.productName,
              id: each.id,
              alternateItemId: each.alternateItemId
            };
          })
        : [],
      shippingAddressId: data ? data.shippingAddress?.id || '' : '',
      contactEmail: data ? data.contactEmail || '' : '',
      contactName: data ? data.contactName || '' : '',
      contactPhone: data ? data.contactPhone || '' : '',
      requestedDeliveryDate: data ? (data.requestedDeliveryDate ? (handleDateValidation(data.requestedDeliveryDate) ? '' : data.requestedDeliveryDate) : '') : '',
      notificationEmails: data?.notificationEmails ?? [],
      note: data ? data.note || undefined : undefined,
      status: DeploymentStatuses.draft,
      isOrderReleased: requestConfigData && requestConfigData.isReleaseOrderRequired ? true : data && data.isOrderReleased ? data.isOrderReleased : false,
      isShipCompleteRequired: requestConfigData && requestConfigData.shipmentDetails.isShipCompleteRequired ? true : data && data.isShipCompleteRequired ? data.isShipCompleteRequired : false,
      isLabConfigurationRequired: data ? data.isLabConfigurationRequired : false,
      // attachments needs to be a empty array initially- existing attachment data is being fetched and rendered on RequestInfo.tsx
      attachments: [],
      fullInstallAddress: data && installAddressData && !installAddressIsError ? installAddressData : null,
      fullShippingAddress: data && shippingAddressData && !shippingAddressIsError ? shippingAddressData : null,
      isExpediteRequested: data ? data.isExpediteRequested : false
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      if (data) {
        try {
          const fileNames = data.attachments.map((each: any) => {
            return each.name;
          });

          for (const attachment of values.attachments) {
            const formData = new FormData();

            if (fileNames.includes(attachment.name) === false) {
              formData.append(attachment.name, attachment);
              await uploadAttachment({ deploymentRequestId: data.id, file: formData }).unwrap();
            }
          }

          values.requestNumber = `${values.requestNumber}--${values.requestNumberSuffix}`;

          const responseData = await submitDeployment(data.id).unwrap();

          Modal.success({
            afterClose: () => nav(`/details/${data.id}`),
            title: 'Request successfully created',
            content: `Request number: ${values.requestNumber}`
          });
        } catch (error) {
          message.error('Request Unsuccessful');
        }
      }
    }
  });

  const loadingMessage = (): string => {
    if (isCreating) return 'Creating deployment request';
    if (postingRequest) return 'Posting deployment request';
    if (updatingRequest) return 'Updating deployment request';

    return 'loading';
  };

  if (!data || isLoading) {
    return <Loader />;
  }

  if (requestConfigError) {
    <NoConfigType messageContent="is not configured for deployment requests. Please contact MDSi support." />;
  }

  if (requestTypeError || (requestTypesData && requestTypesData.totalCount === 0)) {
    return <NoConfigType messageContent="has no request types created. Please contact MDSi support." />;
  }

  return (
    <FormikProvider value={formik}>
      <Form style={styles.container} layout="vertical">
        <Spin spinning={formik.isSubmitting || savingRequest || postingRequest || updatingRequest || isCreating} indicator={<Loader loadingMessage={loadingMessage()} />}>
          <ActionControls />
          <DeploymentRequestInfo />
          <StepControls />
          {<Outlet />}
          {/* <PaginationControls /> */}
        </Spin>
      </Form>
    </FormikProvider>
  );
};
