import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Button, Card, Col, Input, InputNumber, List, Row, Space, Tooltip, Typography, message } from 'antd';
import { AntCardTitle } from 'components/common/AntCardTitle';
import { Stack } from 'components/common/Stack';
import { useFormikContext } from 'formik';
import { DeploymentRequestPayload } from 'models/DeploymentRequest';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { InvOnHandResp } from 'models/InventoryOnHand';
import { Product } from 'models/Product';
import { useRef, useState } from 'react';
import Lottie from 'react-lottie-player';
import { useSelector } from 'react-redux';
import { useGetDeploymentRequestConfigQuery, useGetDeploymentRequestTypeQuery } from 'redux/services/drNobelPrice/drNobelPriceApi';
import { useGetUserFavoritesQuery } from 'redux/services/fuzzyFace/fuzzyFaceApi';
import { useGetSubstituteItemsQuery } from 'redux/services/hermanTheMop/hermanTheMopApi';
import { useGetInventoryQuery } from 'redux/services/mrJohnson/mrJohnsonApi';
import { ReduxState, useAppSelector } from 'redux/store';
import { formatCurrency } from 'utils/formatters';
import favoriteFilledLottie from '../../../../../assets/star-filled.json';
import favoriteLottie from '../../../../../assets/star.json';
import { SubstitutionButton } from '../../common/SubstitutionButton';
import { ListMenu } from './ListMenu';
const { Text } = Typography;

interface Props {
  product: Product | Partial<Product>;
}

const styles: InlineStylesModel = {
  wrapper: {
    margin: 0,
    height: '100%'
  },
  container: {
    backgroundColor: 'white',
    borderRadius: 5,
    height: '100%'
  },
  description: {
    fontSize: '0.8em',
    display: 'flex'
  },
  bottomControls: {
    justifyContent: 'space-between',
    width: '100%',
    justifySelf: 'flex-end'
  },
  quantityInput: {
    width: '60px',
    textAlign: 'center'
  },
  currentCart: {
    textAlign: 'right'
  }
};

export const ProductGridItem = ({ product }: Props): JSX.Element => {
  /* ******************** Hooks ******************** */
  const { data: favoritesData, isLoading: isFavLoading, isFetching: isFavFetching } = useGetUserFavoritesQuery();

  const { isCommonStock } = useSelector((state: ReduxState) => state.juliaProductsParams);
  const { acuityContext } = useAppSelector((state) => state.app);

  const { setFieldValue, values } = useFormikContext<DeploymentRequestPayload>();
  const { data: requestConfigData } = useGetDeploymentRequestConfigQuery();
  const [hover, setHover] = useState<string | null>(null);

  const { data: typeData } = useGetDeploymentRequestTypeQuery(values.deploymentRequestTypeId ?? skipToken);

  const isOverageAllowed = typeData?.isOverageAllowed;

  const { data, isLoading, error } = useGetInventoryQuery(
    {
      typeId: values.deploymentRequestTypeId as string,
      product: product.productNumber?.toUpperCase() as string,
      includeCommonStock: isCommonStock !== undefined ? (isCommonStock ? true : false) : false,
      payload: values.fullShippingAddress as any
    },
    { skip: !values.deploymentRequestTypeId || !product.productNumber || !values.fullShippingAddress }
  );

  const {
    data: subsData,
    isError: isSubsError,
    isLoading: isSubsLoading,
    error: subsError
  } = useGetSubstituteItemsQuery(
    { businessId: acuityContext?.selectedCustomer?.business?.id as string, params: { substitutionOrderTypes: ['All Orders', 'Sparing Orders'] } },
    { skip: !acuityContext?.selectedCustomer?.business?.id }
  );

  const filteredSubItems = subsData?.data.filter((item) => item.item.productNumber === product.productNumber).map((item) => item.substituteItem);
  const selectedProduct = values.lineItems?.find((selectedProduct) => selectedProduct.productNumber === product.productNumber);
  const inputRef = useRef<HTMLInputElement>(null);

  // Pulling products from all favorites lists into one flat array
  const flatData = favoritesData?.lists.map((list) => list.products).flat();
  // Finding if the product is included in the flat array above to switch the favorite icon out
  const isFavorited = flatData?.some((prod) => prod.productNumber === product.productNumber);

  /* ******************** Functions / Variables ******************** */
  const handleChangeQuantity = (): void => {
    //console.log('product', product);
    if (inputRef.current && Number(inputRef.current.value) > 0) {
      const quantity = Number(inputRef.current.value);

      if (selectedProduct) {
        setFieldValue('lineItems' as keyof DeploymentRequestPayload, [
          ...values.lineItems.filter((product) => product.productNumber !== selectedProduct.productNumber),
          { ...selectedProduct, quantity: selectedProduct.quantity + quantity }
        ]);
      } else {
        const payloadProductObject: DeploymentRequestPayload['lineItems'][number] = {
          alternateItemId: product?.customerXRef?.alternateItemId ? product?.customerXRef?.alternateItemId : null,
          id: product.id,
          category: product.category,
          manufacturer: product.manufacturer || 'n/a',
          productDescription: product.productDescription,
          productName: product.productName,
          productNumber: product.productNumber ?? '',
          quantity: quantity,
          customerStandardCost: product.customerXRef ? product.customerXRef.customerStandardCost || 0 : 0
        };

        setFieldValue('lineItems' as keyof DeploymentRequestPayload, [...values.lineItems, payloadProductObject]);
      }
      inputRef.current.value = '0';
    } else {
      message.warn('Please add a valid quantity...');
    }
  };

  const handleGetTotalQuantity = (allInventoryTotals: InvOnHandResp[]): number => {
    if (allInventoryTotals.length === 0) return 0;
    else {
      const sumTotal = (arr: any[]) => arr.reduce((sum, { quantityAvailable }) => sum + quantityAvailable, 0);
      const totalInventory = sumTotal(allInventoryTotals);

      return totalInventory;
    }
  };

  const isAddDisabled = Boolean(!handleGetTotalQuantity(data ?? []) && !isOverageAllowed);

  /* ******************** Render ******************** */
  return (
    <List.Item style={styles.wrapper} colStyle={{ height: '100%', marginBottom: 8, padding: 4 }}>
      <Card
        size="small"
        headStyle={{ paddingLeft: 8, paddingRight: 8 }}
        bodyStyle={{ padding: 8, marginTop: 'auto' }}
        style={styles.container}
        hoverable
        title={
          <div style={{ display: 'flex', flexDirection: 'column', minHeight: 75 }}>
            <Row>
              <Space>
                <ListMenu product={product}>
                  <Button
                    style={{ border: 'none' }}
                    loading={isFavLoading || isFavFetching}
                    onMouseEnter={(): void => setHover('favorite')}
                    onMouseLeave={(): void => setHover(null)}
                    icon={
                      <Lottie
                        animationData={isFavorited ? favoriteFilledLottie : favoriteLottie}
                        loop={false}
                        goTo={!hover ? 0 : undefined}
                        play={hover === 'favorite'}
                        style={isFavorited ? { width: 30, height: 30, display: 'inline-block', transform: 'scale(1.5)' } : { width: 25, height: 25, display: 'inline-block', transform: 'scale(1.5)' }}
                      />
                    }
                  />
                </ListMenu>
                <AntCardTitle level={3} text={product.productNumber ?? ''} />
              </Space>
            </Row>
            <Row justify="space-between">
              <Col>
                <Space size={1} direction="vertical">
                  <Text type="secondary" style={{ fontSize: 12 }}>
                    {product.productName}
                  </Text>
                  <Text type="secondary" style={{ fontSize: 12, minHeight: 20 }}>
                    {product?.customerXRef !== null ? product?.customerXRef?.alternateItemId : ''}
                  </Text>
                </Space>
              </Col>
              <Col>
                <Space style={styles.bottomControls}>
                  {isSubsLoading && <LoadingOutlined />}
                  {filteredSubItems && !!filteredSubItems.length && <SubstitutionButton subData={filteredSubItems} isError={isSubsError} err={subsError} product={product} />}
                  <Input.Group compact>
                    <InputNumber disabled={isAddDisabled} ref={inputRef} style={styles.quantityInput} defaultValue="1" precision={0} type="int" />
                    <Button disabled={isAddDisabled} onClick={handleChangeQuantity} type="ghost" icon={<PlusOutlined />} />
                  </Input.Group>
                </Space>
              </Col>
            </Row>
          </div>
        }>
        <Row gutter={[8, 8]} align="top" style={{ minHeight: 60, paddingBottom: 10 }}>
          <Col span={24}>
            <Text type="secondary" style={styles.description}>
              {product.productDescription}
            </Text>
          </Col>
        </Row>
        <Row gutter={[8, 8]} align="bottom">
          <Col span={8}>
            <Stack flexDirection="column" gap={5} lineHeight={1}>
              <Text>Category</Text>
              <Text type="secondary">{product.category || 'NA'}</Text>
            </Stack>
          </Col>
          <Col span={16} style={{ textAlign: 'right' }}>
            <Stack flexDirection="column" gap={5} lineHeight={1}>
              <Text style={{ color: '#5867dd' }}>{selectedProduct ? `Cart Contains : ${selectedProduct.quantity}` : null}</Text>
            </Stack>
          </Col>
          <Col span={8}>
            <Stack flexDirection="column" gap={5} lineHeight={1}>
              <Text>Manufacturer</Text>
              <Text type="secondary">{product.manufacturer || 'NA'}</Text>
            </Stack>
          </Col>
          <Col span={16} style={{ textAlign: 'right' }}>
            <Stack flexDirection="column" gap={5} lineHeight={1}>
              {isLoading ? (
                <Text type="success">
                  Loading: <LoadingOutlined />
                </Text>
              ) : (
                <Tooltip title={error ? (error as any).data ?? 'Error getting inventory' : undefined}>
                  <Text type={error ? 'danger' : 'success'}>Available: {error ? 'Error' : handleGetTotalQuantity(data || [])}</Text>
                </Tooltip>
              )}
              {requestConfigData?.isStandardCostDisplayed && (
                <Text type="secondary">
                  Unit Cost: <Text>{formatCurrency(product?.customerXRef !== null ? product?.customerXRef?.customerStandardCost : 0)}</Text>
                </Text>
              )}
            </Stack>
          </Col>
        </Row>
      </Card>
    </List.Item>
  );
};
