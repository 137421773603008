import { ArrowLeftOutlined, SyncOutlined } from '@ant-design/icons';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Button, Row, Space } from 'antd';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetDeploymentRequestQuery } from 'redux/services/elmo/elmoApi';

export const ControlActions = (): JSX.Element => {
  const nav = useNavigate();
  const { id } = useParams();
  const { isFetching, refetch } = useGetDeploymentRequestQuery(id || skipToken);

  const handleNavigateBack = (): void => {
    nav('/');
  };

  return (
    <Row justify="end">
      <Space size={8}>
        <Button type="primary" onClick={handleNavigateBack} icon={<ArrowLeftOutlined />}>
          Back
        </Button>
        <Button loading={isFetching} onClick={refetch} icon={<SyncOutlined />}>
          Refresh
        </Button>
      </Space>
    </Row>
  );
};
