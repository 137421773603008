import { Button, message, Modal, Row, Space, Spin, Typography } from 'antd';
import { BlockLoader } from 'components/common/BlockLoader';
import { DeploymentRequest } from 'models/DeploymentRequest';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useGetDeploymentRequestsQuery, usePurgeDeploymentRequestMutation, useRecoverDeploymentRequestMutation } from 'redux/services/elmo/elmoApi';
import { setSelectedDeploymentRequests } from 'redux/slices/selectedDeploymentRequestSlice';
import { ReduxState } from 'redux/store';

interface Props {
  deletedDeploymentRequests?: DeploymentRequest[] | undefined;
}

export const RecoverActions = ({ deletedDeploymentRequests }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const naviagte = useNavigate();
  const [showPurgeModal, _setShowPurgeModal] = useState(false);
  const [deploymentRequestCount, _setDeploymentRequestCount] = useState(1);
  const { selectedDeploymentRequests } = useSelector((state: ReduxState) => state.selectedDeploymentRequests);
  const { elmoDeploymentRequestsParams } = useSelector((state: ReduxState) => state);
  const { data: deploymentRequestData, isLoading, isFetching } = useGetDeploymentRequestsQuery({ ...elmoDeploymentRequestsParams, returnDeletedDataOnly: true });

  const [recoverDeploymentRequest, { isLoading: isRecoveringDeploymentRequest }] = useRecoverDeploymentRequestMutation();
  const [purgeDeploymentRequest, { isLoading: isDeletingDeploymentRequest }] = usePurgeDeploymentRequestMutation();

  const totalSelectedDeploymentRequests = selectedDeploymentRequests.length;

  const handleRestoreDeploymentRequest = async (): Promise<void> => {
    if (selectedDeploymentRequests.length < 1) {
      message.error('No entity selected!');

      return;
    }

    for (const deploymentRequest of selectedDeploymentRequests) {
      try {
        await recoverDeploymentRequest(deploymentRequest.id as string).unwrap();
      } catch {
        message.error(`Deployment Request ${deploymentRequest.requestNumber} failed to be recovered!`);
      }
    }

    dispatch(setSelectedDeploymentRequests([]));
    message.success(`Deployment Request(s) were successfully recovered!`);
  };

  const handlePurgeItems = async (): Promise<void> => {
    if (selectedDeploymentRequests.length < 1) {
      message.error('No items have been selected to be deleted!');

      return;
    }
    for (const deploymentRequest of selectedDeploymentRequests) {
      try {
        await purgeDeploymentRequest(deploymentRequest.id).unwrap();

        _setDeploymentRequestCount((prev) => prev + 1);
        message.success(`Purged deploymentRequests(s) successfully!`);
      } catch (error) {
        console.log(error, '<<<<');
        message.error((error as { data: string }).data);
      }
    }

    // _setPurgeCount(1);
    _setShowPurgeModal(false);
    _setDeploymentRequestCount(1);
    dispatch(setSelectedDeploymentRequests([]));
  };

  const handleSelectAll = (): void => {
    if (deploymentRequestData) {
      dispatch(setSelectedDeploymentRequests(deploymentRequestData.data));
    }
  };

  const handleClearAll = (): void => {
    dispatch(setSelectedDeploymentRequests([]));
  };

  return (
    <Row justify="space-between" style={{ marginTop: 5 }}>
      <Row justify="space-between">
        <Button disabled={!deploymentRequestData?.data.length} onClick={handleSelectAll} style={{ marginRight: 5 }} type="primary">
          Select All
        </Button>
        <Button danger ghost disabled={!totalSelectedDeploymentRequests} onClick={handleClearAll} style={{ marginRight: 5 }}>
          Clear All
        </Button>
      </Row>
      <Row justify="space-between">
        <Button
          onClick={handleRestoreDeploymentRequest}
          style={totalSelectedDeploymentRequests ? { marginRight: 5, background: '#4e937a', borderColor: '#4e937a', color: 'white' } : { marginRight: 5 }}
          loading={isRecoveringDeploymentRequest}
          disabled={!totalSelectedDeploymentRequests}>
          Restore Selected ({totalSelectedDeploymentRequests})
        </Button>
        <Button onClick={(): void => _setShowPurgeModal(!showPurgeModal)} danger loading={isDeletingDeploymentRequest} disabled={!totalSelectedDeploymentRequests}>
          Purge Selected ({totalSelectedDeploymentRequests})
        </Button>
      </Row>
      <Modal
        title="Are you sure?"
        okText={`Purge Items (${totalSelectedDeploymentRequests})`}
        visible={showPurgeModal}
        onOk={handlePurgeItems}
        closable={!isDeletingDeploymentRequest}
        maskClosable={!isDeletingDeploymentRequest}
        onCancel={(): void => _setShowPurgeModal(false)}
        cancelButtonProps={isDeletingDeploymentRequest ? { style: { pointerEvents: 'none', opacity: '.3' } } : undefined}
        okButtonProps={isDeletingDeploymentRequest ? { style: { background: '#dc3545', pointerEvents: 'none', opacity: '.3', border: 'none' } } : { style: { background: '#dc3545', border: 'none' } }}>
        <Spin
          spinning={isDeletingDeploymentRequest}
          indicator={
            <Space style={{ width: '100%', marginLeft: '-50%', marginTop: '-37%' }}>
              <BlockLoader direction="loader loader--slideUp" message={`Purging Type ${deploymentRequestCount} of ${totalSelectedDeploymentRequests}...`} />
            </Space>
          }>
          <Typography.Text type="danger">IF YOU CONTINUE, YOU WILL NO LONGER BE ABLE TO RECOVER THE SELECTED DEPLOYMENT REQUEST(S).</Typography.Text>
        </Spin>
      </Modal>
    </Row>
  );
};

export default RecoverActions;
