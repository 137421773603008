import { Button, Form, Input } from 'antd';
import { useFormik, useFormikContext } from 'formik';
import { rest } from 'lodash';
import { DeploymentRequestPayload } from 'models/DeploymentRequest';
import * as yup from 'yup';
import { FormLabel } from '../FormItems/FormLabel';

const subscribeSchema = yup.object({
  email: yup.string().label('Subscriber Email').email()
});

export const SubscribeInput = (): JSX.Element => {
  const { setFieldValue, values: contextValues } = useFormikContext<DeploymentRequestPayload>();

  const { touched, values, handleBlur, handleChange, errors, handleSubmit } = useFormik({
    validationSchema: subscribeSchema,
    initialValues: {
      email: ''
    },
    onSubmit: async ({ email }, { resetForm }) => {
      setFieldValue('notificationEmails' as keyof DeploymentRequestPayload, [...contextValues.notificationEmails, email]);
      resetForm();
    }
  });

  const handleEnterPressed = (): void => {
    if (!errors.email) {
      handleSubmit();
    }
  };

  return (
    <Form.Item
      labelAlign="left"
      validateStatus={errors.email ? 'error' : 'success'}
      label={<FormLabel label="Subscriber Email" />}
      help={touched.email ? errors.email : undefined}
      style={{ marginBottom: 0 }}>
      <Input.Group style={{ display: 'flex' }} compact>
        <Input {...rest} onPressEnter={handleEnterPressed} value={values.email || undefined} onChange={handleChange('email')} onBlur={handleBlur('email')} style={{ flex: 1 }} />
        <Button type="primary" disabled={Boolean(errors.email)} onClick={(): void => handleSubmit()}>
          Add
        </Button>
      </Input.Group>
    </Form.Item>
  );
};
