import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DeploymentRequest } from 'models/DeploymentRequest';

interface InitialState {
  selectedDeploymentRequests: DeploymentRequest[];
}

const initialState: InitialState = {
  selectedDeploymentRequests: []
};

export const selectedDeploymentRequests = createSlice({
  name: 'selectedDeploymentRequests',
  initialState,
  reducers: {
    setSelectedDeploymentRequests: (state, { payload }: PayloadAction<DeploymentRequest[]>) => {
      state.selectedDeploymentRequests = payload;
    },
    addSelectedDeploymentRequests: (state, { payload }: PayloadAction<DeploymentRequest>) => {
      state.selectedDeploymentRequests = [...state.selectedDeploymentRequests, payload];
    },
    removeSelectedDeploymentRequests: (state, { payload }: PayloadAction<DeploymentRequest>) => {
      state.selectedDeploymentRequests = state.selectedDeploymentRequests.filter((item) => item.id !== payload.id);
    }
  }
});

export const { setSelectedDeploymentRequests, addSelectedDeploymentRequests, removeSelectedDeploymentRequests } = selectedDeploymentRequests.actions;
