import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { Card, Modal, Tooltip, Typography } from 'antd';
import WimsicalError from 'components/common/WimsicalError/WimsicalError';
import { formatErrorMessage } from 'helpers/formatErrorMessage';
import { QueryErrorModel } from 'models/ErrorModel';
import { Product } from 'models/Product';
import { SubstituteProduct } from 'models/Substitutions';
import { FC, Fragment, useState } from 'react';
import Lottie from 'react-lottie-player';
import swapLottie from '../../../../assets/swap.json';
import { ProductSubGrid } from '../ProductsGrid/ProductSubGrid';

type Props = {
  product: Product | Partial<Product>;
  subData: SubstituteProduct[];
  err?: FetchBaseQueryError | SerializedError;
  isError: boolean;
};

export const SubstitutionButton: FC<Props> = ({ subData, err, isError, product }) => {
  const [hover, setHover] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const error = err as QueryErrorModel;

  return (
    <Fragment>
      <Tooltip title="Substitute product">
        <Lottie
          onClick={(): void => setIsOpen(true)}
          onMouseEnter={(): void => setHover(true)}
          onMouseLeave={(): void => setHover(false)}
          animationData={swapLottie}
          loop
          goTo={!hover ? 10 : undefined}
          play={hover}
          style={{ width: 27, height: 27, cursor: 'pointer' }}
        />
      </Tooltip>
      <Modal
        width={(subData ?? [])?.length > 1 ? 800 : 500}
        style={{ maxHeight: 700 }}
        onOk={(): void => setIsOpen(false)}
        cancelButtonProps={{ style: { visibility: 'hidden' } }}
        okText="Exit"
        closable={false}
        onCancel={(): void => setIsOpen(false)}
        visible={isOpen}>
        <Card
          headStyle={{ backgroundColor: 'rgba(24, 167, 153, 0.518)' }}
          title={
            <Typography.Title level={4} style={{ fontWeight: 'normal', padding: 0, margin: 0 }}>
              Substitute product for {product.productNumber}
            </Typography.Title>
          }
          bodyStyle={{ maxHeight: 700, overflow: 'scroll' }}
          style={{ height: '100%', minHeight: '100%', width: '100%' }}>
          {isError && (
            <WimsicalError
              title={formatErrorMessage(typeof error.status === 'number' ? error.status : 400)}
              subTitle={error?.data?.errorMessage ? error.data.errorMessage : ''}
              statusCode={error.status}
            />
          )}
          {!isError && <ProductSubGrid substituteProducts={subData ?? []} />}
        </Card>
      </Modal>
    </Fragment>
  );
};
