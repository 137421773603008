import { EditOutlined } from '@ant-design/icons';
import { Button, Input, Popover, Space, Spin, message } from 'antd';
import { FavoriteListPayload } from 'models/Favorites';
import { FC, useState } from 'react';
import { useUpsertUserFavoritesMutation } from 'redux/services/fuzzyFace/fuzzyFaceApi';

interface Props {
  lists: FavoriteListPayload[];
  index: number;
}

const EditListItem: FC<Props> = ({ lists, index }) => {
  const [visible, setVisible] = useState(false);
  const [inputValue, setInputValue] = useState(lists[index].name);

  const [upsertUserFavorites, { isLoading: isUpdating }] = useUpsertUserFavoritesMutation();

  const handleButtonClick = (ev: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    ev.stopPropagation();
    setVisible(true);
  };

  const handlePopoverVisibleChange = (visible: boolean): void => {
    setVisible(visible);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setInputValue(e.target.value);
  };

  const handlePopoverSubmit = async (): Promise<void> => {
    if (lists.some((list) => list.name === inputValue)) return message.error('List name is already in use');
    const mappedProdIds = lists[index].products?.map((prod) => ({ productCatalogId: prod.productCatalogId }));

    const newArr = [...lists.slice(0, index), { products: mappedProdIds, name: inputValue }, ...lists.slice(index + 1)];

    try {
      await upsertUserFavorites({ payload: { lists: newArr } }).unwrap();
      message.success(`${newArr[index].name} has been succussfully updated`);
    } catch (e) {
      console.error(e);
      message.error((e as { data: { errorMessage: string } }).data.errorMessage);
    }

    setInputValue('');
    setVisible(false);
  };

  const content = (
    <Spin spinning={isUpdating}>
      <Space>
        <Input placeholder="Edit List Name" value={inputValue} onPressEnter={handlePopoverSubmit} onChange={handleInputChange} />
        <Button type="primary" onClick={handlePopoverSubmit}>
          Submit
        </Button>
      </Space>
    </Spin>
  );

  return (
    <Popover content={content} visible={visible} trigger="click" onVisibleChange={handlePopoverVisibleChange}>
      <Button icon={<EditOutlined />} onClick={handleButtonClick} />
    </Popover>
  );
};

export default EditListItem;
