import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { Col, Row } from 'antd';
import { useGetKitsQuery } from 'redux/services/gonzo/gonzoApi';
import { useAppSelector } from 'redux/store';
import { BlockLoader } from './BlockLoader';
import { LineItemTable } from './LineItemTable';
import WimsicalError from './WimsicalError/WimsicalError';

export const KitSearch = (): JSX.Element => {
  const { gonzoParams } = useAppSelector((state) => state);

  const { acuityContext, user } = useAppSelector((state) => state.app);

  const divId = acuityContext?.selectedCustomer.id;
  const { data, isLoading, isFetching, isError, error } = useGetKitsQuery({ divisionId: divId as string, params: gonzoParams }, { skip: !divId });

  if (isError) {
    const errData = error as FetchBaseQueryError;

    return (
      <WimsicalError
        title={(errData.data as string) || 'Oops something went wrong'}
        statusCode={errData.status}
        subTitle={'Please try to reload the app and try again'}
        redirectText={'Reload'}
        redirectUrl={process.env.REACT_APP_REDIRECT_URL}
      />
    );
  }

  return (
    <Row>
      <Col span={24}>
        <LineItemTable
          dataSource={data?.data}
          loading={{ spinning: isLoading || isFetching, indicator: <BlockLoader direction="loader loader--slideUp" /> }}
          isCreate={false}
          locale={{
            emptyText: null
          }}
        />
      </Col>
    </Row>
  );
};
