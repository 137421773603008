import { ShippingLocationsGrid } from 'components/Create/common/LocationsGrid/ShippingLocationsGrid';
import { HeaderControls } from 'components/Create/ShippingLocation';

export const ShippingLocationPage = (): JSX.Element => {
  return (
    <>
      <HeaderControls />
      {/* {isSelectedCard ? <SelectedLocationCard address={isSelectedCard} addressType={'shipping'} /> : <ShippingLocationsGrid addressType="shipping" />} */}
      {<ShippingLocationsGrid addressType="shipping" />}
    </>
  );
};
