import { About } from 'pages/About';
import { ContactInfoPage, CreateDeploymentRequestLayout, InstallLocationPage, ProductsPage, RequestDetailsPage, RequestInfoPage, ReviewOrder, ShippingLocationPage } from 'pages/Create';
import { DetailsPage } from 'pages/Details';
import { EditDeploymentRequestLayout } from 'pages/Edit';
import { EditProfilePage } from 'pages/EditProfile';
import { RecoverPage } from 'pages/Recover';
import { ReleaseNotesPage } from 'pages/releaseNotes/ReleaseNotesPage';
import { SearchPage } from 'pages/Search';
import { Route, Routes } from 'react-router-dom';

export const MainRoutes = (): JSX.Element => {
  return (
    <Routes>
      <Route path="/" element={<SearchPage />} />
      <Route path="/search" element={<SearchPage />} />
      <Route path="/about" element={<About />} />
      <Route path="/profile" element={<EditProfilePage />} />
      <Route path="/release-notes/:version" element={<ReleaseNotesPage />} />
      <Route path="create" element={<CreateDeploymentRequestLayout />}>
        <Route index element={<RequestDetailsPage requestType="create" />} />
        <Route path="install-location" element={<InstallLocationPage />} />
        <Route path="products" element={<ProductsPage />} />
        <Route path="shipping-location" element={<ShippingLocationPage />} />
        <Route path="request-info" element={<RequestInfoPage />} />
        <Route path="contact-info" element={<ContactInfoPage />} />
        <Route path="review-order" element={<ReviewOrder />} />
      </Route>
      <Route path="edit/:id" element={<EditDeploymentRequestLayout />}>
        <Route index element={<RequestDetailsPage requestType="edit" />} />
        <Route path="install-location" element={<InstallLocationPage />} />
        <Route path="products" element={<ProductsPage />} />
        <Route path="shipping-location" element={<ShippingLocationPage />} />
        <Route path="request-info" element={<RequestInfoPage />} />
        <Route path="contact-info" element={<ContactInfoPage />} />
        <Route path="review-order" element={<ReviewOrder />} />
      </Route>
      <Route path="recover" element={<RecoverPage />} />
      <Route path="details/:id" element={<DetailsPage />}>
        <Route index element={<DetailsPage />} />
      </Route>
    </Routes>
  );
};
