import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Col, Row } from 'antd';
import { ProductsContent, RequestInfoContent, ShippingInfoContent } from 'components/Create/ReviewOrder';
import { AttachmentsContent } from 'components/Create/ReviewOrder/AttachmentsContent';
import { OrderInfoContent } from 'components/Create/ReviewOrder/OrderInfoContent';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { useParams } from 'react-router-dom';
import { useGetDeploymentRequestQuery } from 'redux/services/elmo/elmoApi';

const styles: InlineStylesModel = {
  headerContainer: {
    backgroundColor: 'white',
    padding: 24
  },
  headerTitle: {
    margin: 0,
    fontWeight: 'normal'
  },
  container: {
    flex: 1
  }
};

export const ReviewOrder = (): JSX.Element => {
  const { id } = useParams();
  const { data: deploymentRequest } = useGetDeploymentRequestQuery(id || skipToken);

  return (
    <Row gutter={[8, 8]} style={styles.container}>
      <Col sm={24} md={12}>
        <RequestInfoContent />
      </Col>
      <Col sm={24} md={12}>
        <ShippingInfoContent />
      </Col>
      <Col sm={24} md={12}>
        <OrderInfoContent />
      </Col>
      <Col sm={24} md={12}>
        <ProductsContent />
      </Col>
      <Col sm={24} md={12}>
        {deploymentRequest && deploymentRequest?.attachments.length >= 1 ? <AttachmentsContent /> : null}
      </Col>
    </Row>
  );
};
