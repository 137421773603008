import { Card, Col, Row, Typography } from 'antd';
import { TextInput } from 'components/Create/FormItems';
import { ProjectSelect } from 'components/Create/RequestDetails/ProjectSelect';

import { RequestTypeSelect } from 'components/Create/RequestDetails/RequestTypeSelect';
import TaskSelect from 'components/Create/RequestDetails/TaskSelect';
import { Loader } from 'components/common/Loader';
import { useFormikContext } from 'formik';
import { DeploymentRequestLabels, DeploymentRequestPayload } from 'models/DeploymentRequest';
import { InlineStylesModel } from 'models/InlineStylesModel';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetDeploymentRequestConfigQuery, useGetDeploymentRequestTypesQuery } from 'redux/services/drNobelPrice/drNobelPriceApi';
import { useGetProjectQuery } from 'redux/services/mrsGrouch/mrsGrouchApi';
import { useAppSelector } from 'redux/store';
import { colors, toRgba } from 'styles/colors';
const { Text, Title } = Typography;

const styles: InlineStylesModel = {
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: toRgba(colors.romanSilver, 0.4),
    justifyContent: 'space-between',
    width: '100%',
    padding: 24,
    borderBottom: '2px solid rgba(0,0,0,.1)'
  },
  headerTitle: {
    marginBottom: 0,
    fontWeight: 'normal'
  },
  container: {
    color: 'rgba(0,0,0, 0.85)'
  }
};

interface Props {
  requestType: string;
}

export const RequestDetailsPage: React.FC<Props> = ({ requestType }) => {
  const nav = useNavigate();
  const { id } = useParams();
  const { getFieldMeta, values } = useFormikContext<DeploymentRequestPayload>();
  const { value: requestNumberValue } = getFieldMeta<string>('requestNumber');

  const { taskCount, acuityContext } = useAppSelector((state) => state.app);

  const { data: projectData, isLoading: isProjectLoading } = useGetProjectQuery({ projectId: values.projectId as string, params: { overrideSkipTake: true } }, { skip: !values.projectId });

  const { data: requestTypesData, isLoading: isRequestLoading } = useGetDeploymentRequestTypesQuery();
  const { data, isLoading, isFetching } = useGetDeploymentRequestConfigQuery();

  const isDetailsLoading = Boolean(isFetching || isProjectLoading || isLoading || isRequestLoading);

  const [selectedRequestRuleTypeName, setSelectedRequestRuleTypeName] = useState(requestTypesData?.data.find((type) => values.deploymentRequestTypeId === type.id)?.dispositionRuleType ?? null);

  const areProjectsEnabled =
    (data?.areProjectsEnabled && selectedRequestRuleTypeName === 'Disposition') || selectedRequestRuleTypeName === 'SpecificProjects' || selectedRequestRuleTypeName === 'AllProjects';

  useEffect(() => {
    const requestTypeName = requestTypesData?.data.find((type) => values.deploymentRequestTypeId === type.id)?.dispositionRuleType || null;

    setSelectedRequestRuleTypeName(requestTypeName);
  }, [values.deploymentRequestTypeId]);

  const handleGetSuffix = (fullRequestNumber: string): string => {
    if (fullRequestNumber.includes('--')) {
      const suffix = fullRequestNumber.split('--');

      return suffix[1];
    } else {
      return 'n/a';
    }
  };

  if (isDetailsLoading) <Loader />;

  return (
    <Row gutter={[12, 12]}>
      <Col span={12}>
        <Card
          headStyle={{ backgroundColor: toRgba(colors.romanSilver, 0.4) }}
          title={
            <Title level={4} style={{ fontWeight: 'normal' }}>
              Internal Identification
            </Title>
          }
          style={{ height: '100%' }}>
          <Row gutter={[0, 10]}>
            <Col span={24}>
              <TextInput fieldName="requestNumber" label={DeploymentRequestLabels.requestNumber} defaultValue="AUTOGENERATED ID" disabled />
            </Col>
            <Col span={24}>
              <TextInput fieldName="requestNumberSuffix" label={`Request Name`} placeholder={id ? handleGetSuffix(requestNumberValue) : 'Optional'} />
            </Col>
          </Row>
        </Card>
      </Col>
      <Col span={12}>
        <Card
          headStyle={{ backgroundColor: toRgba(colors.royalBlueLight, 0.4) }}
          title={
            <Title level={4} style={{ fontWeight: 'normal' }}>
              Customer Reference
            </Title>
          }
          style={{ height: '100%' }}>
          <Row gutter={[0, 10]}>
            <Col span={24}>
              <TextInput
                fieldName="customerPurchaseOrderNumber"
                // placeholder={DeploymentRequestLabels.customerPurchaseOrderNumber}
                label={acuityContext?.configuration?.reference1Label || 'Primary Reference Number'}
              />
            </Col>
            <Col span={24}>
              <TextInput
                fieldName="customerReferenceNumber"
                // placeholder={DeploymentRequestLabels.customerReferenceNumber}
                label={acuityContext?.configuration?.reference2Label || 'Secondary Reference Number'}
              />
            </Col>
          </Row>
        </Card>
      </Col>
      <Col span={12}>
        <Card
          headStyle={{ backgroundColor: toRgba(colors.illuminatingEmerald, 0.4) }}
          title={
            <Title level={4} style={{ fontWeight: 'normal' }}>
              Request Type
            </Title>
          }
          style={{ height: '100%' }}>
          <Row gutter={[0, 10]}>
            <Col span={24}>
              <RequestTypeSelect />
            </Col>
            <Col span={24}></Col>
          </Row>
        </Card>
      </Col>
      <Col span={12}>
        {areProjectsEnabled && (
          <Card
            headStyle={{ backgroundColor: toRgba(colors.orangeWeb, 0.4) }}
            title={
              <Title level={4} style={{ fontWeight: 'normal' }}>
                Projects
              </Title>
            }
            style={{ height: '100%' }}>
            <Row gutter={[0, 10]}>
              <Col span={24}>
                <Col span={24}>
                  <ProjectSelect />
                </Col>
              </Col>
            </Row>
          </Card>
        )}
      </Col>
      <Col span={12} />
      {values.taskId ? (
        <Col span={12}>
          <Card
            headStyle={{ backgroundColor: toRgba(colors.robinEggBlue, 0.4) }}
            title={
              <Title level={4} style={{ fontWeight: 'normal' }}>
                Tasks
              </Title>
            }
            style={{ height: '100%' }}>
            <Row gutter={[0, 10]}>
              <Col span={24}>
                <Col span={24}>
                  <TaskSelect />
                </Col>
              </Col>
            </Row>
          </Card>
        </Col>
      ) : (
        values.projectId &&
        !!taskCount && (
          <Col span={12}>
            <Card
              headStyle={{ backgroundColor: toRgba(colors.robinEggBlue, 0.4) }}
              title={
                <Title level={4} style={{ fontWeight: 'normal' }}>
                  Tasks
                </Title>
              }
              style={{ height: '100%' }}>
              <Row gutter={[0, 10]}>
                <Col span={24}>
                  <Col span={24}>
                    <TaskSelect />
                  </Col>
                </Col>
              </Row>
            </Card>
          </Col>
        )
      )}
    </Row>
  );
};
