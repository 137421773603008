import { Card, Col, Row, Tooltip, Typography } from 'antd';
import { useFormikContext } from 'formik';
import { DeploymentRequestPayload } from 'models/DeploymentRequest';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { useParams } from 'react-router-dom';
import { useGetDeploymentRequestConfigQuery, useGetDeploymentRequestTypesQuery } from 'redux/services/drNobelPrice/drNobelPriceApi';
import { useGetProjectsQuery } from 'redux/services/mrsGrouch/mrsGrouchApi';
import { colors, toRgba } from 'styles/colors';
const { Text, Title } = Typography;

const styles: InlineStylesModel = {
  header: {
    backgroundColor: toRgba(colors.romanSilver, 0.4),
    height: '18%'
  },
  infoText: {}
};

export const RequestInfoContent = (): JSX.Element => {
  const { values } = useFormikContext<DeploymentRequestPayload>();
  const { id } = useParams();
  const { data: requestTypeData } = useGetDeploymentRequestTypesQuery();
  const { data, isLoading, isFetching } = useGetProjectsQuery({ params: { overrideSkipTake: true } });
  const { data: configData, isLoading: isConfigLoading } = useGetDeploymentRequestConfigQuery();
  const requestType = requestTypeData?.data.find((type) => values.deploymentRequestTypeId === type.id) || null;

  const selectedProject = data?.data.find((project) => project.id === values.projectId);

  const headerInfoCardJSX = (
    <Card
      style={{ height: 350, width: '100%' }}
      bodyStyle={{ marginBottom: 0, paddingBottom: 0, height: '82%' }}
      bordered={false}
      headStyle={styles.header}
      title={
        <Title level={4} style={{ fontWeight: 'normal' }}>
          Request Identification
        </Title>
      }>
      <Row gutter={16} style={{ height: '100%' }}>
        <Col span={8}>
          <Typography.Title level={5} style={styles.infoTitle}>
            Request ID:
          </Typography.Title>
          <Typography.Paragraph style={styles.infoText}>{values.requestNumber || 'AUTOGENERATED'}</Typography.Paragraph>
          <Typography.Title level={5} style={styles.infoTitle}>
            Status:
          </Typography.Title>
          <Typography.Paragraph style={styles.infoText}>{values.status.toUpperCase()}</Typography.Paragraph>
          <Typography.Title level={5} style={styles.infoTitle}>
            Inventory Reservation Setting:
          </Typography.Title>
          <Typography.Paragraph style={styles.infoText}>{requestType?.inventoryReservationMethod ?? 'n/a'}</Typography.Paragraph>
        </Col>
        <Col span={8}>
          <Typography.Title level={5} style={styles.infoTitle}>
            Customer Ref Number:
          </Typography.Title>
          <Typography.Paragraph style={styles.infoText}>{values.customerReferenceNumber || 'n/a'}</Typography.Paragraph>
          <Typography.Title level={5} style={styles.infoTitle}>
            Request Type:
          </Typography.Title>
          <Typography.Paragraph style={styles.infoText}>{requestType?.name}</Typography.Paragraph>
        </Col>
        <Col span={8}>
          <Typography.Title level={5} style={styles.infoTitle}>
            Customer PO Number:
          </Typography.Title>
          <Typography.Paragraph style={styles.infoText}>{values.customerPurchaseOrderNumber || 'n/a'}</Typography.Paragraph>

          <Typography.Title level={5} style={styles.infoTitle}>
            Project ID:
          </Typography.Title>

          <Tooltip placement="bottomLeft" title={configData?.areProjectsEnabled ? selectedProject?.description : undefined}>
            <Typography.Paragraph>{configData?.areProjectsEnabled ? selectedProject?.customerProjectId ?? 'No project selected' : 'Projects not enabled'}</Typography.Paragraph>
          </Tooltip>
        </Col>
        <Col span={24} style={{ position: 'absolute', bottom: 0, left: 0 }}>
          {id ? <Typography.Paragraph style={{ color: toRgba(colors.raisinBlackDark, 0.3) }}>Deployment Request ID: {id}</Typography.Paragraph> : null}
        </Col>
      </Row>
    </Card>
  );

  return headerInfoCardJSX;
};
