import { Col, Row } from 'antd';
import { CountryStateInputs } from 'components/ui/Filters/StateFilter';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { useEffect } from 'react';
import { resetParams } from 'redux/services/chuckieSue/addressesParams';
import { useAppDispatch } from 'redux/store';
import { CityFilter, LocationCodeFilter, StreetFilter, ZipCodeFilter } from './components';
import { LocationNameFilter } from './components/LocationNameFilter';
import { TypeFilter } from './components/TypeFilter';

const styles: InlineStylesModel = {
  container: {
    flex: 1
  }
};

interface Props {
  locationPageType: string;
}

export const LocationsFilters = ({ locationPageType }: Props): JSX.Element => {
  /* ******************** Hooks ******************** */
  const dispatch = useAppDispatch();

  /* ******************** Effects ******************** */
  useEffect(() => {
    return () => {
      dispatch(resetParams());
    };
  }, [dispatch]);

  return (
    <Row style={styles.container} gutter={[8, 20]}>
      <Col span={6}>
        <LocationCodeFilter />
      </Col>
      <Col span={6}>
        <LocationNameFilter />
      </Col>
      <Col span={6}>
        <StreetFilter />
      </Col>
      <Col span={6}>
        <CityFilter />
      </Col>
      <CountryStateInputs />
      <Col span={6}>
        <ZipCodeFilter />
      </Col>
      <Col span={6}>
        <TypeFilter addressPageType={locationPageType} />
      </Col>
    </Row>
  );
};
