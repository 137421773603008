import { BackTop, Button, Col, Layout, Row, Spin, Table, Typography } from 'antd';
import { AtatWalker } from 'components/common/AtatWalker';
import { Loader } from 'components/common/Loader';
import { AntPageTitle } from 'components/ui/AntPageTitle';
import { InlineStylesModel } from 'models/InlineStylesModel';
import * as React from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { useGetApiNameQuery as useAbbyCadabbyName, useGetVersionQuery as useAbbyCadabbyVersion } from 'redux/services/abbyCadabby/abbyCadabbyApi';
import { useGetApiNameQuery as useChuckieSueName, useGetVersionQuery as useChuckieSueVersion } from 'redux/services/chuckieSue/worldsApi';
import { useGetApiNameQuery as useCookieMonsterName, useGetVersionQuery as useCookieMonsterVersion } from 'redux/services/cookieMonster/cookieMonsterApi';
import { useGetApiNameQuery as useDrNobelPriceName, useGetVersionQuery as useDrNobelPriceVersion } from 'redux/services/drNobelPrice/drNobelPriceApi';
import { useGetApiNameQuery as useElmoName, useGetVersionQuery as useElmoVersion } from 'redux/services/elmo/elmoApi';
import { useGetApiNameQuery as useFreddyCadabbyName, useGetVersionQuery as useFreddyCadabbyVersion } from 'redux/services/freddyCadabby/freddyCadabbyApi';
import { useGetApiNameQuery as useJuliaName, useGetVersionQuery as useJuliaVersion } from 'redux/services/julia/juliaApi';
import { useGetApiNameQuery as useMrJohnsonName, useGetVersionQuery as useMrJohnsonVersion } from 'redux/services/mrJohnson/mrJohnsonApi';
import { useGetApiNameQuery as useMrsGrouchName, useGetVersionQuery as useMrsGrouchVersion } from 'redux/services/mrsGrouch/mrsGrouchApi';
import '../styles/checkmarkStyle.less';
import { globalStyles } from '../styles/GlobalStyles';

const styles: InlineStylesModel = {
  headerTitle: {
    color: 'rgb(67, 67, 73)',
    font: isMobileOnly ? '500 0.75rem Poppins' : '500 1rem Poppins',
    fontSize: 16
  },
  statusContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: isMobileOnly ? 'center' : 'none'
  },
  statusIcon: {
    fontSize: '1.5rem',
    marginRight: isMobileOnly ? '0px' : '15px'
  },
  statusText: {
    display: isMobileOnly ? 'none' : 'block'
  },
  tableBody: {
    backgroundColor: '#fff',
    fontSize: isMobileOnly ? '0.7em' : '1em',
    borderTop: '2px solid black'
  }
};

enum ApiStatus {
  online = 'Online',
  offline = 'Offline'
}

export const About: React.FunctionComponent = () => {
  /* ******************** Hooks ******************** */
  const navigate = useNavigate();
  const { data: abbyCadabbyApiVersion, isLoading: isLoadingAbbyCadabby } = useAbbyCadabbyVersion();
  const { data: abbyCadabbyApiName, isLoading: isLoadingAbbyCadabbyName } = useAbbyCadabbyName();

  const { data: freddyCadabbyApiVersion, isLoading: isLoadingFreddyCadabby } = useFreddyCadabbyVersion();
  const { data: freddyCadabbyApiName, isLoading: isLoadingFreddyCadabbyName } = useFreddyCadabbyName();

  const { data: cookieMonsterApiVersion, isLoading: isLoadingCookieMonster } = useCookieMonsterVersion();
  const { data: cookieMonsterApiName, isLoading: isLoadingCookieMonsterName } = useCookieMonsterName();

  const { data: chuckieSueApiVersion, isLoading: isLoadingChuckieSue } = useChuckieSueVersion();
  const { data: chuckieSueApiName, isLoading: isLoadingChuckieSueName } = useChuckieSueName();

  const { data: elmoApiVersion, isLoading: isLoadingElmo } = useElmoVersion();
  const { data: elmoApiName, isLoading: isLoadingElmoName } = useElmoName();

  const { data: drNobelPriceApiVersion, isLoading: isLoadingDrNobelPrice } = useDrNobelPriceVersion();
  const { data: drNobelPriceApiName, isLoading: isLoadingDrNobelPriceName } = useDrNobelPriceName();

  const { data: juliaApiVersion, isLoading: isLoadingJulia } = useJuliaVersion();
  const { data: juliaApiName, isLoading: isLoadingJuliaName } = useJuliaName();

  const { data: mrJohnsonApiVersion, isLoading: isLoadingMrJohnson } = useMrJohnsonVersion();
  const { data: mrJohnsonApiName, isLoading: isLoadingMrJohnsonName } = useMrJohnsonName();

  const { data: mrsGrouchApiVersion, isLoading: isLoadingMrsGrouch } = useMrsGrouchVersion();
  const { data: mrsGrouchApiName, isLoading: isLoadingMrsGrouchName } = useMrsGrouchName();

  const columnHeaders = [
    {
      dataIndex: 'apiName',
      key: 'apiName',
      title: 'API Name'
    },
    {
      dataIndex: 'version',
      key: 'version',
      title: 'Version'
    },
    {
      dataIndex: 'status',
      key: 'status',
      title: 'Status'
    }
  ];
  const handleHomePage = (): void => {
    navigate(`/`);
  };
  /* ******************** Table Data ******************** */
  const abbyCadabbyTableData = {
    version: abbyCadabbyApiVersion || 'failed to get version...',
    key: abbyCadabbyApiName,
    status: !isLoadingAbbyCadabby ? (
      <Row className="status-container" style={styles.statusContainer}>
        <Col style={styles.statusIcon}>
          {abbyCadabbyApiVersion ? (
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
              <circle className="path circle" fill="none" stroke="#73AF55" strokeWidth="10" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
              <polyline className="path check" fill="none" stroke="#73AF55" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
            </svg>
          ) : (
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
              <circle className="path circle" fill="none" stroke="#D06079" strokeWidth="7" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
              <line className="path line" fill="none" stroke="#D06079" strokeWidth="7" strokeLinecap="round" strokeMiterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3" />
              <line className="path line" fill="none" stroke="#D06079" strokeWidth="7" strokeLinecap="round" strokeMiterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2" />
            </svg>
          )}
        </Col>
        <Col style={styles.statusText}>{abbyCadabbyApiVersion ? ApiStatus.online : ApiStatus.offline}</Col>
      </Row>
    ) : (
      <Row>
        <Col style={styles.statusIcon}>
          <Spin spinning />
        </Col>
      </Row>
    ),
    apiName: abbyCadabbyApiName ? (
      <Row>
        <Col>{abbyCadabbyApiName}</Col>
      </Row>
    ) : (
      <Row style={styles.statusContainer}>
        <Col style={styles.statusIcon}>
          <Spin spinning />
        </Col>
      </Row>
    )
  };
  const freddyCadabbyTableData = {
    version: freddyCadabbyApiVersion || 'failed to get version...',
    key: freddyCadabbyApiName,
    status: !isLoadingFreddyCadabby ? (
      <Row className="status-container" style={styles.statusContainer}>
        <Col style={styles.statusIcon}>
          {freddyCadabbyApiVersion ? (
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
              <circle className="path circle" fill="none" stroke="#73AF55" strokeWidth="10" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
              <polyline className="path check" fill="none" stroke="#73AF55" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
            </svg>
          ) : (
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
              <circle className="path circle" fill="none" stroke="#D06079" strokeWidth="7" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
              <line className="path line" fill="none" stroke="#D06079" strokeWidth="7" strokeLinecap="round" strokeMiterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3" />
              <line className="path line" fill="none" stroke="#D06079" strokeWidth="7" strokeLinecap="round" strokeMiterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2" />
            </svg>
          )}
        </Col>
        <Col style={styles.statusText}>{freddyCadabbyApiVersion ? ApiStatus.online : ApiStatus.offline}</Col>
      </Row>
    ) : (
      <Row>
        <Col style={styles.statusIcon}>
          <Spin spinning />
        </Col>
      </Row>
    ),
    apiName: freddyCadabbyApiName ? (
      <Row>
        <Col>{freddyCadabbyApiName}</Col>
      </Row>
    ) : (
      <Row style={styles.statusContainer}>
        <Col style={styles.statusIcon}>
          <Spin spinning />
        </Col>
      </Row>
    )
  };
  const cookieMonsterTableData = {
    version: cookieMonsterApiVersion || 'failed to get version...',
    key: cookieMonsterApiName,
    status: !isLoadingCookieMonster ? (
      <Row className="status-container" style={styles.statusContainer}>
        <Col style={styles.statusIcon}>
          {cookieMonsterApiVersion ? (
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
              <circle className="path circle" fill="none" stroke="#73AF55" strokeWidth="10" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
              <polyline className="path check" fill="none" stroke="#73AF55" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
            </svg>
          ) : (
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
              <circle className="path circle" fill="none" stroke="#D06079" strokeWidth="7" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
              <line className="path line" fill="none" stroke="#D06079" strokeWidth="7" strokeLinecap="round" strokeMiterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3" />
              <line className="path line" fill="none" stroke="#D06079" strokeWidth="7" strokeLinecap="round" strokeMiterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2" />
            </svg>
          )}
        </Col>
        <Col style={styles.statusText}>{cookieMonsterApiVersion ? ApiStatus.online : ApiStatus.offline}</Col>
      </Row>
    ) : (
      <Row>
        <Col style={styles.statusIcon}>
          <Spin spinning />
        </Col>
      </Row>
    ),
    apiName: cookieMonsterApiName ? (
      <Row>
        <Col>{cookieMonsterApiName}</Col>
      </Row>
    ) : (
      <Row style={styles.statusContainer}>
        <Col style={styles.statusIcon}>
          <Spin spinning />
        </Col>
      </Row>
    )
  };
  const chuckieSueTableData = {
    version: chuckieSueApiVersion || 'failed to get version...',
    key: chuckieSueApiName,
    status: !isLoadingChuckieSue ? (
      <Row className="status-container" style={styles.statusContainer}>
        <Col style={styles.statusIcon}>
          {chuckieSueApiVersion ? (
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
              <circle className="path circle" fill="none" stroke="#73AF55" strokeWidth="10" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
              <polyline className="path check" fill="none" stroke="#73AF55" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
            </svg>
          ) : (
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
              <circle className="path circle" fill="none" stroke="#D06079" strokeWidth="7" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
              <line className="path line" fill="none" stroke="#D06079" strokeWidth="7" strokeLinecap="round" strokeMiterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3" />
              <line className="path line" fill="none" stroke="#D06079" strokeWidth="7" strokeLinecap="round" strokeMiterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2" />
            </svg>
          )}
        </Col>
        <Col style={styles.statusText}>{chuckieSueApiVersion ? ApiStatus.online : ApiStatus.offline}</Col>
      </Row>
    ) : (
      <Row>
        <Col style={styles.statusIcon}>
          <Spin spinning />
        </Col>
      </Row>
    ),
    apiName: chuckieSueApiName ? (
      <Row>
        <Col>{chuckieSueApiName}</Col>
      </Row>
    ) : (
      <Row style={styles.statusContainer}>
        <Col style={styles.statusIcon}>
          <Spin spinning />
        </Col>
      </Row>
    )
  };
  const elmoTableData = {
    version: elmoApiVersion || 'failed to get version...',
    key: elmoApiName,
    status: !isLoadingElmo ? (
      <Row className="status-container" style={styles.statusContainer}>
        <Col style={styles.statusIcon}>
          {elmoApiVersion ? (
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
              <circle className="path circle" fill="none" stroke="#73AF55" strokeWidth="10" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
              <polyline className="path check" fill="none" stroke="#73AF55" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
            </svg>
          ) : (
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
              <circle className="path circle" fill="none" stroke="#D06079" strokeWidth="7" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
              <line className="path line" fill="none" stroke="#D06079" strokeWidth="7" strokeLinecap="round" strokeMiterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3" />
              <line className="path line" fill="none" stroke="#D06079" strokeWidth="7" strokeLinecap="round" strokeMiterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2" />
            </svg>
          )}
        </Col>
        <Col style={styles.statusText}>{elmoApiVersion ? ApiStatus.online : ApiStatus.offline}</Col>
      </Row>
    ) : (
      <Row>
        <Col style={styles.statusIcon}>
          <Spin spinning />
        </Col>
      </Row>
    ),
    apiName: elmoApiName ? (
      <Row>
        <Col>{elmoApiName}</Col>
      </Row>
    ) : (
      <Row style={styles.statusContainer}>
        <Col style={styles.statusIcon}>
          <Spin spinning />
        </Col>
      </Row>
    )
  };
  const drNobelPriceTableData = {
    version: drNobelPriceApiVersion || 'failed to get version...',
    key: drNobelPriceApiName,
    status: !isLoadingDrNobelPrice ? (
      <Row className="status-container" style={styles.statusContainer}>
        <Col style={styles.statusIcon}>
          {drNobelPriceApiVersion ? (
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
              <circle className="path circle" fill="none" stroke="#73AF55" strokeWidth="10" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
              <polyline className="path check" fill="none" stroke="#73AF55" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
            </svg>
          ) : (
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
              <circle className="path circle" fill="none" stroke="#D06079" strokeWidth="7" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
              <line className="path line" fill="none" stroke="#D06079" strokeWidth="7" strokeLinecap="round" strokeMiterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3" />
              <line className="path line" fill="none" stroke="#D06079" strokeWidth="7" strokeLinecap="round" strokeMiterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2" />
            </svg>
          )}
        </Col>
        <Col style={styles.statusText}>{drNobelPriceApiVersion ? ApiStatus.online : ApiStatus.offline}</Col>
      </Row>
    ) : (
      <Row>
        <Col style={styles.statusIcon}>
          <Spin spinning />
        </Col>
      </Row>
    ),
    apiName: drNobelPriceApiName ? (
      <Row>
        <Col>{drNobelPriceApiName}</Col>
      </Row>
    ) : (
      <Row style={styles.statusContainer}>
        <Col style={styles.statusIcon}>
          <Spin spinning />
        </Col>
      </Row>
    )
  };
  const juliaTableData = {
    version: juliaApiVersion || 'failed to get version...',
    key: juliaApiName,
    status: !isLoadingJulia ? (
      <Row className="status-container" style={styles.statusContainer}>
        <Col style={styles.statusIcon}>
          {juliaApiVersion ? (
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
              <circle className="path circle" fill="none" stroke="#73AF55" strokeWidth="10" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
              <polyline className="path check" fill="none" stroke="#73AF55" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
            </svg>
          ) : (
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
              <circle className="path circle" fill="none" stroke="#D06079" strokeWidth="7" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
              <line className="path line" fill="none" stroke="#D06079" strokeWidth="7" strokeLinecap="round" strokeMiterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3" />
              <line className="path line" fill="none" stroke="#D06079" strokeWidth="7" strokeLinecap="round" strokeMiterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2" />
            </svg>
          )}
        </Col>
        <Col style={styles.statusText}>{juliaApiVersion ? ApiStatus.online : ApiStatus.offline}</Col>
      </Row>
    ) : (
      <Row>
        <Col style={styles.statusIcon}>
          <Spin spinning />
        </Col>
      </Row>
    ),
    apiName: juliaApiName ? (
      <Row>
        <Col>{juliaApiName}</Col>
      </Row>
    ) : (
      <Row style={styles.statusContainer}>
        <Col style={styles.statusIcon}>
          <Spin spinning />
        </Col>
      </Row>
    )
  };
  const mrJohnsonTableData = {
    version: mrJohnsonApiVersion || 'failed to get version...',
    key: mrJohnsonApiName,
    status: !isLoadingMrJohnson ? (
      <Row className="status-container" style={styles.statusContainer}>
        <Col style={styles.statusIcon}>
          {mrJohnsonApiVersion ? (
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
              <circle className="path circle" fill="none" stroke="#73AF55" strokeWidth="10" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
              <polyline className="path check" fill="none" stroke="#73AF55" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
            </svg>
          ) : (
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
              <circle className="path circle" fill="none" stroke="#D06079" strokeWidth="7" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
              <line className="path line" fill="none" stroke="#D06079" strokeWidth="7" strokeLinecap="round" strokeMiterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3" />
              <line className="path line" fill="none" stroke="#D06079" strokeWidth="7" strokeLinecap="round" strokeMiterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2" />
            </svg>
          )}
        </Col>
        <Col style={styles.statusText}>{mrJohnsonApiVersion ? ApiStatus.online : ApiStatus.offline}</Col>
      </Row>
    ) : (
      <Row>
        <Col style={styles.statusIcon}>
          <Spin spinning />
        </Col>
      </Row>
    ),
    apiName: mrJohnsonApiName ? (
      <Row>
        <Col>{mrJohnsonApiName}</Col>
      </Row>
    ) : (
      <Row style={styles.statusContainer}>
        <Col style={styles.statusIcon}>
          <Spin spinning />
        </Col>
      </Row>
    )
  };
  const mrsGrouchTableData = {
    version: mrsGrouchApiVersion || 'failed to get version...',
    key: mrsGrouchApiName,
    status: !isLoadingMrsGrouch ? (
      <Row className="status-container" style={styles.statusContainer}>
        <Col style={styles.statusIcon}>
          {mrsGrouchApiVersion ? (
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
              <circle className="path circle" fill="none" stroke="#73AF55" strokeWidth="10" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
              <polyline className="path check" fill="none" stroke="#73AF55" strokeWidth="10" strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
            </svg>
          ) : (
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
              <circle className="path circle" fill="none" stroke="#D06079" strokeWidth="7" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
              <line className="path line" fill="none" stroke="#D06079" strokeWidth="7" strokeLinecap="round" strokeMiterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3" />
              <line className="path line" fill="none" stroke="#D06079" strokeWidth="7" strokeLinecap="round" strokeMiterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2" />
            </svg>
          )}
        </Col>
        <Col style={styles.statusText}>{mrsGrouchApiVersion ? ApiStatus.online : ApiStatus.offline}</Col>
      </Row>
    ) : (
      <Row>
        <Col style={styles.statusIcon}>
          <Spin spinning />
        </Col>
      </Row>
    ),
    apiName: mrsGrouchApiName ? (
      <Row>
        <Col>{mrsGrouchApiName}</Col>
      </Row>
    ) : (
      <Row style={styles.statusContainer}>
        <Col style={styles.statusIcon}>
          <Spin spinning />
        </Col>
      </Row>
    )
  };

  const mainAppVersion = (): string => {
    const appName = process.env.REACT_APP_APPLICATION_NAME as string;
    const versionNumber = process.env.REACT_APP_VERSION_NUMBER as string;
    const splitVersion = versionNumber.split('-');

    return `${appName} ${splitVersion[splitVersion.length - 1]}`;
  };

  if (isLoadingAbbyCadabby) {
    return <Loader />;
  }

  return (
    <Layout.Content style={{ ...globalStyles.addressGridContainer }}>
      <BackTop />
      <Row gutter={[10, 10]} justify="space-between">
        <Col span={24}>
          <Row justify="space-between" align="middle">
            <Col>
              <AntPageTitle text="About Website" />
              <Typography.Link href="/" style={styles.headerTitle}>
                {mainAppVersion()}
              </Typography.Link>
            </Col>
            <Col>
              <Button onClick={handleHomePage} type="primary">
                Back to Home
              </Button>
            </Col>
          </Row>
        </Col>
        <Col md={13} sm={24}>
          <Table
            columns={columnHeaders}
            bordered={true}
            pagination={false}
            dataSource={[
              freddyCadabbyTableData,
              abbyCadabbyTableData,
              mrsGrouchTableData,
              mrJohnsonTableData,
              elmoTableData,
              drNobelPriceTableData,
              chuckieSueTableData,
              juliaTableData,
              cookieMonsterTableData
            ]}
            style={styles.tableBody}
          />
        </Col>
        <Col md={10} sm={24}>
          <AtatWalker />
        </Col>
      </Row>
    </Layout.Content>
  );
};
