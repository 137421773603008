import { Button, Col, Row, Space, Typography } from 'antd';
import { useFormikContext } from 'formik';
import { useBulkSearch } from 'hooks/useBulkSearch';
import { DeploymentRequestLabels, DeploymentRequestPayload } from 'models/DeploymentRequest';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { setDescriptionContains, setKitIdContains } from 'redux/services/gonzo/gonzoParams';
import { ReduxState, useAppSelector } from 'redux/store';
import { colors, toRgba } from 'styles/colors';
import { useDebouncedCallback } from 'use-debounce';
import { formatCurrency } from 'utils/formatters';
import { CategoryFilter, ManufacturerFilter } from './components';
import { AlternateIdFilter } from './components/AlternateIdFilter';
import { FavoriteListFilter } from './components/FavoriteListFilter';
import { FavoriteListSettings } from './components/FavoriteListSettings';
import { KitDescriptionFilter } from './components/KitDescriptionFilter';
import { KitIdFilter } from './components/KitIdFilter';
import { ProductDescriptionFilter } from './components/ProductDescriptionFilter';
import { ProductNumberBulkSearch } from './components/ProductNumberBulkSearch';
import { ProductNumberFilter } from './components/ProductNumberFilter';
const { Title } = Typography;

const styles: InlineStylesModel = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: toRgba(colors.romanSilver, 0.4),
    justifyContent: 'space-between',
    width: '100%',
    padding: 24,
    borderBottom: '2px solid rgba(0,0,0,.1)',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10
  },
  title: {
    margin: 0,
    fontWeight: 'normal'
  },
  textColumn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  totalStyle: {
    fontSize: '20px',
    textAlign: 'center'
  },
  nextButton: {
    borderRadius: '5px',
    textAlign: 'right'
  }
};
const FilterGroup = (): JSX.Element => {
  const { bulkSearchResults } = useAppSelector((state: ReduxState) => state.app);

  const { clearValues } = useBulkSearch();

  return (
    <Col span={24}>
      <Row gutter={[10, 15]}>
        <Col span={8}>
          <ProductNumberFilter />
        </Col>
        <Col span={8}>
          <AlternateIdFilter />
        </Col>
        <Col span={8}>
          <ProductDescriptionFilter />
        </Col>
        <Col span={8}>
          <CategoryFilter />
        </Col>
        <Col span={8}>
          <ManufacturerFilter />
        </Col>
        <Col span={8}>
          <Row justify="end">
            <Space>
              {!!bulkSearchResults?.length && <Button onClick={clearValues}>Reset</Button>}
              <ProductNumberBulkSearch />
              {/* <FavoriteListSettings /> */}
            </Space>
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

export const HeaderFilters = (): JSX.Element => {
  const nav = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { values } = useFormikContext<DeploymentRequestPayload>();
  const { descriptionContains, kitIdContains } = useAppSelector((state) => state.gonzoParams);
  const itemCount = values.lineItems?.map((product) => product.quantity).reduce((previousQuantity, currentQuantity) => previousQuantity + currentQuantity, 0);
  const totalCost = values.lineItems
    ?.map((product) => product.customerStandardCost && product.customerStandardCost * product.quantity)
    .reduce((previousCost, currentCost) => (previousCost || 0) + (currentCost || 0), 0);

  const { productsView } = useAppSelector((state) => state.form);

  const handleContinueButton = (): void => {
    id ? nav(`/edit/${id}/request-info`) : nav('/create/request-info');
  };
  const handleChange = useDebouncedCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    dispatch(setKitIdContains(value || undefined));
  }, 500);

  const handleChangeDesc = useDebouncedCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    dispatch(setDescriptionContains(value || undefined));
  }, 500);

  // useEffect(() => {
  //   return () => {
  //     dispatch(setKitIdContains(undefined));
  //     dispatch(setDescriptionContains(undefined));
  //   };
  // }, []);

  if (productsView === 'fav')
    return (
      <Row style={styles.container}>
        <Col>
          <Title level={4} style={styles.title}>
            User Favorites
          </Title>
        </Col>
        <Col>
          <Space>
            <FavoriteListFilter />
            <FavoriteListSettings />
          </Space>
        </Col>
      </Row>
    );
  if (productsView === 'kit')
    return (
      <Row style={styles.container}>
        <Col>
          <Title level={4} style={styles.title}>
            Search Non Assembled Kits
          </Title>
        </Col>
        <Col>
          <Space>
            <KitIdFilter />
            <KitDescriptionFilter />
          </Space>
        </Col>
      </Row>
    );

  return (
    <div style={styles.container}>
      <Space style={{ flex: 3, justifyContent: 'center' }} size={0} direction="vertical">
        <Title level={4} style={styles.title}>
          {DeploymentRequestLabels.products} {productsView === 'grid' ? 'Search' : 'Cart'}
        </Title>
      </Space>
      <Row style={{ flex: 6 }} gutter={[8, 8]}>
        {productsView === 'grid' ? (
          <FilterGroup />
        ) : (
          <Col md={24}>
            <Row>
              <Col span={3}></Col>
              <Col span={9} style={styles.totalStyle}>
                Total Cost: {formatCurrency(totalCost)}
              </Col>
              <Col span={1}></Col>
              <Col span={9} style={styles.totalStyle}>
                Total Items: {itemCount}
              </Col>
              <Col span={2}></Col>
            </Row>
          </Col>
        )}
      </Row>
    </div>
  );
};
