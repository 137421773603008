import { DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Row, Space } from 'antd';
import { DeploymentsGrid } from 'components/Recover';
import RecoverActions from 'components/Recover/RecoverActions';
import { ControlFilters } from 'components/Search';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { useLocation, useNavigate } from 'react-router-dom';

const styles: InlineStylesModel = {
  container: {
    flex: 1,
    width: '100%'
  },
  controlsContainer: {
    backgroundColor: 'white',
    width: '100%',
    padding: 20,
    justifyContent: 'space-between'
  },
  searchRow: {
    padding: '20px 10px',
    backgroundColor: 'white'
  },
  searchSalesOrderHeadline: {
    fontSize: '17px',
    marginBottom: 10,
    paddingLeft: 10
  }
};

export const RecoverPage = (): JSX.Element => {
  const location = useLocation();
  const navigate = useNavigate();
  const isRecoverPage = location.pathname.includes('recover');

  const handleNavigateToRecoverPage = (): void => {
    navigate('/recover');
  };
  const handleNavigateHome = (): void => {
    navigate('/');
  };

  return (
    <Space direction="vertical" style={styles.container}>
      <Row style={styles.searchSalesOrderHeadline} align="middle" justify="space-between">
        <Col>{location.pathname.includes('recover') ? 'Search Deployment Requests - Recycle Bin' : 'Search Deployment Requests'}</Col>
        <Col>
          {!isRecoverPage ? (
            <Button onClick={handleNavigateToRecoverPage} icon={<DeleteOutlined />}>
              Recycle Bin
            </Button>
          ) : (
            <Button onClick={handleNavigateHome} icon={<SearchOutlined />}>
              Back to Deployment Requests
            </Button>
          )}
        </Col>
      </Row>
      <Row gutter={[0, 16]} align="middle" style={styles.controlsContainer}>
        <Col sm={24}>
          <ControlFilters />
        </Col>
      </Row>
      <RecoverActions />
      <Divider style={{ marginTop: 5 }} />
      <DeploymentsGrid />
    </Space>
  );
};
